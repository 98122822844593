import React from 'react'
import SpieltermineEinzel from "../Spieltermine/SpieltermineEinzel";
import GespieltAllEinzel from "../Gespielt/GespieltAllEinzel";
import {authService} from "../_services/authService";


class AllMatchesEinzel extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: {},
      userloaded: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadUser();
    }
  }


  loadUser(){
    authService.getCurrentUser()
    .then(response => {
      this.setState({
        loggedInUser: response,
        userloaded: true
      });
    })
    .catch(error => {
      if (this._isMounted) {
        this.setState({
          hasError: error,
          loggedInUser: {},
          userloaded: false
        });
      }
    });
  }

  render()
  {
    return (
        <div>
          <SpieltermineEinzel currentUser={this.props.currentUser} adminView={false} />
          <GespieltAllEinzel/>
        </div>
    );
  }
}
export default AllMatchesEinzel;