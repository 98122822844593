import React from 'react';
import GespieltAllEinzel from "../../Gespielt/GespieltAllEinzel";
import SpieltermineEinzel from "../../Spieltermine/SpieltermineEinzel";

class SpieltermineVerwaltung extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            gespielt: []
        };
    }
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
  
        return (
            <div className='print-margin-top'>
                <div id="printBox">
                    <SpieltermineEinzel currentUser={this.props.currentUser} adminView={true}/>
                </div>

                <div className='print_hide'>
                    <GespieltAllEinzel adminView={true}/>
                </div>
            </div>
        );
    }
}


export default SpieltermineVerwaltung;