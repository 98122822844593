import './Messages.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class WarningMessage extends React.Component {


    render() {
        return (
            <div className="message warning-message">
                <div className="message-container">
                    <div className="message-icon fa fa-fw fa-2x fa-exclamation-triangle">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    </div>

                    <div className="message-content-container">
                        <div className="message-body">{this.props.message}</div>
                    </div>
                </div>
            </div>
        )
    }
}
// end::app[]

export default WarningMessage;