import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const ranglisteEinzelEintragService = {
    getRanglisteEinzelEintraegeSorted,
    getRanglisteEinzelEintraegeByNavUri,
    getAllEinzelEintraegePaged,
    getEinzelEintragByNutzerId,
    getRanglisteEinzelEintragById,
    mischeRangliste,
    deleteEinzelEintrag,
    addEinzelEintragForNutzer
};

export function getRanglisteEinzelEintraegeSorted()
{
  return request({
    url: API_BASE_URL + "/rangliste-einzel-eintrag/list",
    method: 'GET'
  });
}

export function getRanglisteEinzelEintraegeByNavUri(uri)
{
    return request({
        url: uri,
        method: 'GET'
    });
}

export function getAllEinzelEintraegePaged(page, size)
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/pagedlist?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function getRanglisteEinzelEintragById(eintragId)
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/"  + eintragId,
        method: 'GET'
    });
}

export function getEinzelEintragByNutzerId(nutzerId)
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/getByNutzerId?nutzerId="  + nutzerId,
        method: 'GET'
    });
}

export function mischeRangliste()
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/mix",
        method: 'GET'
    });
}

export function addEinzelEintragForNutzer(nutzerDto)
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/addForNutzer",
        method: 'POST',
        body: JSON.stringify(nutzerDto)
    });
}

export function deleteEinzelEintrag(eintragId)
{
    return request({
        url: API_BASE_URL + "/rangliste-einzel-eintrag/delete/"  + eintragId,
        method: 'DELETE'
    });
}
