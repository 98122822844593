import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const ranglisteMixedEintragService = {
    getAllRanglistenMixedpaarungenSorted,
    getById,
    getRanglisteMixedEintragByNutzerId,
    getAllMixedpaarungenPaged,
    getMixedpaarungByNavUri,
    deleteMixedpaarungAndNutzerById,
    createWithNewMixedpaarungFromNutzer,
    mischeRangliste
};

export function getAllRanglistenMixedpaarungenSorted()
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/list",
        method: 'GET'
    });
}


export function getById(mixedpaarungId)
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/"  + mixedpaarungId,
        method: 'GET'
    });
}
export function getRanglisteMixedEintragByNutzerId(nutzerId)
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/getByMixedpaarung?nutzerId="  + nutzerId,
        method: 'GET'
    });
}

export function getAllMixedpaarungenPaged(page, size)
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/pagedlist?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function getMixedpaarungByNavUri(uri)
{
    return request({
        url: uri,
        method: 'GET'
    });
}
export function deleteMixedpaarungAndNutzerById(mixedpaarungId)
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/deleteMixedpaarung/"  + mixedpaarungId,
        method: 'DELETE'
    });
}

export function createWithNewMixedpaarungFromNutzer(mixedpaarungFromExistingNewRequest) {
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/createWithNewMixedpaarungFromNutzer",
        method: 'POST',
        body: JSON.stringify(mixedpaarungFromExistingNewRequest)
    });
}

export function mischeRangliste()
{
    return request({
        url: API_BASE_URL + "/rangliste-mixed-eintrag/mix",
        method: 'GET'
    });
}

