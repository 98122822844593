import React from "react";
import {
    getDisplayNameEinzel,
    getDisplayNameEinzelTruncated,
    getLabelForHeadingEinzel
} from "../../util/DisplayHelpers";

export default class NutzerDto {
    
    constructor(nutzerJson) {
        if(nutzerJson !== null && nutzerJson !== undefined) {
            this.id = nutzerJson.id;
            this.aktiv = nutzerJson.aktiv;

            //this.nutzer = nutzerJson;
            
            this.vorname = nutzerJson.vorname;
            this.nachname = nutzerJson.nachname;

            this.telefon = nutzerJson.telefon;
            this.email = nutzerJson.email;
        }
    }

    isDoppel(){
        return false;
    }

    //Im Einzel gehen wir hier auf den Nutzer
    //Im Mixed geht das nicht, da die Paarung ja aus zwei Nutzern besteht. Da haben wir den Nutzer nicht.
    //Daher gehen wir da auf den Nutzer des eingeloggten Nutzers.
    containsNutzer(nutzer){
        return this.containsNutzerId(nutzer.id);
    }

    containsNutzerId(nutzerId){
        if(this.id === nutzerId){
            return true;
        }

        return false;
    }

    getLabel(){
        return getDisplayNameEinzel(this);
    }

    getLabelTruncated(){
        return getDisplayNameEinzelTruncated(this);
    }

    getLabelForHeading(){
        return getLabelForHeadingEinzel(this);
    }

    getLabelTruncatedForHeading(){
        return this.vorname;
    }

    getLabelElement(isResponsiveView) {
        let text = <span>{this.nachname}, {this.vorname}</span>;

        if(isResponsiveView){
            text = <span>{this.nachname}, <br/> {this.vorname}</span>;
        }
        return text;
    }

    getKontaktinfoElement() {
        return <p>
                <b>E-Mail-Adresse:</b> {this.email} <br/>
                <b>Telefonnummer:</b> {this.telefon}
            </p>;
    }

    getKey(){
        return this.nachname + "_" + this.id;
    }
}