import React from 'react'

const Spielregeln = () => (
    <div>
      <h1>Spielregeln</h1>

      <h2>Registrierung und Anmeldung</h2>
      <p>
        Um bei der Vereinsrangliste mitspielen zu können, musst du dich zuerst <a href="/signup/new">registrieren</a>. 
        Falls du deinem Sportwart / deiner Sportwartin bereits deine Kontaktdaten mitgeteilt hast, um dich in die Rangliste voreintragen zu lassen, dann achte bitte darauf, dass du für die Registrierung die dort angebgenen E-Mail-Adresse verwendest. Ansonsten erscheinst du später zwei Mal in der Rangsliste.
      </p>
      <p>
        Bereits registrierte Nutzer können sich dann jederzeit <a href="/login">anmelden</a>, um andere Nutzer herauszufordern.
      </p>

      <h2>Forderung</h2>
      <p>
        Nach dem <a href="/login">Login</a> werden dir in der Rangliste die Nutzer, die du fordern kannst, in grün angezeigt.
      </p>
      <p>
        Jeder Nutzer kann immer nur eine offene Forderung haben. Befindest du dich also bereits in einer Forderung, kannst du keine weiteren Nutzer herausfordern, bis die Forderung gespielt wurde.
        Gleiches gilt, falls ein Nutzer, den du fordern möchtest, bereits eine noch nicht ausgetragene Forderung hat: Diesen kannst du erst fordern, wenn er seine bestehende Forderung gespielt und eingetragen hat.
      </p>
      <p>
          Bei Forderung sollte ein Termin innerhalb der nächsten Woche gefunden werden. Wenn binnen zwei Wochen keiner zu finden ist, einigt euch auf eine Streichung der Forderung oder auf einen Sieg durch nicht antreten (n.a.).
      </p>
      <p>
        Um euch einen Platz auf der Anlage zu reservieren, tragt euch mit Uhrzeit und Platznummer in der Liste am Board im Clubhaus ein. Bitte achtet darauf, dass ihr euch nicht für einen Platz eintragt, auf dem zu diesem Zeitpunkt Mannschaftstraining stattfindet. Generell darf in der Zeit des Mannschaftstrainings nur ein Platz belegt werden.
      </p>
      <p>
        Bei Satzgleichheit wird ein Match-Tie-Break gespielt. Es sei denn, beide einigen sich vorab, dass sie einen 3. Satz spielen wollen.
      </p>
        <p>
        Der Nutzer der fordert, stellt die Bälle.
      </p>      
      <h2>Ergebnis Eintragung</h2>
      <p>
        Habt ihr euer Match gespielt, dann tragt danach zeitnah das Ergebnis ein.
      </p>
      <p>
        Gewinnt der Herausforderer, so rückt er an den Platz des herausgeforderten. Der Herausgeforderte wiederum rückt einen Platz tiefer. Verliert der Herausforderer, so bleibt die Rangliste, wie sie war.
      </p>

        <br/>
        <p style={{fontSize: '2em', textAlign: 'center'}}>
            <b>VIEL SPAß UND VIEL ERFOLG</b>
        </p>
    </div>
    
    
)
export default Spielregeln;