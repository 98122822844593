import RanglistenEintragDto from "./RanglistenEintragDto";
import React from "react";
import {getDisplayNameMixed, getLabelForHeadingMixed} from "../../util/DisplayHelpers";
import NutzerDto from "./NutzerDto";

export default class RanglistenMixedEintragDto extends RanglistenEintragDto {
    
    constructor(ranglisteMixedEintragJson) {
        super(ranglisteMixedEintragJson);
        if(ranglisteMixedEintragJson !== null && ranglisteMixedEintragJson !== undefined){
            this.nutzerM = new NutzerDto(ranglisteMixedEintragJson.nutzerM);
            this.nutzerW = new NutzerDto(ranglisteMixedEintragJson.nutzerW);
        }
    }

    isDoppel(){
        return true;
    }

    //Im Einzel gehen wir hier auf den Nutzer
    //Im Mixed geht das nicht, da die Paarung ja aus zwei Nutzern besteht. Da haben wir den Nutzer nicht.
    //Daher gehen wir da auf den Nutzer des eingeloggten Nutzers.
    containsNutzer(nutzer){
        return this.containsNutzerId(nutzer.id);
    }
    containsNutzerId(nutzerId){
        if(this.nutzerM.id === nutzerId){
            return true;
        }

        if(this.nutzerW.id === nutzerId){
            return true;
        }

        return false;
    }

    getLabel(){
        return getDisplayNameMixed(this);
    }

    getLabelTruncated(){
        return getDisplayNameMixed(this);
    }

    getLabelForHeading(){
        return getLabelForHeadingMixed(this);
    }

    getLabelTruncatedForHeading(){
        return getLabelForHeadingMixed(this);
    }

    getLabelElement(isResponsiveView) {
        let text = <span>{this.nutzerM.nachname}/{this.nutzerW.nachname}</span>;

        if(isResponsiveView){
            text = <span>{this.nutzerM.nachname}/<br/> {this.nutzerW.nachname}</span>;
        }
        return text;
    }

    getKontaktinfoElement() {

        let kontaktinfo =
            <p>
                <u>{this.nutzerM.vorname}</u><br/>
                <b>E-Mail-Adresse:</b> {this.nutzerM.email} <br/>
                <b>Telefonnummer:</b> {this.nutzerM.telefon}
                <br/><br/>
                <u>{this.nutzerW.vorname}</u><br/>
                <b>E-Mail-Adresse:</b> {this.nutzerW.email} <br/>
                <b>Telefonnummer:</b> {this.nutzerW.telefon}
            </p>;

        return kontaktinfo;
    }

    getKey(){
        return this.nachname1 + "-" + this.nachname2 + "_" + this.id;
    }
}