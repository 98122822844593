export default class GespieltDto {
    constructor(gespieltJson) {
        if (gespieltJson !== null && gespieltJson !== undefined) {
            this.id = gespieltJson.id;
            this.spieltermin = gespieltJson.spieltermin;

            this.ersterSatzHeim = gespieltJson.ersterSatzFord;
            this.ersterSatzGast = gespieltJson.ersterSatzHeraus;

            this.zweiterSatzHeim = gespieltJson.zweiterSatzFord;
            this.zweiterSatzGast = gespieltJson.zweiterSatzHeraus;

            this.dritterSatzHeim = gespieltJson.dritterSatzFord;
            this.dritterSatzGast = gespieltJson.dritterSatzHeraus;

            this.aufgabeHeim = gespieltJson.aufgabeFordernder;
            this.aufgabeGast = gespieltJson.aufgabeHerausgeforderter;
        }
    }

    getHeim() {
        throw new Error("Method 'getHeim()' must be implemented.");
    }

    getGast() {
        throw new Error("Method 'getGast()' must be implemented.");
    }

    isWinner(nutzerId){
        var gewinnerString = this.determineWinner();

        if(gewinnerString === "Heim"){
            return this.getHeim().containsNutzerId(nutzerId);
        }

        if(gewinnerString === "Gast"){
            return this.getGast().containsNutzerId(nutzerId);
        }

        return false;
    }

    isHeimWinner(){
        const gewinner = this.determineWinner();
        if("Heim" === gewinner){
            return true;
        }
        return false;
    }

    determineWinner() {
        if (this.aufgabeGast) {
            return "Heim";
        }

        if (this.aufgabeHeim) {
            return "Gast";
        }

        //Das Match ging im Match-Tie-Break aus
        if (this.dritterSatzHeim > 0 || this.dritterSatzGast > 0) {
            if (this.dritterSatzHeim > this.dritterSatzGast) {
                return "Heim";
            } else {
                return "Gast";
            }
        }

        if (this.zweiterSatzHeim > this.zweiterSatzGast) {
            return "Heim";
        } else {
            return "Gast";
        }
    }
}