import React from 'react';
import moment from 'moment';

class Gespielt extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            gespielt: this.props.gespielt
        };
    }

    //Die hier ist wichtig.
    //sonst kommen die Aenderungen an den Properties-Variable vom Parent nicht hier beim Kind an
    //keine Ahnung wieso.
    //Anmerkung: hier  kann man nicht auf this zugreifen und auch keinen state setzten/aendern
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.gespielt !== prevState.gespielt) {
            return {
                gespielt: nextProps.gespielt,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevProps.gespielt!==this.props.gespielt){
            this.setState({ gespielt: prevProps.gespielt });
        }
    }

    getDisplayMatchErgebnis(match){
        var ergebnisAsString = match.ersterSatzGast + ':' + match.ersterSatzHeim + ' ' + match.zweiterSatzGast + ':' + match.zweiterSatzHeim;
        
        if(match.dritterSatzGast > 0 || match.dritterSatzHeim > 0){
            ergebnisAsString = ergebnisAsString + ' ' + match.dritterSatzGast + ':' + match.dritterSatzHeim;
        }

        if(match.aufgabeHeim){
            ergebnisAsString = ergebnisAsString + ' Aufg. ' + match.heim.getLabelTruncated();
        }
        if(match.aufgabeGast){
            ergebnisAsString = ergebnisAsString + ' Aufg. ' + match.gast.getLabelTruncated();
        }
        
        return ergebnisAsString;
    }

    render() {
        const { gespielt } = this.state;

        return (
            <div>
                <h1>{this.props.header}</h1>

                {gespielt === undefined  || gespielt.length === 0 ?
                    //Wenn Forderungen NULL ist
                    <p>Momentan wurden keine Matches gespielt.</p>
                    :
                    <div></div>
                }
                
           
                
                {!!gespielt.length &&
                <table className="table table-striped">
                    <tbody>
                    {gespielt.map((match, index) =>
                        <tr key={match.id}>
                            <td key={match.id + '_termin_datum'}>
                                {moment(match.spieltermin).format('DD.MM.YY')}
                            </td>
                            
                            <td style={{fontWeight: match.isHeimWinner() ? "normal" : "bold" }} key={match.id + '_herausgeforderter_name'}>
                                {match.gast.getLabel()}
                            </td>

                            <td>
                                -
                            </td>
                            
                            <td style={{fontWeight: match.isHeimWinner() ? "bold" : "normal" }} key={match.id + '_fordernder_name'}>
                                {match.heim.getLabel()}
                            </td>
                            <td key={match.id + '_ergebnis'}>
                                {this.getDisplayMatchErgebnis(match)}
                            </td>
                        </tr>
                        )} 
                    </tbody> 
                </table>    
                    
                }
            </div>
        );
    }
}

export default Gespielt;