import React, {Component} from 'react';
import {setErrorState} from '../../../util/ErrorUtil';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import {Form, notification, Select} from 'antd';
import {ranglisteMixedEintragService} from "../../../_services/ranglisteMixedEintragService";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {nutzerService} from "../../../_services/nutzerService";
import NutzerEintragNew from "./NutzerEintragNew";

const FormItem = Form.Item;

class NutzerEintragMixedNew extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            disableSubmitButton: false,
            errorMsg: null,
            partner1: {
                value: ''
            },
            partner2: {
                value: ''
            },
            possibleMixedNutzerList: []
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.afterSave = this.afterSave.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    loadData(){
        this.loadPossibleNutzer();
    }

    loadPossibleNutzer(){
        nutzerService.getAllNutzerNichtTeilMixedrangliste()
            .then(response => {
                if (this._isMounted) {
                    this.setState({possibleMixedNutzerList: response});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }

    handleSubmit(event) {
        //event.preventDefault();
        
        this.setState({
            disableSubmitButton: true
        });
        
        const mixedpaarungFromExistingNewRequest = {
            nutzerId1: this.state.partner1.value,
            nutzerId2: this.state.partner2.value
        }

        ranglisteMixedEintragService.createWithNewMixedpaarungFromNutzer(mixedpaarungFromExistingNewRequest)
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Die Mixedpaarung wurde erfolgreich erstellt!",
            });
            
            this.props.history.push("/verwaltung-mixed/nutzer/list");

            //ich navigier oben ja weg. Also eig ist die Komponente unmounted und das hier auch nicht mehr noetig
            //sonst ein warning in der Konsole: 'Can't perform a React state update on an unmounted component.'...
            // this.setState({
            //     disableSubmitButton: false
            // });

            
        }).catch(error => {
            this.setState({
                errorMsg: error.message,
                disableSubmitButton: false
            });
        });
    }


    cancel(){
        this.props.history.push("/verwaltung-mixed/nutzer/list");
    }

    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }


    isFormInvalid() {
        return false;
    }

    afterSave(){
        //wurde ja ein neuer Nutzer angeleget
        this.loadData();
    }


    render() {
        const { possibleMixedNutzerList } = this.state;

        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        let nutzerItems = possibleMixedNutzerList.map((nutzer) =>
            <Select.Option
                value={nutzer.id}
                label={nutzer.vorname + ' ' + nutzer.nachname}
                key={nutzer.id}>
                {nutzer.vorname} {nutzer.nachname}
            </Select.Option>
        );

        return (
        <div className="signup-container">
            <h1 className="page-title">Mixedpaarung erstellen</h1>
            <div className="signup-content">

                {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                }

                <Form {...layout} onFinish={this.handleSubmit} className="signup-form">
                    <FormItem
                        label="Nutzer (m)"
                        name="partner1"
                        rules={[{
                            required: true,
                            message: 'Bitte gib einen Mixed-Partner an' }]}
                        hasFeedback
                        validateStatus={this.state.partner1.validateStatus}
                        help={this.state.partner1.errorMsg}>
                    <Select
                        onChange={(value, event) => this.handleSelectInputChange(value, 'partner1')}
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Tippe um zu Suchen..."
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.label.toUpperCase().indexOf(input.toUpperCase()) !== -1
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                    >
                        {nutzerItems}
                    </Select>
                    </FormItem>


                    <FormItem
                        label="Nutzer (w)"
                        name="partner2"
                        rules={[{
                            required: true,
                            message: 'Bitte gib einen Mixed-Partner an' }]}
                        hasFeedback
                        validateStatus={this.state.partner2.validateStatus}
                        help={this.state.partner2.errorMsg}>
                        <Select
                            onChange={(value, event) => this.handleSelectInputChange(value, 'partner2')}
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Tippe um zu Suchen..."
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.label.toUpperCase().indexOf(input.toUpperCase()) !== -1
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                        >
                            {nutzerItems}
                        </Select>
                    </FormItem>

                    <FormItem wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="submit"
                                size="large"
                                className="signup-form-button"
                                disabled={this.isFormInvalid() || this.state.disableSubmitButton}>Mixedpaarung erstellen</Button>
                        <Button type="submit"
                                size="large"
                                className="signup-form-button"
                                onClick={() => { this.cancel() }}>Abbrechen</Button>
                    </FormItem>
                </Form>
            </div>

            <NutzerEintragNew
                headerObject={"Nutzer"}
                spieltEinzelRanglisteDefaultValue={false}
                onAfterSave={this.afterSave}
                onCancel={this.redirectToList}/>

        </div>

        );
    }



}

export default NutzerEintragMixedNew;