import React from 'react'
import {ranglisteMixedEintragService} from "../../../_services/ranglisteMixedEintragService";
import {setErrorState} from "../../../util/ErrorUtil";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {Form, notification, Radio, Select, Space} from "antd";
import {Button} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {nutzerService} from "../../../_services/nutzerService";

const FormItem = Form.Item;

class RegisterMixedProfil extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            disableSubmitButton: false,
            errorMsg: null,
            geschlecht: 'm',
            geschlecht2: {
                value: ''
            },
            partner: {
                value: ''
            },
            possibleMixedNutzerList: []
        }

        this.loadPossibleNutzer = this.loadPossibleNutzer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadData(){
        this.loadPossibleNutzer();
    }

    loadPossibleNutzer(){
        nutzerService.getAllNutzerNichtTeilMixedrangliste()
            .then(response => {
                if (this._isMounted) {
                    this.setState({possibleMixedNutzerList: response});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }

    handleSubmit(event) {
        //event.preventDefault();

        this.setState({
            disableSubmitButton: true
        });

        var maennlicherNutzerId = this.props.currentUser.id;
        var weiblicherNutzerId = this.state.partner.value;

        if(this.state.geschlecht === 'w'){
            maennlicherNutzerId = this.state.partner.value;
            weiblicherNutzerId = this.props.currentUser.id;
        }

        const mixedpaarungNewRequest = {
            nutzerId1: maennlicherNutzerId,
            nutzerId2: weiblicherNutzerId
        }
        ranglisteMixedEintragService.createWithNewMixedpaarungFromNutzer(mixedpaarungNewRequest)
            .then(response => {
                notification.success({
                    message: 'Vereinsrangliste',
                    description: "Die Mixedpaarung wurde erfolgreich erstellt!",
                });

                this.props.history.push("/rangliste-mixed/home");
                this.setState({
                    disableSubmitButton: false
                });

            }).catch(error => {
            this.setState({
                errorMsg: error.message,
                disableSubmitButton: false
            });
        });
    }

    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }

    isFormInvalid() {
        return false;
    }


    handleRadioChange(event) {
        this.setState({
            geschlecht : event.target.value
        });
    }

  render()
  {

      const { possibleMixedNutzerList } = this.state;

      const layout = {
          labelCol: {
              span: 8,
          },
          wrapperCol: {
              span: 16,
          },
      };

      let nutzerItems = possibleMixedNutzerList.map((nutzer) =>
          <Select.Option
              value={nutzer.id}
              label={nutzer.vorname + ' ' + nutzer.nachname}
              key={nutzer.id}>
              {nutzer.vorname} {nutzer.nachname}
          </Select.Option>
      );

      var labelPartner = 'Meine Partnerin (w):';
      var text = 'eine weibliche Spielpartnerin'
      if(this.state.geschlecht === 'w'){
          labelPartner = 'Mein Partner (m):';
          text = 'einen männlichen Spielpartner'
      }

    return (
        <React.Fragment>
            {!!this.props.currentUser && this.props.currentUser.id &&
                <div className="content-container">
                    <h3>
                        Anmeldung Mixed-Rangliste
                    </h3>

                    <div>
                        {!!this.state.errorMsg &&
                            <ErrorMessage message={this.state.errorMsg}/>
                        }

                        Ich bin ...
                            <Radio.Group style={{paddingLeft: '10px'}} onChange={this.handleRadioChange} value={this.state.geschlecht}>
                                <Space direction="vertical">
                                    <Radio value={'m'}>männlich</Radio>
                                    <Radio value={'w'}>weiblich</Radio>
                                </Space>
                            </Radio.Group>

                            <div style={{paddingTop: '1.5em'}}/>
                            Bitte wähle dir {text} aus:
                            <Form {...layout} onFinish={this.handleSubmit} className="signup-form">
                                <FormItem
                                    label={labelPartner}
                                    name="partner"
                                    style={{paddingTop: '0.5em'}}
                                    rules={[{
                                        required: true,
                                        message: 'Bitte gib einen Mixed-Partner an' }]}
                                    hasFeedback
                                    validateStatus={this.state.partner.validateStatus}
                                    help={this.state.partner.errorMsg}>
                                    <Select
                                        onChange={(value, event) => this.handleSelectInputChange(value, 'partner')}
                                        showSearch
                                        style={{
                                            width: 200
                                        }}
                                        placeholder="Suche..."
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.label.toUpperCase().indexOf(input.toUpperCase()) !== -1
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    >
                                        {nutzerItems}
                                    </Select>
                                </FormItem>


                                <FormItem wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                                    <Button type="submit"
                                            size="large"
                                            className="signup-form-button"
                                            disabled={this.isFormInvalid() || this.state.disableSubmitButton}>Mixedpaarung erstellen</Button>
                                </FormItem>
                            </Form>
                        </div>
                </div>
            }
        </React.Fragment>
    );
  }
}
export default withRouter(RegisterMixedProfil);