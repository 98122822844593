import React from 'react';
import SpieltermineMixed from "../../Spieltermine/SpieltermineMixed";
import GespieltAllMixed from "../../Gespielt/GespieltAllMixed";

class SpieltermineVerwaltungMixed extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            gespielt: []
        };
    }
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
  
        return (
            <div className='print-margin-top'>
                <div id="printBox">
                    <SpieltermineMixed currentUser={this.props.currentUser} adminView={true}/>
                </div>

                <div className='print_hide'>
                    <GespieltAllMixed adminView={true}/>
                </div>
            </div>
        );
    }
}


export default SpieltermineVerwaltungMixed;