import {setErrorState} from "../../util/ErrorUtil";
import Rangliste from "../component/Ranglistel";
import {ranglisteMixedEintragService} from "../../_services/ranglisteMixedEintragService";
import RanglistenMixedEintragDto from "../../dto/match/RanglistenMixedEintragDto";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class RanglisteMixed extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      ranglistenEintraege: [],
      currentRanglistenEintrag: {},
      eintragLoaded: false
    }
    this.loadCurrentMixedpaarungFromLoggedInUser = this.loadCurrentMixedpaarungFromLoggedInUser.bind(this);
  }

  loadData(){
    this.loadMixedpaarungen();
    this.loadCurrentMixedpaarungFromLoggedInUser();

  }

  loadCurrentMixedpaarungFromLoggedInUser(){
    if(this.props.currentUser !== null && this.props.currentUser.id > 0){
      const loggedInNutzerId = this.props.currentUser.id;
      ranglisteMixedEintragService.getRanglisteMixedEintragByNutzerId(loggedInNutzerId)
          .then(response => {
            if (this._isMounted) {
              const mapped = new RanglistenMixedEintragDto(response);
              this.setState({
                currentRanglistenEintrag: mapped,
                eintragLoaded: true
              });
            }
          })
          .catch(error => {
            console.log(error);
            setErrorState(error, this);
            this.setState({
              eintragLoaded: true
            });
          });
    }
  }

  loadMixedpaarungen(){
    ranglisteMixedEintragService.getAllRanglistenMixedpaarungenSorted()
        .then(response => {
          if (this._isMounted) {
            const mapped = response.map((element) => new RanglistenMixedEintragDto(element));
            this.setState({
              ranglistenEintraege: mapped,
              eintragLoaded: true
            });
          }
        })
        .catch(error => {
          if(!error instanceof SyntaxError){
            setErrorState(error, this);
            this.setState({
              eintragLoaded: true
            });
            //SyntaxError:  JSON.parse: unexpected end of data at line 1 column 1 of the JSON data
            //immer wenn kein Ranglsiteneintrag fuer dein eingeloggten Nutzer gefunden werden kann.
            //Also immer wenn man nicht in der Einzel- oder Mixed-Rangliste mitspielt, sich aber eingelgogged hat.
          }

        });
  }

  componentDidMount()
  {
    this._isMounted = true;

    this.loadData();
  }

  componentWillUnmount()
  {
    this._isMounted = false;
  }

  //braucht man fuer das Mischen der RanglisteEinzel.
  //und dem ein/ausloggen von Nutzern
  componentDidUpdate(prevProps, prevState)
  {
    if (prevProps.refreshCount !== this.props.refreshCount) {
      this.loadData();
    }

    if (prevProps.currentUser !== this.props.currentUser) {
      this.loadData();
    }
  }



  render() {

    var loggedInNutzer = this.props.currentUser;

    return (
        <div>
          {!!this.state.eintragLoaded  &&
            <Rangliste
                heading={'Mixed Rangliste'}
                ranglistenEintraege={this.state.ranglistenEintraege}
                loggedInNutzer={loggedInNutzer}
                currentRanglistenEintrag={this.state.currentRanglistenEintrag}
                editForderungUrl={'/rangliste-mixed/forderung/'}/>
          }
        </div>
    )
  }
}
// end::app[]

export default RanglisteMixed;