import RanglistenEinzelEintragDto from "./RanglistenEinzelEintragDto";
import ForderungDto from "./ForderungDto";

export default class ForderungEinzelDto extends ForderungDto {
    constructor(forderungJson) {
        super(forderungJson);
        if(forderungJson !== null && forderungJson !== undefined) {
            this.fordernder = new RanglistenEinzelEintragDto(forderungJson.fordernder);
            this.herausgeforderter = new RanglistenEinzelEintragDto(forderungJson.herausgeforderter);
        }
    }

    isDoppel(){
        return false;
    }

    containsNutzer(nutzer){
        if(this.fordernder.nutzer.id === nutzer.id || this.herausgeforderter.nutzer.id === nutzer.id){
            return true;
        }

        return false;
    }

    //erwartet fuer Einzel: einen Nutzer
    //fuer Doppel eine mixedpaarung
    containsNutzerOrMixedpaarung(nutzer){
        return this.containsNutzer(nutzer);
    }

    //wenn ich das behalten will, muesste ich die Methode umbennenen, dass es sie nicht doppelt gibt. Sonst gibts auch ein Wraning in der Konsole
    // containsNutzer(nutzerId){
    //     return this.fordernder.containsNutzer(nutzerId) || this.herausgeforderter.containsNutzer(nutzerId);
    // }
}