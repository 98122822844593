import React, { Component } from 'react';
import {mischeRangliste} from "../../_services/ranglisteEinzelEintragService";
import { setErrorState } from '../../util/ErrorUtil';


import { Button } from 'react-bootstrap';
import RanglisteEinzel from '../../rangliste/einzel/RanglisteEinzel';
import {notification} from "antd";

class RanglisteShuffleEinzel extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            ranglisteShuffled: 0
        };
        
       
        this.mischeRangliste = this.mischeRangliste.bind(this);
    }

    mischeRangliste(){
        mischeRangliste()        
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Die RanglisteEinzel wurde gemischt!",
            });
            this.setState({ranglisteShuffled: this.state.ranglisteShuffled + 1});
        }).catch(error => {
            setErrorState(error, this);
        });
    }
    
    render() {
        const { ranglisteShuffled} = this.state;

        return (
            <div>
                <h1>Rangliste mischen</h1>
                <p>
                    Hier kann die Rangliste nach dem Zufallsprinzip neu durchmischt werden
                </p>
                <RanglisteEinzel currentUser={this.props.currentUser} refreshCount={ranglisteShuffled}/>
                <Button type="submit"
                        size="large"
                        className="signup-form-button"
                        onClick={() => { this.mischeRangliste() }}>Rangliste mischen</Button>
            </div>
        )
    }
}

export default RanglisteShuffleEinzel;