import React from 'react';
import moment from 'moment';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

class GespieltAll extends React.Component {
    _isMounted = false;

    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getDisplayMatchErgebnis(match){
        var ergebnisAsString = match.ersterSatzGast + ':' + match.ersterSatzHeim + ' ' + match.zweiterSatzGast + ':' + match.zweiterSatzHeim;
        
        if(match.dritterSatzGast > 0 || match.dritterSatzHeim > 0){
            ergebnisAsString = ergebnisAsString + ' ' + match.dritterSatzGast + ':' + match.dritterSatzHeim;
        }

        if(match.aufgabeHeim){
            ergebnisAsString = ergebnisAsString + ' Aufg. ' + match.heim.getLabelTruncated();
        }
        if(match.aufgabeGast){
            ergebnisAsString = ergebnisAsString + ' Aufg. ' + match.gast.getLabelTruncated();
        }
        
        return ergebnisAsString;
    }


    render() {
        
        const { gespielt } = this.props;
        const isAdminView = this.props.adminView;


        return (    <div>


                {!!gespielt.length &&

                    <table className="table table-striped">
                        <tbody>
                        {gespielt.map((match) =>
                            <tr key={match.id}>
                                <td key={match.id + '_termin_datum'}>
                                    {moment(match.spieltermin).format('DD.MM.YY')}
                                </td>

                                <td style={{fontWeight: match.isHeimWinner() ? "normal" : "bold" }} key={match.id + '_herausgeforderter_name'}>
                                    {match.gast.getLabel()}
                                </td>

                                <td>
                                    -
                                </td>

                                <td style={{fontWeight: match.isHeimWinner() ? "bold" : "normal" }}  key={match.id + '_fordernder_name'}>
                                    {match.heim.getLabel()}
                                </td>
                                <td key={match.id + '_ergebnis'}>
                                    {this.getDisplayMatchErgebnis(match)}
                                </td>

                                {!!isAdminView &&
                                <React.Fragment>
                                    <td>
                                        <Button type="primary" onClick={e => this.props.editGespielt(e, match.id)} title={"Bearbeiten"}>
                                            <FontAwesomeIcon icon={faPen} style={{display: 'inline-block', verticalAlign: '-0.095em'}}/>
                                        </Button>
                                    </td>

                                    <td>
                                        <Button type="primary" variant={"danger"}
                                                onClick={e => this.props.deleteGespielt(e, match.id)} title={"Löschen"}>
                                            <FontAwesomeIcon icon={faTrashAlt} style={{display: 'inline-block', verticalAlign: '-0.095em'}}/>
                                        </Button>
                                    </td>
                                </React.Fragment>
                                }
                                {!!isAdminView === false &&
                                <React.Fragment>
                                    <td></td>
                                    <td></td>
                                </React.Fragment>
                                }
                            </tr>
                        )}
                        </tbody>
                    </table>


                }

            </div>
        
        );
    }
}

export default GespieltAll;