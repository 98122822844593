// tag::ForderungSpieltermin[]
// der Abschnitt zum Eintragen des Spieltermins
import ForderungSpieltermin from "./ForderungSpieltermin";

// tag::vars[]
const React = require('react');
// end::vars[]


class ForderungSpielterminEinzel extends React.Component{

  render() {
    return (

        <ForderungSpieltermin
            isMixed={false}
            nutzer={this.props.nutzer}
            existentForderung={this.props.existentForderung}
            loggedInNutzer={this.props.loggedInNutzer}
            termin={this.props.termin}
            isForderung={this.props.isForderung}
        />
    )
  }

}
// end::ForderungSpieltermin[]


export default ForderungSpielterminEinzel;