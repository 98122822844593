import {notification} from "antd";

export function setErrorState(error, comp) {
  if (comp._isMounted) {
    comp.setState({
      hasError: error,
      errorMsg: error.message
    });
  }

  if(error.status === 401){
    //TODO die history war iwie ab und zu mal undefined
    //keine Ahnung wieso
    if(comp.props.history){
      comp.props.history.push("/login");
      notification.info({
        message: 'Vereinsrangliste',
        description: 'Bitte melden Sie sich an, um fortzuahren.'
      });
    }

    return;
  }
  
  notification.error({
    message: 'Vereinsrangliste',
    description: error.message || 'Sorry! Etwas ist schief gelaufen. Versuche es Bitte noch einmal!'
  });
}
