import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const gespieltMixedService = {
  getGespieltById,
  saveGespielt,
  editGespielt,
  getLetztenXSorted,
  getPagedSorted,
  getGespielteMixedForNutzer,
  getAllByHerausgeforderter,
  getAllByFordernder,
  deleteGespieltById
};


export function getGespieltById(gespieltId)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/getById/"  + gespieltId,
    method: 'GET'
  });
}

//hier wird die ForderungDto zu dem GespieltDto-Request gesucht.
export function saveGespielt(gespieltRequest) {
  return request({
    url: API_BASE_URL + "/gespielt-mixed/save",
    method: 'POST',
    body: JSON.stringify(gespieltRequest)
  });
}

//hier wird wirklich nur das GespieltDto-Objekt geändert. Ohne die ForderungDto zu beachten.
export function editGespielt(gespieltRequest) {
  return request({
    url: API_BASE_URL + "/gespielt-mixed/edit",
    method: 'POST',
    body: JSON.stringify(gespieltRequest)
  });
}

export function getLetztenXSorted(count)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/list/last/" + count,
    method: 'GET'
  });
}

export function getPagedSorted(pageNumber, pageSize)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/pagedlist?page=" + pageNumber + "&size=" + pageSize,
    method: 'GET'
  });
}

export function getGespielteMixedForNutzer(nutzerId)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/getByNutzerId/" + nutzerId,
    method: 'GET'
  });
}

export function getAllByHerausgeforderter(mixedpaarungId)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/getAllByHerausgeforderter/" + mixedpaarungId,
    method: 'GET'
  });
}

export function getAllByFordernder(nutzerId)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/getAllByFordernder/" + nutzerId,
    method: 'GET'
  });
}

//loescht das Match
export function deleteGespieltById(gespieltId)
{
  return request({
    url: API_BASE_URL + "/gespielt-mixed/delete/"  + gespieltId,
    method: 'DELETE'
  });
}