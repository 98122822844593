import GespieltDto from "./GespieltDto";
import NutzerDto from "./NutzerDto";

export default class GespieltEinzelDto extends GespieltDto{
    constructor(gespieltJson) {
        super(gespieltJson);

        if(gespieltJson !== null && gespieltJson !== undefined) {
            this.heim = new NutzerDto(gespieltJson.fordernderNutzer);
            this.gast = new NutzerDto(gespieltJson.herausgeforderterNutzer);
        }
    }

    getHeim(){
        return this.heim;
    }

    getGast(){
        return this.gast;
    }
}