// tag::vars[]

const React = require('react');
// end::vars[]

// tag::FehlerDuKannstNichtFordern[]
// wenn einer der beiden RanglistenEintragDto schon Fordersungen/Herausforderungen hat
class FehlerDuKannstNichtFordern extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      theirForderungen: this.props.theirForderungen,
      loggedInNutzer: this.props.loggedInNutzer,
      ranglistenEintrag: this.props.ranglistenEintrag,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { loggedInNutzer, ranglistenEintrag,  theirForderungen} = this.state;
    let ichHabeForderungen = false;
    let erHatForderungen = false;

    var anzeigeText = '';

    for(var i=0; i < theirForderungen.length; i++){
      var forderung = theirForderungen[i];
      if(forderung.containsNutzerOrMixedpaarung(loggedInNutzer)){
        ichHabeForderungen = true;
      }
      if(forderung.containsNutzerOrMixedpaarung(ranglistenEintrag.nutzer)){
        erHatForderungen = true;
      }

      const nutzerLabelShort = ranglistenEintrag.getLabelTruncatedForHeading();

      var txt = 'kann nicht';
      var duIhrKlein = 'du';
      var duIhrGross = 'Du';
      var hastHabt = 'hast';
      var hatHaben = 'hat';
      var kannKoennen = 'kann';
      var kannstKönnt = 'kannst';
      var musstMuesst = 'musst';
      var deineEure = 'deine';
      var seineIhre = 'seine';
      if(ranglistenEintrag.isDoppel()){
        txt = 'können nicht'
        duIhrKlein = 'ihr';
        duIhrGross = 'Ihr';
        hastHabt = 'habt';
        hatHaben = 'haben';
        kannKoennen = 'können';
        kannstKönnt = 'könnt';
        musstMuesst = 'müsst';
        deineEure = 'eure';
        seineIhre = 'ihre';
      }

      if(ichHabeForderungen && erHatForderungen){
        //wenn man mehrere html-Elemente in eine Variable packen moechte, dann muss man das so in ein <React.Fragment> wrappen
        anzeigeText =  (
            <React.Fragment>
              <p>{duIhrGross} {hastHabt} bereits beide laufende Forderungen, daher {kannstKönnt} {duIhrKlein} keine weiteren Spieler fordern und {nutzerLabelShort} {txt} gefordert werden.</p>
              <p>Um {nutzerLabelShort} zu fordern, müsst ihr zuerst eure laufenden Forderungen spielen und ein Ergebnis eintragen.</p>
            </React.Fragment>
        );
      } else if(ichHabeForderungen){
        anzeigeText =  (
            <React.Fragment>
              <p>{duIhrGross} {hastHabt} bereits laufende Forderungen, daher {kannstKönnt} {duIhrKlein} keine weiteren Spieler fordern.</p>
              <p>Um {nutzerLabelShort} zu fordern, {musstMuesst} {duIhrKlein} zuerst {deineEure} laufenden Forderungen spielen und ein Ergebnis eintragen.</p>
            </React.Fragment>
        );
      } else if(erHatForderungen){
        anzeigeText =  (
            <React.Fragment>
              <p>{nutzerLabelShort} {hatHaben} bereits laufende Forderungen und {kannKoennen} daher nicht gefordert werden.</p>
              <p>Um {nutzerLabelShort} zu fordern, {musstMuesst} {duIhrKlein} warten, bis {nutzerLabelShort} {seineIhre} Forderungen gespielt und ein Ergebnis eingetragen {hatHaben}.</p>
            </React.Fragment>
        );

      }

    }

    return (
        <React.Fragment>
          {!!theirForderungen &&
          <div className="content-container">
            <h1>Fordere {ranglistenEintrag.getLabelForHeading()} </h1>

            <div>
              {anzeigeText}
            </div>


          </div>
          }
        </React.Fragment>
    )
  }

}
// end::FehlerDuKannstNichtFordern[]

export default FehlerDuKannstNichtFordern;