import RanglistenEintragDto from "./RanglistenEintragDto";
import NutzerDto from "./NutzerDto";

export default class fordernderRanglistenEintrag extends RanglistenEintragDto {
    
    constructor(nutzerJson) {
        super(nutzerJson);
        if(nutzerJson !== null && nutzerJson !== undefined) {
            this.nutzer = new NutzerDto(nutzerJson.nutzer);
        }
    }

    isDoppel(){
        return false;
    }

    //Im Einzel gehen wir hier auf den Nutzer
    //Im Mixed geht das nicht, da die Paarung ja aus zwei Nutzern besteht. Da haben wir den Nutzer nicht.
    //Daher gehen wir da auf den Nutzer des eingeloggten Nutzers.
    containsNutzer(nutzer){
        return this.nutzer.containsNutzer(nutzer);
    }

    getLabel(){
        return this.nutzer.getLabel();
    }

    getLabelTruncated(){
        return this.nutzer.getLabelTruncated();
    }

    getLabelForHeading(){
        return this.nutzer.getLabelForHeading();
    }

    getLabelTruncatedForHeading(){
        return this.nutzer.getLabelTruncatedForHeading();
    }

    getLabelElement(isResponsiveView) {
        return this.nutzer.getLabelElement(isResponsiveView);
    }

    getKontaktinfoElement() {
        return this.nutzer.getKontaktinfoElement();
    }

    getKey(){
        return this.nutzer.getKey();
    }
}