import React from 'react';
import {setErrorState} from '../../../util/ErrorUtil';
import {forderungMixedService} from "../../../_services/forderungMixedService";
import ForderungDoppelDto from "../../../dto/match/ForderungDoppelDto";
import SpieltermineMixed from "../../../Spieltermine/SpieltermineMixed";

class MeineForderungenMixed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            forderungen: []
        };

        this.loadForderungen = this.loadForderungen.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadForderungen();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadForderungen();
        }
    }

    loadForderungen() {
        forderungMixedService.getAllForderungenForNutzerSorted(this.props.currentUser.id)
            .then(response => {
                const mapped = response.map((element) => new ForderungDoppelDto(element));
                if (this._isMounted) {
                    this.setState({forderungen: mapped});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }


    render() {
        const { forderungen } = this.state;

        return (
            <div>
                <SpieltermineMixed forderungen={forderungen}
                                  reloadForderungen={this.loadForderungen} 
                                  header={"Deine anstehenden Forderungen"}
                                  currentUser={this.props.currentUser}/>
            </div>
        );
    }
}


export default MeineForderungenMixed;