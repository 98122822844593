import {notification} from "antd";
import {setErrorState} from "../../../util/ErrorUtil";
import {API_BASE_URL} from "../../../constants";
import NutzerListPaged from "../components/NutzerListPaged";
import {ranglisteMixedEintragService} from "../../../_services/ranglisteMixedEintragService";
import RanglistenMixedEintragDto from "../../../dto/match/RanglistenMixedEintragDto";
// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class EintragListMixed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {employees: [], page: 0, pageSize: 10, lastPage: 0, links: {}};
        //this.onCreate = this.onCreate.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangePageInfo = this.onChangePageInfo.bind(this);
    }


    componentDidMount() {
        this._isMounted = true;

        const page = 0;
        const pagesize = 10;

        this.setState({
            isLoading: true,
        });

        this.loadData(pagesize, page);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    onChangePageInfo(pageSize, page, lastPage) {
        this.setState({
            pageSize: pageSize,
            page: page,
            lastPage: lastPage
        });
    }

    // tag::delete[]
    onDelete(mixedpaarung) {
        ranglisteMixedEintragService.deleteMixedpaarungAndNutzerById(mixedpaarung.id).then(response => {
            this.loadData(this.state.pageSize, this.state.page);

            notification.success({
                message: 'Vereinsrangliste',
                description: "Die Mixedpaarung wurde erfolgreich gelöscht!",
            });
        })
            .catch(error => {
                setErrorState(error, this);
            });
    }
    // end::delete[]

    // tag::navigate[]
    onNavigate(navUri) {
        ranglisteMixedEintragService.getMixedpaarungByNavUri(navUri).then(response => {
            const mapped = response.content.map((element) => new RanglistenMixedEintragDto(element));
            this.setState({
                employees: mapped,
                attributes: this.state.attributes,
                pageSize: this.state.pageSize,
                links: this.createLinks(response)
            });
        });

    }
    // end::navigate[]

    getLastPage(responseEntity){
        const totalPages = responseEntity.totalPages;
        const lastPage = totalPages - 1;

        return lastPage;
    }

    createLinks(responseEntity) {
        const actualPage = responseEntity.number;
        const totalPages = responseEntity.totalPages;

        const firstPage = 0;
        const lastPage = totalPages - 1;
        const nextPage = actualPage + 1;
        const prevPage = actualPage - 1;

        this.setState({
            lastPage: lastPage
        });


        //const baseLink = 'http://localhost:8080/api/nutzer/pagedlist';
        const baseLink = API_BASE_URL + '/nutzer/pagedlist';
        const navLinks = {};

        if(actualPage !== firstPage){
            const first = {href: baseLink + '?page=' + firstPage + '&size=10'};
            navLinks.first = first;
        }

        if(actualPage !== lastPage){
            const last = {href: baseLink + '?page=' + lastPage + '&size=10'};
            navLinks.last = last;
        }

        if(actualPage < lastPage){
            const next = {href: baseLink + '?page=' + nextPage + '&size=10'};
            navLinks.next = next;
        }

        if(actualPage > firstPage){
            const prev = {href: baseLink + '?page=' + prevPage + '&size=10'};
            navLinks.prev = prev;
        }

        return navLinks;
    }

    loadData(pagesize, page){
        ranglisteMixedEintragService.getAllMixedpaarungenPaged(page, pagesize)
            .then(response => {
                const navLinks = this.createLinks(response);
                const lastPage = this.getLastPage(response);

                const mapped = response.content.map((element) => new RanglistenMixedEintragDto(element));
                if (this._isMounted) {
                    this.setState({
                        employees: mapped,
                        links: navLinks,
                        isLoading: false,
                        lastPage: lastPage
                    });
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                });

                setErrorState(error, this);
            });
    }

    render() {
        const isLoading = this.state.isLoading;
        return (
            <React.Fragment>
                {!! !isLoading &&

                    <NutzerListPaged isDoppel={true}
                                      ranglistenEintraege={this.state.employees}
                                  links={this.state.links}
                                  pageSize={this.state.pageSize}
                                  page={this.state.page}
                                  lastPage={this.state.lastPage}
                                  onNavigate={this.onNavigate}
                                  onDelete={this.onDelete}
                                  onChangePageInfo={this.onChangePageInfo}/>
                }
            </React.Fragment>
        )
    }
}
// end::app[]

export default EintragListMixed;