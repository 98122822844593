import React, {Component} from 'react';
import {checkEmailAvailability, signup} from '../../_services/authService';
import './Signup.css';
import {setErrorState} from '../../util/ErrorUtil';

import {Link} from 'react-router-dom';
import {
    EMAIL_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PHONE_MAX_LENGTH,
    USERNAME_MAX_LENGTH
} from '../../constants';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import {Checkbox, Form, Input, notification} from 'antd';
import ErrorMessage from "../../_components/notifications/ErrorMessage";

const FormItem = Form.Item;

class Signup extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            errorMsg: '',
            disableSubmitButton: false,
            vorname: {
                value: ''
            },
            nachname: {
                value: ''
            },
            telefonnummer: {
                value: ''
            },
            email: {
                value: '',
                validateStatus: 'success',
                errorMsg: null
            },
            password: {
                value: ''
            },
            isDatenschutzZugestimmt: {
                value: false
            }
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);

    }
    
    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleCheckboxChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const checked = target.checked;

        this.setState({
            [inputName] : {
                value: checked,
                ...validationFun(checked)
            }
        });
    }
    
    handleSubmit(event) {
        //event.preventDefault();
        
        this.setState({
            errorMsg: '',
            disableSubmitButton: true
        });

        //TODO: ich glaube das bringt nix. da nigends mehr isFormInvalid aufgerufen wird
        this.validateEmail();

        
        document.body.classList.add('busy-cursor');
        
        
        const signupRequest = {
            email: this.state.email.value,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            telefonnummer: this.state.telefonnummer.value,
            password: this.state.password.value
        };
        signup(signupRequest)
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Überprüfe deine Mails, um die Registrierung abzuschließen!",
            });
            
            this.props.history.push("/signup/infoconfirm");
            document.body.classList.remove('busy-cursor');
            this.setState({
                disableSubmitButton: false
            });
            
        }).catch(error => {
            this.setState({
                errorMsg: error.message
            });
            setErrorState(error, this);
            document.body.classList.remove('busy-cursor');
            this.setState({
                disableSubmitButton: false
            });
        });
    }

    isFormInvalid() {
        var invalid = this.areFormInputsInvalid();
        return invalid || !(this.state.isDatenschutzZugestimmt.validateStatus === 'success');
    }
    
    areFormInputsInvalid(){
        return !(this.state.vorname.validateStatus === 'success' &&
            this.state.nachname.validateStatus === 'success' &&
            this.state.telefonnummer.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success' &&
            this.state.password.validateStatus === 'success'
        );
    }

    toButtonDisable() {
        return (this.areFormInputsInvalid());
    }
    
    render() {
        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };
        
        return (
            <div className="signup-container">
                <h1 className="page-title">Registrierung</h1>
                {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                }
                <div className="signup-content">
                    <Form {...layout} onFinish={this.handleSubmit} className="signup-form">
                        <FormItem 
                            label="Vorname"
                            name="vorname"
                            rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Vornamen an' }]}
                            hasFeedback
                            validateStatus={this.state.vorname.validateStatus}
                            help={this.state.vorname.errorMsg}>
                            <Input 
                                size="large"
                                name="vorname" 
                                autoComplete="on"
                                placeholder="Dein Vorname"
                                value={this.state.vorname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateVorname)} />    
                        </FormItem>
                        <FormItem label="Nachname"
                                  name="nachname"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Nachnamen an' }]}
                                  hasFeedback
                                  validateStatus={this.state.nachname.validateStatus}
                                  help={this.state.nachname.errorMsg}>
                            <Input
                                size="large"
                                name="nachname"
                                autoComplete="on"
                                placeholder="Dein Nachname"
                                value={this.state.nachname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateNachname)}/>
                        </FormItem>
                        <FormItem label="Telefonnummer"
                                  name="telefonnummer"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deine Telefonnummer an' }]}
                                  hasFeedback
                                  validateStatus={this.state.telefonnummer.validateStatus}
                                  help={this.state.telefonnummer.errorMsg}>
                            <Input
                                size="large"
                                name="telefonnummer"
                                autoComplete="on"
                                placeholder="Deine Telefonnummer"
                                value={this.state.telefonnummer.value}
                                onChange={(event) => this.handleInputChange(event, this.validateTelefonnummer)}/>
                        </FormItem>
                        <FormItem 
                            label="E-Mail"
                            name="email"
                            rules={[{
                                required: true,
                                message: 'Bitte gib deinen E-Mail-Adresse an' }]}
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input 
                                size="large"
                                name="email" 
                                type="email" 
                                autoComplete="on"
                                placeholder="Deine Email-Adresse"
                                value={this.state.email.value}
                                onBlur={this.validateEmailAvailability}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                        </FormItem>
                        <FormItem 
                            label="Passwort"
                            name="password"
                            rules={[{
                                required: true,
                                message: 'Bitte gib ein Passwort an' }]}
                            validateStatus={this.state.password.validateStatus}
                            help={this.state.password.errorMsg}>
                            <Input 
                                size="large"
                                name="password" 
                                type="password"
                                autoComplete="off"
                                placeholder="Passwort zwischen 6 und 20 Zeichen" 
                                value={this.state.password.value} 
                                onChange={(event) => this.handleInputChange(event, this.validatePassword)} />    
                        </FormItem>
                        <FormItem className={"verticalAlignCeckbox"}
                                  name="isDatenschutzZugestimmt" 
                                  valuePropName="checked"
                                  initialValue={false}
                                  rules={[{ 
                                      required: true,
                                      transform: value => (value || undefined),  // Those two lines
                                      type: 'boolean',                           // Do the magic
                                      message: 'Bitte stimme den Datenschutzrichtlinien zu' }]}
                                  hasFeedback
                                  validateStatus={this.state.isDatenschutzZugestimmt.validateStatus}
                                  help={this.state.isDatenschutzZugestimmt.errorMsg}
                                  onChange={(event) => this.handleCheckboxChange(event, this.validateDatenschutzRichtlinien)}>
                            <Checkbox>Ja, ich stimme den <Link to="/impressum">Datenschutzrichtlinien</Link> zu.</Checkbox>
                        </FormItem>
                        <FormItem {...tailLayout}>
                            <Button type="submit"
                                size="large" 
                                className="myLargeBtn"
                                disabled={this.toButtonDisable() || this.state.disableSubmitButton}>Konto erstellen</Button>
                            <br/>Bereits registriert?
                            <br/>
                            <Link to="/login">Dann melde dich jetzt an!</Link>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateVorname = (vorname) => {
        if(!vorname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen Vornamen an. Die Angabe des Vornamens ist pflicht.`
            }
        } else if (vorname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Vorname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateNachname = (nachname) => {
        if(!nachname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen Nachnamen an. Die Angabe des Nachnamens ist pflicht.`
            }
        } else if (nachname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Nachname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateDatenschutzRichtlinien = (isZugestimmt) => {
        if(isZugestimmt === undefined || !isZugestimmt) {
            return {
                validateStatus: 'error',
                errorMsg: `Um die registrieren zu können, stimme bitte den Datenschutzrichtlinien zu.`
            }
        }
        
        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }
    
    validateTelefonnummer = (telefonnummer) => {
        if(!telefonnummer) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deine Telefonnummer an. Die Angabe einer Telefonnummer ist pflicht, damit dich andere Nutzer kontaktieren können.`
            }
        } else if (telefonnummer.length > PHONE_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Telefonnummer ist zu lang. Es sind maximal ${PHONE_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }


    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
        .then(response => {
            if(response.available) {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            } else {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                    }
                });
            }
        }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }

    validatePassword = (password) => {
        if(password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Das Passwort ist zu kurz. Das Passwort muss mindestens aus ${PASSWORD_MIN_LENGTH} Zeichen bestehen.`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Das Passwort ist zu lang. Es sind maximal ${PASSWORD_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };            
        }
    }

}

export default Signup;