import ForderungDto from "./ForderungDto";
import RanglistenMixedEintragDto from "./RanglistenMixedEintragDto";

export default class ForderungDoppelDto extends ForderungDto {
    constructor(forderungJson) {
        super(forderungJson);
        if(forderungJson !== null && forderungJson !== undefined){
            this.fordernder = new RanglistenMixedEintragDto(forderungJson.fordernder);
            this.herausgeforderter = new RanglistenMixedEintragDto(forderungJson.herausgeforderter);
        }
    }

    isDoppel(){
        return true;
    }

    //Im Einzel gehen wir hier auf den Nutzer
    //Im Mixed geht das nicht, da die Paarung ja aus zwei Nutzern besteht. Da haben wir den Nutzer nicht.
    //Daher gehen wir da auf den Nutzer des eingeloggten Nutzers.
    containsNutzer(nutzer){
        if(this.containsNutzerId(nutzer.id)){
            return true;
        }

        return false;
    }

    //erwartet fuer Einzel: einen Nutzer
    //fuer Doppel eine mixedpaarung
    containsNutzerOrMixedpaarung(mixedpaarung){
        if(mixedpaarung.id === this.fordernder.id || mixedpaarung.id === this.herausgeforderter.id){
            return true;
        }

        return false;
    }

    //wenn ich das behalten will, muesste ich die Methode umbennenen, dass es sie nicht doppelt gibt. Sonst gibts auch ein Wraning in der Konsole
    containsNutzerId(nutzerId){
        return this.fordernder.containsNutzerId(nutzerId) || this.herausgeforderter.containsNutzerId(nutzerId);
    }
}