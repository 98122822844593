// tag::ForderungsErgebnis[]
import './ForderungsErgebnis.css';
import ForderungsErgebnis from "./ForderungsErgebnis";

// tag::vars[]
const React = require('react');
// end::vars[]

class ForderungsErgebnisEinzel extends React.Component{

  render() {
    return (
        <ForderungsErgebnis
            herausgeforderterRanglistenEintrag={this.props.herausgeforderterRanglistenEintrag}
            fordernderRanglistenEintrag={this.props.fordernderRanglistenEintrag}
            isForderung={this.props.isForderung}/>
    )
  }

}
// end::ForderungsErgebnis[]



export default ForderungsErgebnisEinzel;
