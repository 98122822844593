// tag::ForderungsErgebnis[]
import './ForderungsErgebnis.css';
import {setErrorState} from "../../../util/ErrorUtil";
import {Checkbox, Form, Input, Switch} from "antd";
import {Button} from "react-bootstrap";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {gespieltMixedService} from "../../../_services/gespieltMixedService";
import {gespieltService} from "../../../_services/gespieltService";

const FormItem = Form.Item;

// tag::vars[]
const React = require('react');
// end::vars[]

class ForderungsErgebnis extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      //Ob es sich um die Sicht als "ForderungDto" (klick im Baum in der RanglisteEinzel) oder "Herausforderung" (kann im Profil unter "Meine Forderungen")
      isForderung: this.props.isForderung,
      herausgeforderterRanglistenEintrag: this.props.herausgeforderterRanglistenEintrag,
      fordernderRanglistenEintrag: this.props.fordernderRanglistenEintrag,

      ersterSatzFord: {
        value: '',
        inputWidth: 30,
      },
      ersterSatzHeraus: {
        value: '',
        inputWidth: 30,
      },

      zweiterSatzFord: {
        value: '',
        inputWidth: 30,
      },
      zweiterSatzHeraus: {
        value: '',
        inputWidth: 30,
      },

      dritterSatzFord: {
        value: '',
        inputWidth: 35,
      },
      dritterSatzHeraus: {
        value: '',
        inputWidth: 35,
      },
      aufgabeFordernder: {
        value: false
      },
      aufgabeHerausgeforderter: {
        value: false
      },
      aufgabe: false,
      errorMsg: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.changeInputSize = this.changeInputSize.bind(this);
    this.changeInputSizeSatz = this.changeInputSizeSatz.bind(this);
    this.changeInputSizeMTB = this.changeInputSizeMTB.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  }

  handleKeyUp(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    //hier kommt das Ergebnis der Funktion rein
    let ergebnis = validationFun(inputValue);

    //WIRKLICH NUR inputWidth im State setzen und die anderen Felder des states nicht veraendern.
    //hier wird der state in die Variable stateObj uebernommen
    //danach wird in stateObj der neue Wert von inputWidth aus dem Ergebnis gesetzt
    //WICHTIG: So wird im state wirklich nur inputWidth und value geaendert und errorMesg und validateStatus
    // und auch alle anderen Felder des States fuer dieses Eingabefeld bleiben gleich.
    //Sonst wuerden diese hier immer zurueck auf undefined gesetzt werden.
    let stateObj = this.state[inputName];
    stateObj.inputWidth = ergebnis.inputWidth;
    stateObj.value = inputValue;

    this.setState({
      [inputName] : stateObj
    });
  }

  handleSubmit(values)
  {
    //alte Fehlermeldungen zurücksetzen
    this.setState({
      errorMsg: ''
    });

    var validationResult = this.validateErgebnis();

    if(validationResult.validateStatus === 'error'){
      this.setState({
        errorMsg: validationResult.errorMsg
      });
      return;
    }

    var gespieltRequest = {
      fordernder: this.state.fordernderRanglistenEintrag,
      herausgeforderter: this.state.herausgeforderterRanglistenEintrag,
      ersterSatzFord: this.state.ersterSatzFord.value,
      ersterSatzHeraus: this.state.ersterSatzHeraus.value,
      zweiterSatzFord: this.state.zweiterSatzFord.value,
      zweiterSatzHeraus: this.state.zweiterSatzHeraus.value,
      dritterSatzFord: this.state.dritterSatzFord.value,
      dritterSatzHeraus: this.state.dritterSatzHeraus.value,

      aufgabeFordernder: this.state.aufgabeFordernder.value,
      aufgabeHerausgeforderter: this.state.aufgabeHerausgeforderter.value
    };

    //Wenn wir uns nicht in der "Fordern"-View befinden, sondern die View aus der Herausforderung-Sicht ist.
    //Also ich fordere nicht, sondern ich wurde herausgefordert.
    //In dieses Szenario kann man ueber den Menupunkt "Meine Forderungen" aus dem Nutzer-profil reinlaufen.
    //Dann wurde das Ergebnis in der UI falsch herum eingetragen. Daher muss man heim und Gast tauschen:
    if(!this.state.isForderung){
      gespieltRequest = {
        // gast: this.state.fordernderRanglistenEintrag,
        // heim: this.state.herausgeforderterRanglistenEintrag,
        fordernder: this.state.fordernderRanglistenEintrag,
        herausgeforderter: this.state.herausgeforderterRanglistenEintrag,

        //In der Anzeige sind Heim und gast vertauscht.
        //egal wer auf die Seite geht, der jenige, der drauf geht, steht immer als erstes und
        //soll immer aus seiner Sicht eintragen. Egal ob er gerade der fordernde oder der herausgeforderte ist.
        ersterSatzFord: this.state.ersterSatzHeraus.value,
        ersterSatzHeraus: this.state.ersterSatzFord.value,
        zweiterSatzFord: this.state.zweiterSatzHeraus.value,
        zweiterSatzHeraus: this.state.zweiterSatzFord.value,
        dritterSatzFord: this.state.dritterSatzHeraus.value,
        dritterSatzHeraus: this.state.dritterSatzFord.value,

        aufgabeFordernder: this.state.aufgabeHerausgeforderter.value,
        aufgabeHerausgeforderter: this.state.aufgabeFordernder.value
      };
    }

    if(this.isDoppel()){
      this.makeSaveCall(gespieltMixedService.saveGespielt, gespieltRequest);
    }else{
      this.makeSaveCall(gespieltService.saveGespielt, gespieltRequest);
    }
  }

  isDoppel(){
    return this.props.herausgeforderterRanglistenEintrag.isDoppel();
  }

  makeSaveCall(saveFunc, gespieltRequest){
    saveFunc(gespieltRequest)
        .then(response => {
          this.setState({
            successMsg: "Euer Ergebnis wurde erfolgreich gespeichert."
          });

          if(this.isDoppel()){
            window.location.href = '/rangliste-mixed/home';
          }else{
            window.location.href = '/';
          }


        }).catch(error => {
      setErrorState(error, this);
    });
  }

  isFormInvalid() {
    return !(this.isErsteBeidenSaezteInvalid() && this.isDritterSatzInvalid());
  }


  isErsteBeidenSaezteInvalid() {
    return !(this.state.ersterSatzFord.validateStatus === 'success' &&
        this.state.ersterSatzHeraus.validateStatus === 'success' &&
        this.state.zweiterSatzFord.validateStatus === 'success' &&
        this.state.zweiterSatzHeraus.validateStatus === 'success'
    );
  }
  isDritterSatzInvalid() {
    return (this.state.dritterSatzFord.validateStatus === 'error' ||
        this.state.dritterSatzHeraus.validateStatus === 'error'
    );
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }

  handleAufgabeChange(checked) {

    this.setState({
      aufgabe: checked
    });

    //dann auch Aufgabe Cehckboxen leeren. Die werden dann ja ausgeblendet und man koennte meinen als Nutzer,
    // dass man die dann nicht ab-checken muss, wenn man den Switch auf off schiebt.
    if(checked === false){
      this.setState({
        aufgabeFordernder: {
          value: false
        },
        aufgabeHerausgeforderter: {
          value: false
        }
      });
    }
  }

  render() {
    const { herausgeforderterRanglistenEintrag, fordernderRanglistenEintrag, ersterSatzFord, ersterSatzHeraus, zweiterSatzFord, zweiterSatzHeraus, dritterSatzFord, dritterSatzHeraus, isForderung } = this.state;

    const tableCellSpacer = {
      'paddingRight': '15px',
    };

    const buttonWrapper = {
      'display': 'table-cell',
      'verticalAlign': 'bottom',
    };

    const cnt = {
      'display': 'table',
    };

    var displayNameHeim = fordernderRanglistenEintrag.getLabel();
    var displayNameGast = herausgeforderterRanglistenEintrag.getLabel();


    //Wenn wir uns nicht in der "Fordern"-View befinden, sondern die View aus der Herausforderung-Sicht ist.
    //Also ich fordere nicht, sondern ich wurde herausgefordert.
    //In dieses Szenario kann man ueber den Menupunkt "Meine Forderungen" aus dem Nutzer-profil reinlaufen.
    if(!isForderung){
      displayNameHeim = herausgeforderterRanglistenEintrag.getLabel();
      displayNameGast = fordernderRanglistenEintrag.getLabel();
    }
    var begegnungsNamen =  (
        <React.Fragment>
          <span style={{whiteSpace: 'nowrap'}}>{displayNameHeim}</span> - <span style={{whiteSpace: 'nowrap'}}>{displayNameGast}</span>
        </React.Fragment>
    );

    let validierungsmeldung = '';

    if(ersterSatzFord.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + ersterSatzFord.errorMsg;
    }

    if(ersterSatzHeraus.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + ersterSatzHeraus.errorMsg;
    }

    if(zweiterSatzFord.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + zweiterSatzFord.errorMsg;
    }

    if(zweiterSatzHeraus.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + zweiterSatzHeraus.errorMsg;
    }

    if(dritterSatzFord.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + dritterSatzFord.errorMsg;
    }

    if(dritterSatzHeraus.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + dritterSatzHeraus.errorMsg;
    }

    return (
        <React.Fragment>

          <div>
            <h2>Ergebnis eintragen</h2>
            <p>
              Trage hier das Ergebnis aus deiner Sicht ein:
            </p>
            <p>
              Ein Nutzer musste aufgeben?
              <Switch name="aufgabe"
                      size="small"
                      style={{marginLeft: '5px'}}
                      checked={this.state.aufgabe}
                      onChange={(event) => this.handleAufgabeChange(event)}>
              </Switch>
            </p>

            <Form layout="inline" onFinish={this.handleSubmit} className="spieltermin-form">
              <table>
                <tbody>
                <tr>
                  <th></th>
                  <th colSpan="3">1. Satz</th>
                  <th colSpan="3">2. Satz</th>
                  <th colSpan="3">3. Satz/MTB</th>
                  <th></th>
                </tr>
                <tr>
                  <td style={{ 'paddingRight': '40px' }}><b>{begegnungsNamen}</b></td>
                  <td>
                    <Input name="ersterSatzFord"
                           value={this.state.ersterSatzFord.value}
                           placeholder="0"
                           autoComplete="off"
                           style={{padding: '4px 9px', width: this.state.ersterSatzFord.inputWidth+"px"}}
                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                           className={this.state.ersterSatzFord.validateStatus === 'error' ? 'error' : ''}
                           onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>
                  </td>
                  <td> : </td>
                  <td  style={tableCellSpacer}>
                    <Input
                        name="ersterSatzHeraus"
                        value={this.state.ersterSatzHeraus.value}
                        placeholder="0"
                        autoComplete="off"
                        style={{padding: '4px 9px', width: this.state.ersterSatzHeraus.inputWidth+"px"}}
                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                        className={this.state.ersterSatzHeraus.validateStatus === 'error' ? 'error' : ''}
                        onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>
                  </td>

                  <td>
                    <Input name="zweiterSatzFord"
                           value={this.state.zweiterSatzFord.value}
                           placeholder="0"
                           autoComplete="off"
                           style={{padding: '4px 9px', width: this.state.zweiterSatzFord.inputWidth+"px"}}
                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                           className={this.state.zweiterSatzFord.validateStatus === 'error' ? 'error' : ''}
                           onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>
                  </td>
                  <td>:</td>
                  <td style={tableCellSpacer}>
                    <Input
                        name="zweiterSatzHeraus"
                        value={this.state.zweiterSatzHeraus.value}
                        placeholder="0"
                        autoComplete="off"
                        style={{padding: '4px 9px', width: this.state.zweiterSatzHeraus.inputWidth+"px"}}
                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                        className={this.state.zweiterSatzHeraus.validateStatus === 'error' ? 'error' : ''}
                        onChange={(event) => this.handleInputChange(event, this.validateSatz)}
                    />
                  </td>

                  <td>
                    <Input
                        name="dritterSatzFord"
                        value={this.state.dritterSatzFord.value}
                        autoComplete="off"
                        style={{padding: '4px 9px', width: this.state.dritterSatzFord.inputWidth+"px"}}
                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeMTB)}
                        className={this.state.dritterSatzFord.validateStatus === 'error' ? 'error' : ''}
                        onChange={(event) => this.handleInputChange(event, this.validateMatchTieBreak)}/>
                  </td>
                  <td>:</td>
                  <td style={tableCellSpacer}>
                    <Input name="dritterSatzHeraus"
                           value={this.state.dritterSatzHeraus.value}
                           autoComplete="off"
                           style={{padding: '4px 9px', width: this.state.dritterSatzHeraus.inputWidth+"px"}}
                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeMTB)}
                           className={this.state.dritterSatzHeraus.validateStatus === 'error' ? 'error' : ''}
                           onChange={(event) => this.handleInputChange(event, this.validateMatchTieBreak)}/>
                  </td>

                </tr>
                </tbody>
              </table>
              <div style={cnt}>
                <div style={buttonWrapper}>
                  <Button type="submit">Ergebnis eintragen</Button>
                </div>
              </div>
              {!!this.state.aufgabe &&
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <FormItem name="aufgabeFordernder"
                                    valuePropName="checked"
                                    initialValue={false}
                                    onChange={(event) => this.handleCheckboxChange(event)}>
                            <Checkbox name="aufgabeFordernder">Aufg. {displayNameHeim}</Checkbox>
                          </FormItem>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <FormItem name="aufgabeHerausgeforderter"
                                    valuePropName="checked"
                                    initialValue={false}
                                    onChange={(event) => this.handleCheckboxChange(event)}>
                            <Checkbox name="aufgabeHerausgeforderter">Aufg. {displayNameGast}</Checkbox>
                          </FormItem>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              }
              <p style={{color: 'red'}}>
                {validierungsmeldung}
                <br/>
              </p>

              {!!this.state.successMsg &&
                  <SuccessMessage message={this.state.successMsg}/>
              }

              {!!this.state.errorMsg &&
                  <ErrorMessage message={this.state.errorMsg}/>
              }
            </Form>
          </div>

        </React.Fragment>
    )
  }

  changeInputSizeSatz(input){
    return this.changeInputSize(input, 30);
  }

  changeInputSizeMTB(input){
    return this.changeInputSize(input, 35);
  }

  //Bei Texteingaben (mit mehr als 1 Zeichen, waechst dann das Textfeld mit)
  changeInputSize(input, startWidth){
    const value = input;
    const length = value.length;

    var width = startWidth;

    if(length > 1){
      width = 30 + ((value.length - 1) * 9);
    }

    return {
      inputWidth: width,
    }
  }

  validateSatz = (satz) => {
    // isNaN(x) geht nicht, weil der nimmt auch Kommazahlen. Also 1.5 waere valide
    //Number.isInteger(x) erwartet wirklich eine Zahl und kein String als Parameter,
    //dager muss satz erstmal gecasted werden zu einer Zahl: Number(satz)
    var isANumber = Number.isInteger(Number(satz));
    if(!isANumber){
      return {
        validateStatus: 'error',
        errorMsg: satz + ` ist keine korrekte Eingabe. Bitte gib eine valide Zahl zwischen 0 und 7 an.`
      }
    }

    if(this.state.aufgabeFordernder.value || this.state.aufgabeHerausgeforderter.value){
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }

    if(!satz) {
      return {
        validateStatus: 'error',
        errorMsg: `Bitte gib ein Ergebnis an. Es müssen zwei vollständige Sätze gespielt werden.`
      }
    } else if (satz > 7) {
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Ein Satz kann nur bis 7 gehen.`
      }
    }
    else {
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }
  }

  validateMatchTieBreak = (satz) => {
    var isANumber = Number.isInteger(Number(satz));
    if(!isANumber){
      return {
        validateStatus: 'error',
        errorMsg: satz + ` ist keine korrekte Eingabe. Bitte gib eine valide Zahl an.`
      }
    }
    return {
      validateStatus: 'success',
      errorMsg: null
    }
  }

  validateErgebnis() {
    this.setState({
      errorMsg: null,
      successMsg: null
    });

    if(this.state.aufgabeFordernder.value || this.state.aufgabeHerausgeforderter.value){
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }

    var ersterSatzFord = this.state.ersterSatzFord.value;
    var ersterSatzHeraus = this.state.ersterSatzHeraus.value;
    var zweiterSatzFord = this.state.zweiterSatzFord.value;
    var zweiterSatzHeraus = this.state.zweiterSatzHeraus.value;
    var dritterSatzFord = this.state.dritterSatzFord.value;
    var dritterSatzHeraus = this.state.dritterSatzHeraus.value;

    let validationResult = this.validateWholeSatz(ersterSatzFord, ersterSatzHeraus);
    if(validationResult.validateStatus === 'error'){
      return validationResult;
    }

    validationResult = this.validateWholeSatz(zweiterSatzFord, zweiterSatzHeraus);
    if(validationResult.validateStatus === 'error'){
      return validationResult;
    }


    //schauen, ob das Formular ueberhaupt fehlerfrei ausgefuellt wurde:
    if(this.isErsteBeidenSaezteInvalid()){
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide und konnte daher nicht gespeichert werden.`
      }
    }

    if(this.isSatzgleichheit(ersterSatzFord, ersterSatzHeraus, zweiterSatzFord, zweiterSatzHeraus)){
      //schauen, ob das Formular ueberhaupt fehlerfrei ausgefuellt wurde:
      if(this.isDritterSatzInvalid()){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide und konnte daher nicht gespeichert werden.`
        }
      }
      return this.validateWholeMatchTieBreak(dritterSatzFord, dritterSatzHeraus);
    }else{
      //Ein Match-Tie-Break darf nur eingetragen werden, bei Satzgleichstand
      if(dritterSatzFord || dritterSatzHeraus) {
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break wird nur bei Satzgleichstand gespielt.`
        }
      }
    }


    return {
      validateStatus: 'success',
      errorMsg: null
    }
  }

  isSatzgleichheit(ersterSatzFord, ersterSatzHeraus, zweiterSatzFord, zweiterSatzHeraus){
    if(ersterSatzFord < ersterSatzHeraus && zweiterSatzFord > zweiterSatzHeraus){
      return true;
    } else if(ersterSatzHeraus < ersterSatzFord && zweiterSatzHeraus > zweiterSatzFord){
      return true;
    }
    return false;
  }

  validateWholeSatz(heim, gast){
    if(heim === gast){
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Ein Satz kann nicht unentschieden ausgehen.`
      }
    }
    if(heim > gast){
      return this.validateWholeSatzOrdered(heim, gast, heim + ':' + gast);
    }

    return this.validateWholeSatzOrdered(gast, heim, heim + ':' + gast);
  }

  //dass immer zuerst der gewoonene uebergeben wird
  //6:X
  validateWholeSatzOrdered(gewonnen, verloren, satzString){

    if(gewonnen < 6){
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Ein Satz muss mindestens bis 6 gehen.`
      }
    }

    if(gewonnen > 7){
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Ein Satz darf nur bis 6 oder 7 gehen.`
      }
    }

    //wichtig: nur ==, da gewonnen und verloren Strings sind, und '6' === 6 nicht stimmt. Daher nur ==
    if(gewonnen === '6'){
      if(gewonnen - verloren < 2){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Es muss mit mindestens zwei Spielen Vorsprung gewonnen werden: ` + satzString
        }
      }
    } else if(gewonnen === '7'){
      if(verloren !== '5' && verloren !== '6'){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Ein Satz kann nur bis 7 gehen bei 5:7 oder 6:7`
        }
      }
    }


    return {
      validateStatus: 'success',
      errorMsg: null
    }
  }

  validateWholeMatchTieBreak(heim, gast){
    let diff = 0;
    var dritterSatzFord = parseInt(heim);
    var dritterSatzHeraus = parseInt(gast);

    if(!heim) {
      if(!gast){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Bei Satzgleichstand muss ein Match-Tie-Break oder ein 3. Satz gespielt werden.`
        }
      }
    }

    //wenn nur gast oder nur heim ausgefuellt wurde
    //kann ich so einfach mit  || machen, da oben ja schon abgefragt wird, ob beide NULL sind und falls ja, rausgesprungen wird.
    if(!heim || !gast) {
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Der Match-Tie-Break oder 3. Satz ist nicht vollständig ausgefüllt.`
      }
    }

    if(dritterSatzFord > dritterSatzHeraus){
      diff = dritterSatzFord - dritterSatzHeraus;
      if(dritterSatzFord < 6 || (dritterSatzFord > 7 && dritterSatzFord < 10)){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break geht mindestens bis 10 und ein dritter Satz bis 6.`
        }
      }
    } else if(dritterSatzHeraus > dritterSatzFord){
      diff = dritterSatzHeraus - dritterSatzFord;
      if(dritterSatzHeraus < 6 || (dritterSatzHeraus > 7 && dritterSatzHeraus < 10)){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break geht mindestens bis 10 und ein dritter Satz bis 6.`
        }
      }
    }else{
      return {
        validateStatus: 'error',
        errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break kann nicht unentschieden ausgehen.`
      }
    }

    //Ein Satz kann 7:6 ausgehen.
    //Die Differenz > 2 Regel, gilt eben nur, wenn der Satz nicht im Tie-Break ausgegangen ist.
    if(!((dritterSatzFord === '6' && dritterSatzHeraus === '7') || (dritterSatzFord === '7' && dritterSatzHeraus === '6'))){
      if(diff < 2){
        return {
          validateStatus: 'error',
          errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break muss mit 2 Punkten Vorsprung und ein 3. Satz mit 2 Spielen Vorsprung gewonnen werden.`
        }
      }
    }


    return {
      validateStatus: 'success',
      errorMsg: null
    }
  }
}
// end::ForderungsErgebnis[]

// Specifies the default values for props:
//so muss in der 'zentralen' Forderungs-Komponente das Property isForderung nicht gesetzt werden
ForderungsErgebnis.defaultProps = {
  isForderung: true
};


export default ForderungsErgebnis;
