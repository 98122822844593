// tag::ForderungsErgebnis[]
import './GespieltFrom.css';
import {Form, Input} from "antd";
import {Button} from "react-bootstrap";

import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import moment from "moment";

// tag::vars[]
const React = require('react');
// end::vars[]

class GespieltForm extends React.Component{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            heim: {},
            gast: {},
            
            ersterSatzHeim: {
                value: '',
                inputWidth: 30,
            },
            ersterSatzGast: {
                value: '',
                inputWidth: 30,
            },

            zweiterSatzHeim: {
                value: '',
                inputWidth: 30,
            },
            zweiterSatzGast: {
                value: '',
                inputWidth: 30,
            },

            dritterSatzHeim: {
                value: '',
                inputWidth: 35,
            },
            dritterSatzGast: {
                value: '',
                inputWidth: 35,
            },
            errorMsg: '',
        }

        this.loadData = this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
        this.changeInputSize = this.changeInputSize.bind(this);
        this.changeInputSizeSatz = this.changeInputSizeSatz.bind(this);
        this.changeInputSizeMTB = this.changeInputSizeMTB.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentDidUpdate(nextProps, prevState) {
        //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
        if(this.props.existentGespielt !== nextProps.existentGespielt) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        if(this.props.existentGespielt && this.props.existentGespielt.id > 0){
            this.setDefaultFormValuesForEditView(this.props.existentGespielt);
        }
    }
    
    setDefaultFormValuesForEditView(gespielt){
        
        if (this._isMounted) {
            this.setState({
                heim: this.props.existentGespielt.heim,
                gast: this.props.existentGespielt.gast,
                
                ersterSatzHeim: {
                    value: this.props.existentGespielt.ersterSatzHeim,
                    inputWidth: 30,
                    ...this.validateSatz(this.props.existentGespielt.ersterSatzHeim)
                },
                ersterSatzGast: {
                    value: this.props.existentGespielt.ersterSatzGast,
                    inputWidth: 30,
                    ...this.validateSatz(this.props.existentGespielt.ersterSatzGast)
                },

                zweiterSatzHeim: {
                    value: this.props.existentGespielt.zweiterSatzHeim,
                    inputWidth: 30,
                    ...this.validateSatz(this.props.existentGespielt.zweiterSatzHeim)
                },
                zweiterSatzGast: {
                    value: this.props.existentGespielt.zweiterSatzGast,
                    inputWidth: 30,
                    ...this.validateSatz(this.props.existentGespielt.zweiterSatzGast)
                },

                dritterSatzHeim: {
                    value: this.props.existentGespielt.dritterSatzHeim,
                    inputWidth: 35,
                    ...this.validateMatchTieBreak(this.props.existentGespielt.dritterSatzHeim)
                },
                dritterSatzGast: {
                    value: this.props.existentGespielt.dritterSatzGast,
                    inputWidth: 35,
                    ...this.validateMatchTieBreak(this.props.existentGespielt.dritterSatzGast)
                },
            });
        }

    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleKeyUp(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        //hier kommt das Ergebnis der Funktion rein
        let ergebnis = validationFun(inputValue);

        //WIRKLICH NUR inputWidth im State setzen und die anderen Felder des states nicht veraendern.
        //hier wird der state in die Variable stateObj uebernommen
        //danach wird in stateObj der neue Wert von inputWidth aus dem Ergebnis gesetzt
        //WICHTIG: So wird im state wirklich nur inputWidth und value geaendert und errorMesg und validateStatus
        // und auch alle anderen Felder des States fuer dieses Eingabefeld bleiben gleich. 
        //Sonst wuerden diese hier immer zurueck auf undefined gesetzt werden.
        let stateObj = this.state[inputName];
        stateObj.inputWidth = ergebnis.inputWidth;
        stateObj.value = inputValue;

        this.setState({
            [inputName] : stateObj
        });
    }
    
    isFormInvalid() {
        return !(this.isErsteBeidenSaezteInvalid() && this.isDritterSatzInvalid());
    }


    isErsteBeidenSaezteInvalid() {
        return !(this.state.ersterSatzHeim.validateStatus === 'success' &&
            this.state.ersterSatzGast.validateStatus === 'success' &&
            this.state.zweiterSatzHeim.validateStatus === 'success' &&
            this.state.zweiterSatzGast.validateStatus === 'success'
        );
    }
    isDritterSatzInvalid() {
        return (this.state.dritterSatzHeim.validateStatus === 'error' ||
            this.state.dritterSatzGast.validateStatus === 'error'
        );
    }

    handleSubmit(){
        //alte Fehlermeldungen zurücksetzen
        this.setState({
            errorMsg: ''
        });

        var validationResult = this.validateErgebnis();

        if(validationResult.validateStatus === 'error'){
            this.setState({
                errorMsg: validationResult.errorMsg
            });
            return;
        }

        var gespieltRequest = {
            id: this.props.existentGespielt.id,
            spieltermin: this.props.existentGespielt.spieltermin,
            fordernderNutzer: this.props.existentGespielt.heim,
            herausgeforderterNutzer: this.props.existentGespielt.gast,
            ersterSatzFord: this.state.ersterSatzHeim.value,
            ersterSatzHeraus: this.state.ersterSatzGast.value,
            zweiterSatzFord: this.state.zweiterSatzHeim.value,
            zweiterSatzHeraus: this.state.zweiterSatzGast.value,
            dritterSatzFord: this.state.dritterSatzHeim.value,
            dritterSatzHeraus: this.state.dritterSatzGast.value
        };

        if(this.props.isDoppel){
            gespieltRequest = {
                id: this.props.existentGespielt.id,
                spieltermin: this.props.existentGespielt.spieltermin,
                fordernderNutzerM: this.props.existentGespielt.heim.nutzer1,
                fordernderNutzerW: this.props.existentGespielt.heim.nutzer2,

                herausgeforderterNutzerM: this.props.existentGespielt.gast.nutzer1,
                herausgeforderterNutzerW: this.props.existentGespielt.gast.nutzer2,

                ersterSatzFord: this.state.ersterSatzHeim.value,
                ersterSatzHeraus: this.state.ersterSatzGast.value,
                zweiterSatzFord: this.state.zweiterSatzHeim.value,
                zweiterSatzHeraus: this.state.zweiterSatzGast.value,
                dritterSatzFord: this.state.dritterSatzHeim.value,
                dritterSatzHeraus: this.state.dritterSatzGast.value
            };
        }

        this.props.onHandleSubmit(gespieltRequest);
    }

    render() {
        const { ersterSatzHeim, ersterSatzGast, zweiterSatzHeim, zweiterSatzGast, dritterSatzHeim, dritterSatzGast } = this.state;
     
        const spieltermin = this.props.existentGespielt.spieltermin;
        
        const tableCellSpacer = {
            'paddingRight': '15px',
        };

        const buttonWrapper = {
            'display': 'table-cell',
            'verticalAlign': 'bottom',
        };

        const cnt = {
            'display': 'table',
        };

        const gastLabel = this.props.existentGespielt.gast.getLabel();
        const heimLabel = this.props.existentGespielt.heim.getLabel();

        var begegnungsNamen =  (
            <React.Fragment>
                <span style={{whiteSpace: 'nowrap'}}>{gastLabel}</span> - <span style={{whiteSpace: 'nowrap'}}>{heimLabel}</span>
            </React.Fragment>
        );
        

        let validierungsmeldung = '';

        if(ersterSatzHeim.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + ersterSatzHeim.errorMsg;
        }

        if(ersterSatzGast.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + ersterSatzGast.errorMsg;
        }

        if(zweiterSatzHeim.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + zweiterSatzHeim.errorMsg;
        }

        if(zweiterSatzGast.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + zweiterSatzGast.errorMsg;
        }

        if(dritterSatzHeim.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + dritterSatzHeim.errorMsg;
        }

        if(dritterSatzGast.validateStatus === 'error'){
            validierungsmeldung = validierungsmeldung + dritterSatzGast.errorMsg;
        }


        return (
            <React.Fragment>

                <div>
                    <h2>Ergebnis bearbeiten</h2>

                    <Form layout="inline"
                          onFinish={this.handleSubmit}
                          className="spieltermin-form">
                        <table>
                            <tbody>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan="3">1. Satz</th>
                                <th colSpan="3">2. Satz</th>
                                <th colSpan="3">3. Satz/MTB</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td style={{paddingRight:'1em'}}>
                                    {moment(spieltermin).format('DD.MM.YY')}
                                </td>
                                <td style={{ 'paddingRight': '40px' }}><b>{begegnungsNamen}</b></td>
                                <td>
                                    <Input name="ersterSatzGast"
                                           value={this.state.ersterSatzGast.value}
                                           placeholder="0"
                                           autoComplete="off"
                                           style={{padding: '4px 9px', width: this.state.ersterSatzGast.inputWidth+"px"}}
                                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                                           className={this.state.ersterSatzGast.validateStatus === 'error' ? 'error' : ''}
                                           onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>

                                </td>
                                <td> : </td>
                                <td  style={tableCellSpacer}>
                                    <Input
                                        name="ersterSatzHeim"
                                        value={this.state.ersterSatzHeim.value}
                                        placeholder="0"
                                        autoComplete="off"
                                        style={{padding: '4px 9px', width: this.state.ersterSatzHeim.inputWidth+"px"}}
                                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                                        className={this.state.ersterSatzHeim.validateStatus === 'error' ? 'error' : ''}
                                        onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>
                                </td>

                                <td>
                                    <Input name="zweiterSatzGast"
                                           value={this.state.zweiterSatzGast.value}
                                           placeholder="0"
                                           autoComplete="off"
                                           style={{padding: '4px 9px', width: this.state.zweiterSatzGast.inputWidth+"px"}}
                                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                                           className={this.state.zweiterSatzGast.validateStatus === 'error' ? 'error' : ''}
                                           onChange={(event) => this.handleInputChange(event, this.validateSatz)}/>
                                </td>
                                <td>:</td>
                                <td style={tableCellSpacer}>
                                    <Input
                                        name="zweiterSatzHeim"
                                        value={this.state.zweiterSatzHeim.value}
                                        placeholder="0"
                                        autoComplete="off"
                                        style={{padding: '4px 9px', width: this.state.zweiterSatzHeim.inputWidth+"px"}}
                                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeSatz)}
                                        className={this.state.zweiterSatzHeim.validateStatus === 'error' ? 'error' : ''}
                                        onChange={(event) => this.handleInputChange(event, this.validateSatz)}
                                    />
                                </td>

                                <td>
                                    <Input
                                        name="dritterSatzGast"
                                        value={this.state.dritterSatzGast.value}
                                        autoComplete="off"
                                        style={{padding: '4px 9px', width: this.state.dritterSatzGast.inputWidth+"px"}}
                                        onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeMTB)}
                                        className={this.state.dritterSatzGast.validateStatus === 'error' ? 'error' : ''}
                                        onChange={(event) => this.handleInputChange(event, this.validateMatchTieBreak)}/>
                                </td>
                                <td>:</td>
                                <td style={tableCellSpacer}>
                                    <Input name="dritterSatzHeim"
                                           value={this.state.dritterSatzHeim.value}
                                           autoComplete="off"
                                           style={{padding: '4px 9px', width: this.state.dritterSatzHeim.inputWidth+"px"}}
                                           onKeyUp={(event) => this.handleKeyUp(event, this.changeInputSizeMTB)}
                                           className={this.state.dritterSatzHeim.validateStatus === 'error' ? 'error' : ''}
                                           onChange={(event) => this.handleInputChange(event, this.validateMatchTieBreak)}/>
                                </td>

                            </tr>
                            </tbody>
                        </table>
                        <div style={cnt}>
                            <div style={buttonWrapper}>
                                <Button type="submit">Ergebnis eintragen</Button>
                            </div>
                        </div>
                        <p style={{color: 'red'}}>
                            {validierungsmeldung}
                            <br/>
                        </p>

                        {!!this.state.successMsg &&
                        <SuccessMessage message={this.state.successMsg}/>
                        }

                        {!!this.state.errorMsg &&
                        <ErrorMessage message={this.state.errorMsg}/>
                        }
                    </Form>

                    <Button type="submit"
                            style={{float: 'left'}}
                            size="large"
                            className="myLargeBtn"
                            onClick={() => this.props.onHandleBack()}>Zurück</Button>
                </div>

            </React.Fragment>
        )
    }

    changeInputSizeSatz(input){
        return this.changeInputSize(input, 30);
    }

    changeInputSizeMTB(input){
        return this.changeInputSize(input, 35);
    }

    //Bei Texteingaben (mit mehr als 1 Zeichen, waechst dann das Textfeld mit)
    changeInputSize(input, startWidth){
        const value = input;
        const length = value.length;

        var width = startWidth;

        if(length > 1){
            width = 30 + ((value.length - 1) * 9);
        }

        return {
            inputWidth: width,
        }
    }

    validateSatz = (satz) => {
        // isNaN(x) geht nicht, weil der nimmt auch Kommazahlen. Also 1.5 waere valide
        //Number.isInteger(x) erwartet wirklich eine Zahl und kein String als Parameter,
        //dager muss satz erstmal gecasted werden zu einer Zahl: Number(satz)
        var isANumber = Number.isInteger(Number(satz));
        if(!isANumber){
            return {
                validateStatus: 'error',
                errorMsg: satz + ` ist keine korrekte Eingabe. Bitte gib eine valide Zahl zwischen 0 und 7 an.`
            }
        }

        if(satz !== 0 && !satz) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib ein Ergebnis an. Es müssen zwei vollständige Sätze gespielt werden.`
            }
        } else if (satz > 7) {
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Ein Satz kann nur bis 7 gehen.`
            }
        }
        else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateMatchTieBreak = (satz) => {
        var isANumber = Number.isInteger(Number(satz));
        if(!isANumber){
            return {
                validateStatus: 'error',
                errorMsg: satz + ` ist keine korrekte Eingabe. Bitte gib eine valide Zahl an.`
            }
        }
        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateErgebnis() {
        this.setState({
            errorMsg: null,
            successMsg: null
        });

        var ersterSatzHeim = this.state.ersterSatzHeim.value;
        var ersterSatzGast = this.state.ersterSatzGast.value;
        var zweiterSatzHeim = this.state.zweiterSatzHeim.value;
        var zweiterSatzGast = this.state.zweiterSatzGast.value;
        var dritterSatzHeim = this.state.dritterSatzHeim.value;
        var dritterSatzGast = this.state.dritterSatzGast.value;

        let validationResult = this.validateWholeSatz(ersterSatzHeim, ersterSatzGast);
        if(validationResult.validateStatus === 'error'){
            return validationResult;
        }

        validationResult = this.validateWholeSatz(zweiterSatzHeim, zweiterSatzGast);
        if(validationResult.validateStatus === 'error'){
            return validationResult;
        }
        
        //schauen, ob das Formular ueberhaupt fehlerfrei ausgefuellt wurde:
        if(this.isErsteBeidenSaezteInvalid()){
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide und konnte daher nicht gespeichert werden.`
            }
        }

        if(this.isSatzgleichheit(ersterSatzHeim, ersterSatzGast, zweiterSatzHeim, zweiterSatzGast)){
            //schauen, ob das Formular ueberhaupt fehlerfrei ausgefuellt wurde:
            if(this.isDritterSatzInvalid()){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide und konnte daher nicht gespeichert werden.`
                }
            }
            return this.validateWholeMatchTieBreak(dritterSatzHeim, dritterSatzGast);
        }else{
            //Ein Match-Tie-Break darf nur eingetragen werden, bei Satzgleichstand
            if(dritterSatzHeim || dritterSatzGast) {
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break wird nur bei Satzgleichstand gespielt.`
                }
            }
        }


        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    isSatzgleichheit(ersterSatzHeim, ersterSatzGast, zweiterSatzHeim, zweiterSatzGast){
        if(ersterSatzHeim < ersterSatzGast && zweiterSatzHeim > zweiterSatzGast){
            return true;
        } else if(ersterSatzGast < ersterSatzHeim && zweiterSatzGast > zweiterSatzHeim){
            return true;
        }
        return false;
    }

    validateWholeSatz(heim, gast){
        if(heim === gast){
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Ein Satz kann nicht unentschieden ausgehen.`
            }
        }
        if(heim > gast){
            return this.validateWholeSatzOrdered(heim, gast);
        }

        return this.validateWholeSatzOrdered(gast, heim);
    }

    //dass immer zuerst der gewoonene uebergeben wird
    //6:X
    validateWholeSatzOrdered(gewonnen, verloren){

        if(gewonnen < 6){
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Ein Satz muss mindestens bis 6 gehen.`
            }
        }

        if(gewonnen > 7){
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Ein Satz darf nur bis 6 oder 7 gehen.`
            }
        }

        if(gewonnen === 6){
            if(gewonnen - verloren < 2){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Es muss mit mindestens zwei Spielen vorsprung gewonnen werden: ` + gewonnen + ':' + (gewonnen + 2)
                }
            }
        } else if(gewonnen === 7){
            if(verloren !== 6){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide.`
                }
            }
        }


        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateWholeMatchTieBreak(heim, gast){
        let diff = 0;
        var dritterSatzHeim = parseInt(heim);
        var dritterSatzGast = parseInt(gast);

        if(!heim) {
            if(!gast){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Bei Satzgleichstand muss ein Match-Tie-Break oder ein 3. Satz gespielt werden.`
                }
            }
        }

        //wenn nur gast oder nur heim ausgefuellt wurde
        //kann ich so einfach mit  || machen, da oben ja schon abgefragt wird, ob beide NULL sind und falls ja, rausgesprungen wird.
        if(!heim || !gast) {
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Der Match-Tie-Break oder 3. Satz ist nicht vollständig ausgefüllt.`
            }
        }

        if(dritterSatzHeim > dritterSatzGast){
            diff = dritterSatzHeim - dritterSatzGast;
            if(dritterSatzHeim < 6 || (dritterSatzHeim > 7 && dritterSatzHeim < 10)){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break geht mindestens bis 10 und ein dritter Satz bis 6.`
                }
            }
        } else if(dritterSatzGast > dritterSatzHeim){
            diff = dritterSatzGast - dritterSatzHeim;
            if(dritterSatzGast < 6 || (dritterSatzGast > 7 && dritterSatzGast < 10)){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break geht mindestens bis 10 und ein dritter Satz bis 6.`
                }
            }
        }else{
            return {
                validateStatus: 'error',
                errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break kann nicht unentschieden ausgehen.`
            }
        }

        //Ein Satz kann 7:6 ausgehen.
        //Die Differenz > 2 Regel, gilt eben nur, wenn der Satz nicht im Tie-Break ausgegangen ist.
        if(!((dritterSatzHeim === 6 && dritterSatzGast === 7) || (dritterSatzHeim === 7 && dritterSatzGast === 6))){
            if(diff < 2){
                return {
                    validateStatus: 'error',
                    errorMsg: `Das Ergebnis ist nicht valide. Ein Match-Tie-Break muss mit 2 Punkten Vorsprung gewonnen werden.`
                }
            }
        }


        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }
}
// end::ForderungsErgebnis[]


export default GespieltForm;
