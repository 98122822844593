//Korz, Annika
export function getDisplayNameEinzel(nutzer){
    return nutzer.nachname + ', ' + nutzer.vorname;
}

//A. Korz
export function getDisplayNameEinzelTruncated(nutzer) {
    let vorname = nutzer.vorname;

    return vorname.substring(0, 1) + ', ' + nutzer.nachname;
}

//Annika Korz
export function getLabelForHeadingEinzel(nutzer){
    return nutzer.vorname + ' ' + nutzer.nachname;
}


//-------------Doppel--------------
//Korz/Muster

export function getDisplayNameMixed(spielpaarung){
    return getDisplayNameMixedDoppel(spielpaarung.nutzerM, spielpaarung.nutzerW);
}

//Annika Korz und Max Muster
export function getLabelForHeadingMixed(spielpaarung){
    return getLabelForHeadingMixedDoppel(spielpaarung.nutzerM, spielpaarung.nutzerW);
}

export function getDisplayNameDoppel(spielpaarung){
    return getDisplayNameMixedDoppel(spielpaarung.nutzer1, spielpaarung.nutzer2);
}

//Annika Korz und Max Muster
export function getLabelForHeadingDoppel(spielpaarung){
    return getLabelForHeadingMixedDoppel(spielpaarung.nutzer1, spielpaarung.nutzer2);
}

export function getDisplayNameMixedDoppel(nutzer1, nutzer2){
    return nutzer1.nachname + '/' +  nutzer2.nachname;
}

//Annika Korz und Max Muster
export function getLabelForHeadingMixedDoppel(nutzer1, nutzer2){
    return nutzer1.vorname + ' und ' +  nutzer2.vorname;
}