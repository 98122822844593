import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const forderungMixedService = {
  getAllForderungenForNutzerSorted,
  getAllForTheseTwoNutzerSortedExceptTheirs,
  getFordererAndHerausgefordertertId,
  getAllNutzerSorted,
  getAllNutzerSortedPaged,
  getAllZukuenftigenSorted,
  getAllSorted,
  getForderungById,
  saveForderung,
  deleteForderungById,
  existsAndereForderungZurSelbenZeit

};

//Liefert alle Forderungen, an denen der RanglistenEintragDto beteiligt ist. Auch die aus der Vergangenheit.
export function getAllForderungenForNutzerSorted(nutzerId)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/getByNutzerId/"  + nutzerId,
    method: 'GET'
  });
}

// liefert auch die aus der Vergangeheit
// liefert alle Forderungen der beiden RanglistenEintragDto zuruck (egal, ob sie als Fordernder oder Herausfordernder dabei
// gelten), auch die, aus der Vergangenheit.
// Nur ihr eigene ForderungDto nicht (also die, wo fordernder fordernderId und herausfordernder herausgefordertertId ist).
export function getAllForTheseTwoNutzerSortedExceptTheirs(fordernderId, herausgefordertertId)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/getAllForTheseTwoNutzerExecptTheirOwn?fordernderId="  + fordernderId + "&herausgefordertertId=" + herausgefordertertId,
    method: 'GET'
  });
}

export function getFordererAndHerausgefordertertId(fordernderId, herausgefordertertId)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/getFordererAndHerausgeford/"  + fordernderId + "/" + herausgefordertertId,
    method: 'GET'
  });
}

export function getAllNutzerSorted()
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/list",
    method: 'GET'
  });
}

export function getAllNutzerSortedPaged(page, size)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/pagedlist?page=" + page + "&size=" + size,
    method: 'GET'
  });
}

export function getAllZukuenftigenSorted()
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/getAllZukuenftigenSorted",
    method: 'GET'
  });
}

export function getAllSorted()
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/list",
    method: 'GET'
  });
}

//Liefert alle Forderungen, an denen der RanglistenEintragDto beteiligt ist. Auch die aus der Vergangenheit.
export function getForderungById(forderungsId)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/getById/"  + forderungsId,
    method: 'GET'
  });
}

export function saveForderung(forderungRequest) {
  return request({
    url: API_BASE_URL + "/forderung-mixed/save",
    method: 'POST',
    body: JSON.stringify(forderungRequest)
  });
}

//loescht eine ForderungDto
export function deleteForderungById(forderungId)
{
  return request({
    url: API_BASE_URL + "/forderung-mixed/delete/"  + forderungId,
    method: 'DELETE'
  });
}

export function existsAndereForderungZurSelbenZeit(forderungRequest) {
  return request({
    url: API_BASE_URL + "/forderung-mixed/existsAndereForderungZurSelbenZeit",
    method: 'POST',
    body: JSON.stringify(forderungRequest)
  });
}

