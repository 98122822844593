export default class RanglistenEintragDto {
    constructor(nutzerJson) {
        if(nutzerJson !== null && nutzerJson !== undefined) {
            this.id = nutzerJson.id;
            this.aktiv = nutzerJson.aktiv;
            this.position = nutzerJson.position;
        }
    }
    //
    // isDoppel(){
    //     return false;
    // }
    //
    // containsNutzer(nutzer){
    //     return false;
    // }
    // containsNutzer(nutzerId){
    //     return false;
    // }
    //
    // getLabel(){
    //     return '';
    // }
    //
    // getLabelTruncated(){
    //     return '';
    // }
    //
    // getLabelForHeading(){
    //     return '';
    // }
    //
    // getLabelTruncatedForHeading(){
    //     return '';
    // }
    //
    // getLabelElement(isResponsiveView) {
    //     return '';
    // }
    //
    // getKontaktinfoElement(){
    //     return '';
    // }
    // getKey(){
    //     return '';
    // }
}