import React from 'react';
import {checkEmailAvailability, getCurrentNutzer} from "../../_services/authService";
import {setErrorState} from '../../util/ErrorUtil';


import {Button} from 'react-bootstrap';
import {Form, Input, notification} from 'antd';

import {EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, USERNAME_MAX_LENGTH} from "../../constants";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import ProfilEinstellungen from "./profilEinstellungen";
import {nutzerService} from "../../_services/nutzerService";

const FormItem = Form.Item;


class Profil extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            
            //die Form-Daten
            vorname: {
                value: '',
                validateStatus: 'success'
            },
            nachname: {
                value: '',
                validateStatus: 'success'
            },
            telefonnummer: {
                value: '',
                validateStatus: 'success'
            },
            email: {
                value: '',
                validateStatus: 'success'
            }
        };
        
        this.loadUsers = this.loadUsers.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }


    loadUsers() {

        //das hier ist die DB Abfrage und liefert das komplette Nutzer Objekt zurueck, wo alle Felder gefuellt sind
        getCurrentNutzer()
        .then(response => {
            if (this._isMounted) {
                this.setState({
                    user: response,
                    vorname: {
                        value: response.vorname,
                        validateStatus: 'success'
                    },
                    nachname: {
                        value: response.nachname,
                        validateStatus: 'success'
                    },
                    telefonnummer: {
                        value: response.telefon,
                        validateStatus: 'success'
                    },
                    email: {
                        value: response.email,
                        validateStatus: 'success'
                    },
                });

            }
        })
        .catch(error => {
            setErrorState(error, this);
        });
    }

    
    componentDidMount() {
        this._isMounted = true;
        this.loadUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadUsers();
        }
    }

    handleSubmit(event) {
        //event.preventDefault();

        const saveNutzerProfilInfo = {
            id: this.state.user.id,
            email: this.state.email.value,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            telefon: this.state.telefonnummer.value
        };
        
        nutzerService.saveNutzerProfilInfo(saveNutzerProfilInfo)
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Deine Nutzer-Daten wurden erfolgreich gespeichert.",
            });

            this.setState({
                successMsg: "Deine Nutzer-Daten wurden erfolgreich gespeichert."
            });

        }).catch(error => {
            setErrorState(error, this);
        });
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    
    isFormInvalid() {

        return !(this.state.vorname.validateStatus === 'success' &&
            this.state.nachname.validateStatus === 'success' &&
            this.state.telefonnummer.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success'
        );
    }
    
    render() {
        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };
        
        const { user } = this.state;

        return (
            <React.Fragment>
            {!!user && !!user.id > 0 &&
                <div>
                <div className="form-container">
    
                    <h1>Hallo {user.vorname}</h1>
                    <p>Hier kannst du deine Daten verwalten.</p>
    
                    <Form {...layout} onFinish={this.handleSubmit} className="signup-form">
                        <FormItem label="Vorname"
                                  hasFeedback
                                  validateStatus={this.state.vorname.validateStatus}
                                  help={this.state.vorname.errorMsg}>
                            <Input
                                size="large"
                                name="vorname"
                                autoComplete="on"
                                placeholder="Dein Vorname"
                                value={this.state.vorname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateVorname)} />
                        </FormItem>
                        <FormItem label="Nachname"
                                  hasFeedback
                                  validateStatus={this.state.nachname.validateStatus}
                                  help={this.state.nachname.errorMsg}>
                            <Input
                                size="large"
                                name="nachname"
                                autoComplete="on"
                                placeholder="Dein Nachname"
                                value={this.state.nachname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateNachname)}/>
                        </FormItem>
                        <FormItem label="Telefonnummer"
                                  hasFeedback
                                  validateStatus={this.state.telefonnummer.validateStatus}
                                  help={this.state.telefonnummer.errorMsg}>
                            <Input
                                size="large"
                                name="telefonnummer"
                                autoComplete="on"
                                placeholder="Deine Telefonnummer"
                                value={this.state.telefonnummer.value}
                                onChange={(event) => this.handleInputChange(event, this.validateTelefonnummer)}/>
                        </FormItem>
                        <FormItem
                            label="Email"
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input
                                size="large"
                                name="email"
                                type="email"
                                autoComplete="on"
                                placeholder="Deine Email-Adresse"
                                value={this.state.email.value}
                                onBlur={this.validateEmailAvailability}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />
                        </FormItem>

                        {!!this.state.successMsg &&
                        <SuccessMessage message={this.state.successMsg}/>
                        }

                        {!!this.state.errorMsg &&
                        <ErrorMessage message={this.state.errorMsg}/>
                        }
                        
                        <FormItem {...tailLayout}>
                            <Button type="submit"
                                    size="large"
                                    disabled={this.isFormInvalid()}>Speichern</Button>
                        </FormItem>
                    </Form>
                </div>

                    <ProfilEinstellungen nutzer={this.state.user} />
                </div>
         }
        </React.Fragment>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    validateVorname = (vorname) => {
        if(!vorname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen Vornamen an. Die Angabe des Vornamens ist pflicht.`
            }
        } else if (vorname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Vorname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateNachname = (nachname) => {
        if(!nachname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen Nachnamen an. Die Angabe des Nachnamens ist pflicht.`
            }
        } else if (nachname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Nachname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateTelefonnummer = (telefonnummer) => {
        if(!telefonnummer) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deine Telefonnummer an. Die Angabe einer Telefonnummer ist pflicht, damit dich andere Nutzer kontaktieren können.`
            }
        } else if (telefonnummer.length > PHONE_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Telefonnummer ist zu lang. Es sind maximal ${PHONE_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }


    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);
        const user = this.state.user;
        
        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        if(user.email !== emailValue){
            checkEmailAvailability(emailValue)
            .then(response => {
                if(response.available) {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                        }
                    });
                }
            }).catch(error => {
                // Marking validateStatus as success, Form will be recchecked at server
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            });
        }else{
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            }); 
        }
        

    }

}


export default Profil;