import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const nutzerService = {
  saveNutzerProfilInfo,
  getAllNutzerNichtTeilMixedrangliste,
  //getNutzerByNutzerId
    //getNutzerById,
};


// export function getNutzerByNutzerId(nutzerId)
// {
//   return request({
//     url: API_BASE_URL + "/nutzer/getByNutzer?nutzerId="  + nutzerId,
//     method: 'GET'
//   });
// }

export function saveNutzerProfilInfo(nutzerRequest) {
  return request({
    url: API_BASE_URL + "/nutzer/saveProfilInfo",
    method: 'POST',
    body: JSON.stringify(nutzerRequest)
  });
}

export function getAllNutzerNichtTeilMixedrangliste() {
  return request({
    url: API_BASE_URL + "/nutzer/getAllNutzerNichtTeilMixedrangliste" ,
    method: 'GET'
  });
}


//

