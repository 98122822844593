import './Messages.css';

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class LoadingMessage extends React.Component {


    render() {
        return (
            <div className="message success-message">
                <div className="message-container">
                    <div className="message-loading-spinner">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div className="message-content-container">
                        <div className="message-body">{this.props.message}</div>
                    </div>
                </div>
            </div>
        )
    }
}
// end::app[]

export default LoadingMessage;