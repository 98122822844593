export default class ForderungDto {
    constructor(forderungJson) {
        if(forderungJson !== null && forderungJson !== undefined) {
            this.id = forderungJson.id;
            this.spieltermin = forderungJson.spieltermin;
        }
    }

    isDoppel(){
        return false;
    }

    containsNutzer(nutzer){
        return false;
    }

    //erwartet fuer Einzel: einen Nutzer
    //fuer Doppel eine mixedpaarung
    containsNutzerOrMixedpaarung(nutzerOrMixedpaarung){
        return false;
    }

    //wenn ich das behalten will, muesste ich die Methode umbennenen, dass es sie nicht doppelt gibt. Sonst gibts auch ein Wraning in der Konsole
    // containsNutzer(nutzerId){
    //     return false;
    // }
}