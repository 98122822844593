import React from "react";
import {getDisplayNameDoppel, getLabelForHeadingDoppel} from "../../util/DisplayHelpers";

export default class NutzerDoppelDto {

    constructor(nutzerM, nutzerW) {
        if(nutzerM !== null && nutzerW !== undefined){
            this.nutzer1 = nutzerM;
            this.nutzer2 = nutzerW;

            this.vorname1 = nutzerM.vorname;
            this.nachname1 = nutzerM.nachname;
            this.vorname2 = nutzerW.vorname;
            this.nachname2 = nutzerW.nachname;
        }
    }

    isDoppel(){
        return true;
    }

    //Im Einzel gehen wir hier auf den Nutzer
    //Im Mixed geht das nicht, da die Paarung ja aus zwei Nutzern besteht. Da haben wir den Nutzer nicht.
    //Daher gehen wir da auf den Nutzer des eingeloggten Nutzers.
    containsNutzer(nutzer){
        if(this.containsNutzerId(nutzer.id)){
            return true;
        }

        return false;
    }
    containsNutzerId(nutzerId){
        if(this.nutzer1.id === nutzerId){
            return true;
        }

        if(this.nutzer2.id === nutzerId){
            return true;
        }

        return false;
    }

    getLabel(){
        return getDisplayNameDoppel(this);
    }

    getLabelTruncated(){
        return getDisplayNameDoppel(this);
    }

    getLabelForHeading(){
        return getLabelForHeadingDoppel(this);
    }

    getLabelTruncatedForHeading(){
        return getLabelForHeadingDoppel(this);
    }

    getLabelElement(isResponsiveView) {
        let text = <span>{this.nutzer1.nachname}/{this.nutzer2.nachname}</span>;

        if(isResponsiveView){
            text = <span>{this.nutzer1.nachname}/<br/> {this.nutzer2.nachname}</span>;
        }
        return text;
    }

    getKontaktinfoElement() {

        let kontaktinfo =
            <p>
                <u>{this.vorname1}</u><br/>
                <b>E-Mail-Adresse:</b> {this.nutzer1.email} <br/>
                <b>Telefonnummer:</b> {this.nutzer1.telefon}
                <br/><br/>
                <u>{this.vorname2}</u><br/>
                <b>E-Mail-Adresse:</b> {this.nutzer2.email} <br/>
                <b>Telefonnummer:</b> {this.nutzer2.telefon}
            </p>;

        return kontaktinfo;
    }

    getKey(){
        return this.nachname1 + "-" + this.nachname2 + "_" + this.id;
    }
}