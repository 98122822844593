import React from 'react';
import moment from 'moment';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

class SpieltermineList extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            loggedInNutzer: null
        };
        
        this.loadLoggedInNutzer = this.loadLoggedInNutzer.bind(this);
        this.renderEditButtons = this.renderEditButtons.bind(this);
    }

    
    componentDidMount() {
        this._isMounted = true;
        this.loadLoggedInNutzer();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.forderungen!==this.props.forderungen){
            this.setState({ forderungen: prevProps.forderungen });
        }
        if(this.props.currentUser !== prevProps.currentUser) {
            // Reset State
            this.loadLoggedInNutzer();
        }
    }

    //Die hier ist wichtig.
    //sonst kommen die Aenderungen an den Properties-Variable vom Parent nicht hier beim Kind an
    //keine Ahnung wieso.
    //Anmerkung: hier  kann man nicht auf this zugreifen und auch keinen state setzten/aendern
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.forderungen !== prevState.selected) {
            return {
                forderungen: nextProps.forderungen,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }
    
    loadLoggedInNutzer() {
        const currentUser = this.props.currentUser;


        if(currentUser !== null && currentUser !== undefined){

            this.setState({
                loggedInNutzer: currentUser
            });

            // getNutzerByNutzerId(currentUserId)
            // .then(response2 => {
            //     if (this._isMounted) {
            //         this.setState({
            //             loggedInNutzer: response2
            //         });
            //     }
            // })
            // .catch(error => {
            //     setErrorState(error, this);
            // });
        }else{
            this.setState({
                loggedInNutzer: null
            });
        }
    }

    renderEditButtons(loggedInNutzer, forderung){

        if(this.props.adminView){
            return true;
        }

        return forderung.containsNutzer(loggedInNutzer);
    }


    enableDeleteButton(loggedInNutzer, forderung){
        if(this.props.adminView){
            return true;
        }

        if(forderung.containsNutzer(loggedInNutzer)){
            return true;
        }
        return false;
    }
    
    render() {
        const { loggedInNutzer } = this.state;
        const { forderungen } = this.props;

        return (
            <div>
                <h1>{this.props.header}</h1>

                {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                }

                {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                }


                {forderungen === undefined  || forderungen.length === 0 ?
                    //Wenn Forderungen NULL ist
                    <p>Momentan stehen keine Forderungen an.</p>
                    :
                    <div></div>
                }



                {!!forderungen.length &&
                <table className="table table-striped">
                    <tbody>
                    {forderungen.map((forderung, index) =>
                        <tr key={forderung.id}>
                            <td key={forderung.id + '_termin_datum'}>
                                {moment(forderung.spieltermin).format('DD.MM.YY')}
                            </td>
                            <td key={forderung.id + '_termin_uhrzeit'}>
                                {moment(forderung.spieltermin).format('HH:mm')}
                            </td>

                            <td key={forderung.id + '_herausgeforderter_name'}>
                                {forderung.herausgeforderter.getLabel()}
                            </td>

                            <td>
                                -
                            </td>

                            <td key={forderung.id + '_fordernder_name'}>
                                {forderung.fordernder.getLabel()}
                            </td>

                            {!!loggedInNutzer && this.renderEditButtons(loggedInNutzer, forderung) === true &&
                            <React.Fragment>
                                <td>
                                    <Button type="primary" onClick={e => this.props.editForderung(e, forderung.id)} title={"Bearbeiten"}>
                                        <FontAwesomeIcon icon={faPen} style={{display: 'inline-block', verticalAlign: '-0.095em'}}/>
                                    </Button>
                                </td>

                                    <td>
                                    <Button type="primary" variant={this.enableDeleteButton(loggedInNutzer, forderung) ? "danger" : "secondary"} disabled={!this.enableDeleteButton(loggedInNutzer, forderung)}
                                            onClick={e => this.props.deleteForderung(e, forderung.id)} title={"Löschen"}>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{display: 'inline-block', verticalAlign: '-0.095em'}}/>
                                    </Button>
                                </td>
                            </React.Fragment>
                            }
                            {!!loggedInNutzer && this.renderEditButtons(loggedInNutzer, forderung) === false &&
                            <React.Fragment>
                                <td></td>
                                <td></td>
                            </React.Fragment>
                            }
                        </tr>
                        )}
                    </tbody>
                </table>

                }
            </div>
        );
    }
}


export default SpieltermineList;