import React from 'react';
import {getAllForderungenForNutzerSorted} from "../../../_services/forderungService";
import {setErrorState} from '../../../util/ErrorUtil';
import ForderungEinzelDto from "../../../dto/match/ForderungEinzelDto";
import SpieltermineEinzel from "../../../Spieltermine/SpieltermineEinzel";

class MeineForderungenEinzel extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            forderungen: []
        };

        this.loadForderungen = this.loadForderungen.bind(this);
    }



    loadForderungen() {
        getAllForderungenForNutzerSorted(this.props.nutzer.id)
            .then(response => {
                if (this._isMounted) {
                    const mapped = response.map((element) => new ForderungEinzelDto(element));
                    this.setState({forderungen: mapped});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadForderungen();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadForderungen();
        }
    }

    render() {
        const { forderungen } = this.state;

        return (
            <div>
                <SpieltermineEinzel forderungen={forderungen}
                                  reloadForderungen={this.loadForderungen} 
                                  header={"Deine anstehenden Forderungen"}
                                  currentUser={this.props.currentUser}/>
            </div>
        );
    }
}


export default MeineForderungenEinzel;