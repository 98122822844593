import './Forderung.css';

import FehlerDuKannstNichtFordern from '../forderung/components/FehlerDuKannstNichtFordern';
import ForderungsNutzerInfos from '../forderung/components/ForderungsNutzerInfos';

import {setErrorState} from '../../util/ErrorUtil';

import 'antd/dist/antd.css';
import 'moment/locale/de';
import ForderungSpielterminMixed from "./components/ForderungSpielterminMixed";
import {forderungMixedService} from "../../_services/forderungMixedService";
import ForderungDoppelDto from "../../dto/match/ForderungDoppelDto";
import ForderungsErgebnisMixed from "./components/ForderungsErgebnisMixed";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class ForderungMixed extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      //Ob es sich um die "Bearbeiten-Seite" der ForderungDto handelt. ALso ob ich über mein Profil -> "Meine Forderungen" -> Bearbeiten....auf die Seite gekommen bin.
      //Dann ist isForderungEdit = true
      //Aber bin ich ganz normal, ueber die RanglisteEinzel, durch Klick auf einen RanglistenEintragDto auf die Forderungsseite gekommen,
      //Dann ist isForderungEdit = false
      isForderungEdit: this.props.isForderungEdit,
      //Ob es sich um die Sicht als "ForderungDto" (klick im Baum in der RanglisteEinzel) oder "Herausforderung" (kann im Profil unter "Meine Forderungen")
      isForderung: this.props.isForderung,
      // zuFordernderNutzer: null,
      // loggedInNutzer: null,
      fordernderRanglistenEintrag: this.props.fordernderRanglistenEintrag,
      herausgeforderterRanglistenEintrag: this.props.herausgeforderterRanglistenEintrag,      
      existentForderung: null,
      existentForderungLoaded: false,
      existentTerminSet: false,
      theirForderungenLoaded: false,
      termin: null
      //termin: new Date() das funktioniert nicht. Das ueberschreibt iwie dann spaeter das Setzen des richtigen Datums. Keine Ahnung warum.
    };

    this.loadExistentForderung = this.loadExistentForderung.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;

    this.loadExistentForderung();
  }


  //falls bereits eine ForderungDto existiert, wird diese geladen
  loadExistentForderung(){
    const fordernderMixedpaarungId = this.props.fordernderRanglistenEintrag.id;
    const herausgeforderterMixedpaarungId = this.props.herausgeforderterRanglistenEintrag.id;
    forderungMixedService.getFordererAndHerausgefordertertId(fordernderMixedpaarungId, herausgeforderterMixedpaarungId)
    .then(response => {
      if (this._isMounted) {
        const mapped = new ForderungDoppelDto(response);
        this.setState({
          existentForderung: mapped,
          //existentForderungLoaded brauch ich, da existentForderung ja auch NULL sein kann ich sonst nicht festellen kann, ob die ForderungDto geladen wurde
          existentForderungLoaded: true
        });

        if(response !== null && response.id > 0){
          //falls eine ForderungDto existiert, den Termin aus dieser ForderungDto setzen
          this.setState({
            termin: new Date(response.spieltermin),
            existentTerminSet: true
          });
        }else{
          this.setState({
            existentForderungLoaded: true,
            existentTerminSet: true
          });
        }
        this.loadForderungenForNutzer();
      }
    })
    .catch(error => {
      //hier springt er auch rein, wenn es keine gibt
      this.setState({
        existentForderungLoaded: true,
        existentTerminSet: true
      });
      console.log(error)
      setErrorState(error, this);
      this.loadForderungenForNutzer();
    });
  }


  //Falls einer der beiden RanglistenEintragDto bereits eine ForderungDto hat, kann dieser nicht mehr fordern/gefordert werden
  loadForderungenForNutzer(){
    //meine Forderungen laden
    forderungMixedService.getAllForTheseTwoNutzerSortedExceptTheirs(this.getFordernder().id, this.getHerausgeforderter().id)
    .then(response => {
      if (this._isMounted) {
        if(response !== null && response.length > 0){
          const mapped = response.map((element) => new ForderungDoppelDto(element));
          this.setState({
            theirForderungen: mapped,
            theirForderungenLoaded: true
          });
        }else{
          this.setState({
            theirForderungenLoaded: true
          });
        }
      }
    })
    .catch(error => {
      //hier springt er auch rein, wenn es keine gibt
      this.setState({
        myForderungenLoaded: true
      });
      setErrorState(error, this);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isForderung, herausgeforderterRanglistenEintrag, existentForderung,  existentForderungLoaded, existentTerminSet, fordernderRanglistenEintrag, termin, theirForderungen, theirForderungenLoaded,isForderungEdit} = this.state;
    
    //Die Infos des Nutzers, die auf der Seite angezeigt werden sollen (Name, email, Telefonnummer, ...)
    var nutzerInfos = herausgeforderterRanglistenEintrag;
    
    //Es gibt 2 Stellen, an denen die Forderungseite verwendet wird:
    //einmal auf der RanglisteEinzel, wenn man einen RanglistenEintragDto anklickt.
    //und einmal im Profil unter "Meine Forderungen" = isForderungEdit = true
    //Und in meinem Profil, soll dieser Fehler, dass ich nicht fordern kann, nicht kommen.
    if(!isForderungEdit){
      const ichBinNichtForderbar = theirForderungenLoaded && theirForderungen;

      if (ichBinNichtForderbar) {
        return <FehlerDuKannstNichtFordern theirForderungen={theirForderungen} loggedInNutzer={fordernderRanglistenEintrag.nutzer} ranglistenEintrag={herausgeforderterRanglistenEintrag}/>;
      }
    }
    
    if(!isForderung){
      nutzerInfos = fordernderRanglistenEintrag;
    }

    return (
        <div className="content-container">

          {!!theirForderungenLoaded && !!herausgeforderterRanglistenEintrag &&
          <ForderungsNutzerInfos nutzer={nutzerInfos} isForderung={isForderung}/>
          }
          {!!theirForderungenLoaded && !!herausgeforderterRanglistenEintrag && !!fordernderRanglistenEintrag && !!existentForderungLoaded && !!existentTerminSet &&
          <ForderungSpielterminMixed nutzer={herausgeforderterRanglistenEintrag}
                                existentForderung={existentForderung}
                                loggedInNutzer={fordernderRanglistenEintrag}
                                termin={termin}
                                isForderung={isForderung}/>
          }

          {!!theirForderungenLoaded && !!herausgeforderterRanglistenEintrag && !!fordernderRanglistenEintrag && !!existentForderungLoaded &&
          <ForderungsErgebnisMixed herausgeforderterRanglistenEintrag={herausgeforderterRanglistenEintrag}
                                   fordernderRanglistenEintrag={fordernderRanglistenEintrag}
                              isForderung={isForderung}/>
          }

          <br/>
          {!!theirForderungenLoaded &&
              <a className="btn btn-primary" style={{ width: '100%' }} href="/rangliste-mixed/home">Zurück</a>
          }
        </div>
    )
  }

  getFordernder(){

    if(this.state.herausgeforderterRanglistenEintrag.position > this.state.fordernderRanglistenEintrag.position){
      return this.state.herausgeforderterRanglistenEintrag;
    }

    return this.state.fordernderRanglistenEintrag;
  }

  getHerausgeforderter(){
    if(this.state.herausgeforderterRanglistenEintrag.position > this.state.fordernderRanglistenEintrag.position){
      return this.state.fordernderRanglistenEintrag;
    }

    return this.state.herausgeforderterRanglistenEintrag;
  }

}
// end::app[]

// Specifies the default values for props:
//so muss in der 'zentralen' Forderungs-Komponente das Property isForderung nicht gesetzt werden
ForderungMixed.defaultProps = {
  isForderungEdit: false,
  isForderung: true
};


export default ForderungMixed;