import React from 'react';
import {setErrorState} from '../../../util/ErrorUtil';
import Gespielt from "../../../Gespielt/component/Gespielt";
import {getGespieltForNutzer} from "../../../_services/gespieltService";
import GespieltEinzelDto from "../../../dto/match/GespieltEinzelDto";

class MeineGespieltenEinzel extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            gespielt: []
        };

    }


    loadMeineGespielten() {
        getGespieltForNutzer(this.props.nutzer.id)
        .then(response => {
            if (this._isMounted) {
                const mapped = response.map((element) => new GespieltEinzelDto(element));
                this.setState({gespielt: mapped});
            }
        })
        .catch(error => {
            setErrorState(error, this);
        });
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadMeineGespielten();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadMeineGespielten();
        }
    }


    render() {

        return (
            <div>
                <Gespielt gespielt={this.state.gespielt} header={"Deine gespielten Matches"}/>
            </div>
        );
    }
}


export default MeineGespieltenEinzel;