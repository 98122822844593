import React from 'react';
import {setErrorState} from '../../../util/ErrorUtil';
import {gespieltMixedService} from "../../../_services/gespieltMixedService";
import {ranglisteMixedEintragService} from "../../../_services/ranglisteMixedEintragService";
import GespieltDoppelDto from "../../../dto/match/GespieltDoppelDto";

class MeineMatchStatistikMixed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            meineMixedpaarung: [],
            gespieltAll: [],
            forderungen: [],
            herausforderungen: [],
            gespieltAllLoaded: false,
            forderugnenLoaded: false,
            herausforderungenLoaded: false
        };

        this.loadStatistikData = this.loadStatistikData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadStatistikData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadStatistikData();
        }
    }

    loadStatistikData(){
        //meine Mixedpaarung laden
        ranglisteMixedEintragService.getRanglisteMixedEintragByNutzerId(this.props.currentUser.id)
            .then(response => {

                if (this._isMounted) {
                    this.setState({meineMixedpaarung: response});
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
                setErrorState(error, this);
            });

        //hole alle gespielten Matches des Nutzers
        gespieltMixedService.getGespielteMixedForNutzer(this.props.currentUser.id)
        .then(responseGespielt => {

            if (this._isMounted) {
                this.setState({
                    gespieltAll: responseGespielt,
                    gespieltAllLoaded: true});
            }
        })
        .catch(errorNutzer => {
            if (this._isMounted) {
                this.setState({hasError: errorNutzer})
            }
            setErrorState(errorNutzer, this);
        });
        
        //hole alle gespielten Matches, in denen der RanglistenEintragDto der Fordernde war
        gespieltMixedService.getAllByFordernder(this.props.currentUser.id)
        .then(responseForderungen => {

            if (this._isMounted) {
                this.setState({
                    forderungen: responseForderungen,
                    forderugnenLoaded: true});
            }
        })
        .catch(errorNutzer => {
            if (this._isMounted) {
                this.setState({hasError: errorNutzer})
            }
            setErrorState(errorNutzer, this);
        });

        //hole alle gespielten Matches, in denen der RanglistenEintragDto der Herausgeforderte war
        gespieltMixedService.getAllByHerausgeforderter(this.props.currentUser.id)
        .then(responseHerausforderungen => {

            if (this._isMounted) {
                this.setState({
                    herausforderungen: responseHerausforderungen,
                    herausforderungenLoaded: true});
            }
        })
        .catch(errorNutzer => {
            if (this._isMounted) {
                this.setState({hasError: errorNutzer})
            }
            setErrorState(errorNutzer, this);
        });
    }


    //liefert fuer das uebergebene Array an gespielt-Objekten die Anzahl der gewonnen und verlorenen Matches des 
    //eingeloggten Nutzers zuerueck in Form eines Arrays: [anzahlGewonnen, anzahlVerloren]
    getGewonnenVerlorenCount = (matchesArray) => {
        var gewonnenCount = 0;
        var verolrenCount = 0;

        //Vorsicht: array.forEach geht hier nicht. Dann funktionieren irgendwie die
        //this.-Methodenaufrufe in der Schleife nicht mehr.
        //Daher ueber die Zaehlerschleife gemacht.
        for (var i = 0; i < matchesArray.length; i++) {
            if(this.hasMatchGewonnen(matchesArray[i])){
                gewonnenCount = gewonnenCount + 1;
            }else{
                verolrenCount = verolrenCount + 1;
            }
        }
        return [gewonnenCount, verolrenCount];
    }
    
    //ob der RanglistenEintragDto das uebergebenen gespielt-Objekt gewonnen hat
    hasMatchGewonnen = (gespielt) => {
        var dto = new GespieltDoppelDto(gespielt);

        //bin ich der gewinner?
        return dto.isWinner(this.props.currentUser.id);
    }
    
    
    render() {
        const statTable = {
            padding: '4px 9px',
            fontSize: '14px',
        };

        const statErgebnisCell = {
            paddingLeft: '30px',
            fontSize: '16px',
        };
        
        const { gespieltAll, forderungen, herausforderungen } = this.state;

        //------ Statistik fuer alle Matches insgesamt
        const allMatchesStatistik = this.getGewonnenVerlorenCount(gespieltAll);
        var allMatchesGewonnen = allMatchesStatistik[0];
        var allMatchesVerloren = allMatchesStatistik[1];
        

        //------ Statistik fuer die Forderungen
        const forderungenStatistik = this.getGewonnenVerlorenCount(forderungen);
        var forderungenGewonnen = forderungenStatistik[0];
        var forderungenVerloren = forderungenStatistik[1];

        //------ Statistik fuer die Herausforderungen
        const herausforderungenStatistik = this.getGewonnenVerlorenCount(herausforderungen);
        var herausforderungenGewonnen = herausforderungenStatistik[0];
        var herausforderungenVerloren = herausforderungenStatistik[1];
        
        return (
            <div>
                <h1>Deine Match Statistik</h1>

                 <table style={statTable}>
                     <tbody>
                    <tr>
                        <td>Matches isngesamt</td>
                        
                        <td style={statErgebnisCell}>
                            {!!this.state.gespieltAllLoaded &&
                            <div>{allMatchesGewonnen}:{allMatchesVerloren}</div>
                            }
                        </td>
                        
                    </tr>
                    <tr>
                        <td>Deine Forderungen an andere Nutzer</td>
                        <td style={statErgebnisCell}>
                            {!!this.state.forderugnenLoaded &&
                            <div>{forderungenGewonnen}:{forderungenVerloren}</div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Forderungen anderer Nutzer an dich</td>
                        <td style={statErgebnisCell}>
                            {!!this.state.herausforderungenLoaded &&
                            <div>{herausforderungenGewonnen}:{herausforderungenVerloren}</div>
                            }
                        </td>
                    </tr>
                 </tbody>
                </table>
                
            </div>
        );
    }
}


export default MeineMatchStatistikMixed;