import './Forderung.css';
import 'antd/dist/antd.css';
import 'moment/locale/de';
import {setErrorState} from "../../util/ErrorUtil";

import ForderungEinzel from './ForderungEinzel';
import RanglistenEinzelEintragDto from "../../dto/match/RanglistenEinzelEintragDto";
import {
  getEinzelEintragByNutzerId,
  getRanglisteEinzelEintragById
} from "../../_services/ranglisteEinzelEintragService";
import {authService} from "../../_services/authService";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class ForderungFromRanglisteEinzel extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      //Ob es sich um die "Bearbeiten-Seite" der ForderungDto handelt. ALso ob ich über mein Profil -> "Meine Forderungen" -> Bearbeiten....auf die Seite gekommen bin.
      //Dann ist isForderungEdit = true
      //Aber bin ich ganz normal, ueber die RanglisteEinzel, durch Klick auf einen RanglistenEintragDto auf die Forderungsseite gekommen,
      //Dann ist isForderungEdit = false
      isForderungEdit: this.props.isForderungEdit,
      //Ob es sich um die Sicht als "ForderungDto" (klick im Baum in der RanglisteEinzel) oder "Herausforderung" (kann im Profil unter "Meine Forderungen")
      isForderung: this.props.isForderung,
      herausgeforderterRanglistenEintrag: null,
      fordernderRanglistenEintrag: null,
    };

    this.loadHerausgeforderterNutzerAndFordernderNutzer = this.loadHerausgeforderterNutzerAndFordernderNutzer.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;

    this.loadHerausgeforderterNutzerAndFordernderNutzer();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //der HerausgeforderterS wird aus den Props uebergeben und
  //der Fordernder ist der angemeldete RanglistenEintragDto
  loadHerausgeforderterNutzerAndFordernderNutzer(){

    //Zuerst den anderen RanglistenEintragDto laden
    const herausgeforderterNutzerId = this.props.match.params.herausgeforderterRanglistenEintragId;

    getRanglisteEinzelEintragById(herausgeforderterNutzerId)
    .then(response => {
      if (this._isMounted) {
        const mapped = new RanglistenEinzelEintragDto(response);
        this.setState({
          herausgeforderterRanglistenEintrag: mapped
        });
        //dann den eingeloggten RanglistenEintragDto ladem
        //hier wird dann danach auch die ForderungDto geladen und dazu muessen ja schon beide RanglistenEintragDto da sein.
        //daher konnte ich die 3 Methoden nicht einfach in der DidMount-Methode nacheinander aufrufen, sondern muss das immer
        //in dem then tun
        this.loadUser();
      }

    })
    .catch(error => {
      setErrorState(error, this);
    });
  }

  loadUser() {
    authService.getCurrentUser()
    .then(responseLoggedInUser => {
      if (this._isMounted) {
        getEinzelEintragByNutzerId(responseLoggedInUser.id)
            .then(response => {
              if (this._isMounted) {
                const mapped = new RanglistenEinzelEintragDto(response);
                this.setState({
                  fordernderRanglistenEintrag: mapped
                });
              }

            })
            .catch(error => {
              setErrorState(error, this);
            });

      }
    })
    .catch(error => {
      setErrorState(error, this);
    });
  }
  
  render() {
    
    const { herausgeforderterRanglistenEintrag, fordernderRanglistenEintrag} = this.state;

    return (
        <div>
          {!!herausgeforderterRanglistenEintrag && !!fordernderRanglistenEintrag &&
            <ForderungEinzel
                fordernderRanglistenEintrag={fordernderRanglistenEintrag}
                herausgeforderterRanglistenEintrag={herausgeforderterRanglistenEintrag}
                isForderung={true}
                isForderungEdit={false}/>
          }
        </div>
    )
  }


}
// end::app[]

export default ForderungFromRanglisteEinzel;