import React from 'react';
import {setErrorState} from '../../util/ErrorUtil';

import {Button} from 'react-bootstrap';
import {Form} from 'antd';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {ranglisteMixedEintragService} from "../../_services/ranglisteMixedEintragService";
import {ranglisteEinzelEintragService} from "../../_services/ranglisteEinzelEintragService";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import RegisterMixedProfil from "./mixed/RegisterMixedProfil";
import {SHOW_MIXED_RANGLISTE} from "../../constants";

const FormItem = Form.Item;


class ProfilEinstellungen extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            ranglisteEinzelEintrag: {},
            mixedpaarung: {},

            einzelrangliste: {
                value: false
            },
            mixedrangliste: {
                value: false
            },
        };

        this.loadMixedpaarung = this.loadMixedpaarung.bind(this);
        this.loadRanglisteEinzelEintrag = this.loadRanglisteEinzelEintrag.bind(this);
    }


    
    componentDidMount() {
        this._isMounted = true;
        this.loadMixedpaarung();
        this.loadRanglisteEinzelEintrag();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    componentDidUpdate(nextProps) {
        if(this.props !== nextProps) {
            // Reset State
            this.loadMixedpaarung();
            this.loadRanglisteEinzelEintrag();
        }
    }

    loadRanglisteEinzelEintrag(){
        ranglisteEinzelEintragService.getEinzelEintragByNutzerId(this.props.nutzer.id)
            .then(response => {

                if (this._isMounted) {
                    this.setState({ranglisteEinzelEintrag: response});
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
                setErrorState(error, this);
            });
    }

    loadMixedpaarung(){
        ranglisteMixedEintragService.getRanglisteMixedEintragByNutzerId(this.props.nutzer.id)
            .then(response => {

                if (this._isMounted) {
                    this.setState({mixedpaarung: response});
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
                setErrorState(error, this);
            });
    }

    addEinzelEintragForNutzer(){
        ranglisteEinzelEintragService.addEinzelEintragForNutzer(this.props.nutzer)
            .then(response => {
                this.setState({
                    ranglisteEinzelEintrag: response,
                    successMsg: "Du wurdest erfolgreich in die Einzelrangliste eingetragen."
                });

            }).catch(error => {
                console.log(error);
            setErrorState(error, this);
        });
    }

    removeFromEinzelRangliste(){
        ranglisteEinzelEintragService.deleteEinzelEintrag(this.state.ranglisteEinzelEintrag.id)
            .then(response => {
                this.setState({
                    ranglisteEinzelEintrag: {},
                    successMsg: "Du wurdest erfolgreich aus der Einzelrangliste gelöscht."
                });

            }).catch(error => {
            setErrorState(error, this);
        });
    }

    removeFromMixedRangliste(){
        ranglisteMixedEintragService.deleteMixedpaarungAndNutzerById(this.state.mixedpaarung.id)
            .then(response => {
                this.setState({
                    mixedpaarung: {},
                    successMsg: "Du wurdest erfolgreich aus der Mixedrangliste gelöscht."
                });

            }).catch(error => {
            setErrorState(error, this);
        });
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const inputName = target.name;
        const checked = target.checked;

        this.setState({
            [inputName] : {
                value: checked
            }
        });
    }
    
    render() {

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };

        const { ranglisteEinzelEintrag, mixedpaarung } = this.state;


        var renderDeleteEinzel = false;         //Sektion zum Löschen aus der Einzelrangliste anzeigen
        var renderAddToEinzel = true;           //Sektion zum Eintragen in die Einzelrangliste anzeigen
        if(ranglisteEinzelEintrag && ranglisteEinzelEintrag.id > 0){
            renderDeleteEinzel = true;
            renderAddToEinzel = false;
        }

        var renderDeleteMixed = false;
        var renderAddToMixed = true;
        if(mixedpaarung && mixedpaarung.id > 0){
            renderDeleteMixed = true;
            renderAddToMixed = false;
        }

        return (
            <React.Fragment>
            {!!ranglisteEinzelEintrag &&  this.props.nutzer &&
                <div className="form-container">
    
                    <h1>Einstellungen</h1>

                    {!!this.state.successMsg &&
                        <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                        <ErrorMessage message={this.state.errorMsg}/>
                    }

                    {!!renderAddToEinzel &&
                        <div>
                            <h3>Anmeldung Einzel-Rangliste</h3>
                            <p>Ich möchte mich für die Einzelrangliste eintragen:</p>
                            <FormItem {...tailLayout}>
                                <Button type="submit"
                                        size="large"
                                        onClick={e => this.addEinzelEintragForNutzer()} ><FontAwesomeIcon icon={faPlusCircle} /> in die Einzelrangliste eintragen</Button>
                            </FormItem>
                        </div>
                    }

                        {!!renderDeleteEinzel &&
                        <div>
                            <h3>Abmeldung Einzel-Rangliste</h3>
                            <p>Ich möchte nicht mehr in der Einzelrangliste spielen:</p>
                            <FormItem {...tailLayout}>
                                <Button type="submit"
                                        size="large"
                                        onClick={e => this.removeFromEinzelRangliste()} ><FontAwesomeIcon icon={faTrashAlt} /> aus der Einzelrangliste löschen</Button>
                            </FormItem>
                        </div>
                        }


                    {!!SHOW_MIXED_RANGLISTE && renderAddToMixed &&
                        <div>
                            <RegisterMixedProfil  currentUser={this.props.nutzer}/>
                        </div>
                    }

                        {!!SHOW_MIXED_RANGLISTE && renderDeleteMixed &&
                        <div>
                            <h3>Abmeldung Mixed-Rangliste</h3>
                            <p>Ich möchte nicht mehr in der Mixedrangliste spielen:</p>
                            <FormItem {...tailLayout}>
                                <Button type="submit"
                                        size="large"
                                        onClick={e => this.removeFromMixedRangliste()}><FontAwesomeIcon icon={faTrashAlt} /> aus der Mixedrangliste löschen</Button>
                            </FormItem>
                       </div>
                        }


                </div>
         }
        </React.Fragment>
        );
    }


}


export default ProfilEinstellungen;