import React from 'react';
import {gespieltMixedService} from "../_services/gespieltMixedService";
import {setErrorState} from "../util/ErrorUtil";
import GespieltDoppelDto from "../dto/match/GespieltDoppelDto";
import Gespielt from "./component/Gespielt";

class GespieltMixed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            gespielt: []
        };

        this.loadData = this.loadData.bind(this);
        this.loadGespielt = this.loadGespielt.bind(this);
    }

    loadData() {
        this.loadGespielt();
    }

    // //Die hier ist wichtig.
    // //sonst kommen die Aenderungen an den Properties-Variable vom Parent nicht hier beim Kind an
    // //keine Ahnung wieso.
    // //Anmerkung: hier  kann man nicht auf this zugreifen und auch keinen state setzten/aendern
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.gespielt !== prevState.gespielt) {
    //         return {
    //             gespielt: nextProps.gespielt,
    //         };
    //     }
    //
    //     // Return null if the state hasn't changed
    //     return null;
    // }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    loadGespielt() {
        gespieltMixedService.getLetztenXSorted(5)
            .then(response => {
                if (this._isMounted) {
                    const mapped = response.map((element) => new GespieltDoppelDto(element));
                    this.setState({gespielt: mapped});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }

    render() {
        const { gespielt } = this.state;

        return (
            <Gespielt gespielt={gespielt} header={"Zuletzt gespielte Matches"}/>
        );
    }
}

export default GespieltMixed;