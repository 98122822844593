import React from 'react'
import MeineMatchStatistikEinzel from "./MeineMatchStatistikEinzel";
import MeineForderungenEinzel from "./MeineForderungenEinzel";
import MeineGespieltenEinzel from "./MeineGespieltenEinzel";
import {setErrorState} from "../../../util/ErrorUtil";
import {authService} from "../../../_services/authService";

class MatchProfiEinzell extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            nutzer: []
        };

        this.loadLoggedInUser = this.loadLoggedInUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadLoggedInUser();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadLoggedInUser() {
        authService.getCurrentUser()
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        user: response,
                        nutzer: response.nutzer
                    });
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }


  render()
  {
    return (
        <React.Fragment>
            {!!this.state.nutzer && !!this.state.nutzer.id &&
            <div>
                <MeineMatchStatistikEinzel nutzer={this.state.nutzer}/>
                <br/>
                <MeineForderungenEinzel nutzer={this.state.nutzer} currentUser={this.props.currentUser}/>
                <br/>
                <MeineGespieltenEinzel nutzer={this.state.nutzer}/>
            </div>
            }
        </React.Fragment>
    );
  }
}
export default MatchProfiEinzell;