// tag::ForderungSpieltermin[]
// der Abschnitt zum Eintragen des Spieltermins
import moment from "moment";
import {DatePicker, Form, notification, TimePicker} from "antd";
import {
  deleteForderungById,
  existsAndereForderungZurSelbenZeit,
  saveForderung
} from "../../../_services/forderungService";
import {setErrorState} from "../../../util/ErrorUtil";
import deDE from "antd/lib/locale/de_DE";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import WarningMessage from "../../../_components/notifications/WarningMessage";
import {forderungMixedService} from "../../../_services/forderungMixedService";

const FormItem = Form.Item;

// tag::vars[]
const React = require('react');
// end::vars[]


class ForderungSpieltermin extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    var propTermin = moment(this.props.termin);
    if(!this.props.termin){
      propTermin = null;
    }

    this.state = {
      //Ob es sich um die Sicht als "ForderungEinzel" (klick im Baum in der Rangliste) oder "Herausforderung" (kann im Profil unter "Meine Forderungen")
      isForderung: this.props.isForderung,
      existentForderung: this.props.existentForderung,
      nutzer: this.props.nutzer,
      loggedInNutzer: this.props.loggedInNutzer,
      termin: this.props.termin,
      terminDatum: propTermin,
      terminUhrzeit: propTermin,
      spieltermin: {
        value: ''
      },
    };


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.timePickerPanelChange = this.timePickerPanelChange.bind(this);
    this.handleTimeChange =  this.handleTimeChange.bind(this);
    this.deleteForderung = this.deleteForderung.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  handleSubmit(values) {

    this.setState({
      errorMsg: null,
      successMsg: null,
      warningMsg: null
    });

    const valid = this.validateSpieltermin(this.state.termin);

    if(valid.validateStatus !== 'success'){
      return;
    }

    var fordernder = this.getFordernder();
    var herausgeforderter = this.getHerausgeforderter();

    var existentForderung = this.state.existentForderung;

    //Validierung Spieltermin
    if(!this.state.terminDatum || !this.state.terminDatum.isValid()){
      notification.error({
        message: 'Vereinsrangliste',
        description: "Bitte gib einen validen Spieltermin an.",
      });

      this.setState({
        errorMsg: "Bitte gib einen validen Spieltermin an."
      });

      return;
    }

    if(!this.state.terminUhrzeit || !this.state.terminUhrzeit.isValid()){
      notification.error({
        message: 'Vereinsrangliste',
        description: "Bitte gib eine valide Uhrzeit für euren Spieltermin an.",
      });

      this.setState({
        errorMsg: "Bitte gib eine valide Uhrzeit für euren Spieltermin an."
      });

      return;
    }
    // ENDE

    var tUhrzeit = this.state.terminUhrzeit;
    var spielterminMitUhrzeit = this.state.terminDatum;

    spielterminMitUhrzeit.set({
      hour:   tUhrzeit.get('hour'),
      minute: tUhrzeit.get('minute'),
      second: 0
    });



    const spielterminRequest = {
      id: existentForderung ? existentForderung.id : 0,
      fordernder: fordernder,
      herausgeforderter: herausgeforderter,
      //spieltermin: this.state.termin
      spieltermin: spielterminMitUhrzeit
    };

    if(this.props.isMixed){
      this.makeSaveCall(forderungMixedService.saveForderung, spielterminRequest);
    } else{
      this.makeSaveCall(saveForderung, spielterminRequest);
    }
    // saveForderung(spielterminRequest)
    // .then(response => {
    //   if(response){
    //     this.setState({
    //       existentForderung: response,
    //       successMsg: "Euer Spieltermin wurde erfolgreich gespeichert."
    //     });
    //   }
    //
    //   //Warnmeldung ausgeben, falls zu dieser Zeit bereits Forderungen angesetzt sind.
    //   existsAndereForderungZurSelbenZeit(response)
    //       .then(responseExistentSameSpieltermin => {
    //         if(responseExistentSameSpieltermin){
    //           this.setState({
    //             warningMsg: "Zu dieser Zeit findet bereits eine ForderungEinzel statt. Achtet darauf, dass in der Zeit des Mannschaftstrainings nur EIN Platz belegt werden darf!"
    //           });
    //         }
    //
    //       }).catch(error => {
    //     setErrorState(error, this);
    //   });
    //
    // }).catch(error => {
    //     setErrorState(error, this);
    // });

  }

  makeSaveCall(saveFunc, spielterminRequest){

    saveFunc(spielterminRequest)
        .then(response => {
          if(response){
            this.setState({
              existentForderung: response,
              successMsg: "Euer Spieltermin wurde erfolgreich gespeichert."
            });
          }

          //Warnmeldung ausgeben, falls zu dieser Zeit bereits Forderungen angesetzt sind.
          existsAndereForderungZurSelbenZeit(response)
              .then(responseExistentSameSpieltermin => {
                if(responseExistentSameSpieltermin){
                  this.setState({
                    warningMsg: "Zu dieser Zeit findet bereits eine ForderungEinzel statt. Achtet darauf, dass in der Zeit des Mannschaftstrainings nur EIN Platz belegt werden darf!"
                  });
                }

              }).catch(error => {
            setErrorState(error, this);
          });

        }).catch(error => {
      setErrorState(error, this);
    });
  }

  deleteForderung(e){
    e.preventDefault();
    const forderungId = this.state.existentForderung ? this.state.existentForderung.id : 0;

    if(forderungId > 0){
      deleteForderungById(forderungId)
          .then(response => {
            this.setState({
              successMsg: "Euer Spieltermin und damit auch eure ForderungEinzel, wurde erfolgreich geloescht.",
              terminDatum: null,
              terminUhrzeit: null,
            });

          }).catch(error => {
        setErrorState(error, this);
      });
    }
  }


  getFordernder(){
    if(this.state.nutzer.position > this.state.loggedInNutzer.position){
      return this.state.nutzer;
    }

    return this.state.loggedInNutzer;
  }

  getHerausgeforderter(){
    if(this.state.nutzer.position > this.state.loggedInNutzer.position){
      return this.state.loggedInNutzer;
    }

    return this.state.nutzer;
  }


  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  }

  //function(date: moment, dateString: string)
  //also value ist moment und dateString ist ein String
  //Fuer den Datepicker
  handleChange(value, dateString) {
    //wenn auf NULL/Leer gesetzt wird
    if(!value){
      this.setState({
        terminDatum: null
      });
      return;
    }

    this.setState({
      termin: value,
      terminDatum: value
    });
  }


  //time ist vom Typ moment
  timePickerPanelChange(value){
    //wenn auf NULL/Leer gesetzt wird
    if(!value){
      return;
    }

    var time = value;

    var aktuellerTermin = this.state.terminUhrzeit;
    if(!this.state.terminUhrzeit){
      aktuellerTermin = time;
    }

    aktuellerTermin.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: 0
    });

    this.setState({
      termin: aktuellerTermin,
      terminUhrzeit: aktuellerTermin,
    });

    //Wenn das Datum-Feld noch leer ist, dann wird
    //da heute reingeschrieben.
    if(!this.state.terminDatum){
      this.setState({
        terminDatum: aktuellerTermin
      });
    }

  }

  //die onChange brauchen wir noch, da sonst das Bestaetigen mit ENTER nicht funktioniert.
  handleTimeChange(value) {
    if(!value){
      return;
    }
    var time = value;

    var aktuellerTermin = this.state.terminUhrzeit;
    if(!this.state.terminUhrzeit){
      aktuellerTermin = time;
    }

    aktuellerTermin.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: 0
    });

    this.setState({
      termin: aktuellerTermin,
      terminUhrzeit: aktuellerTermin,
    });

    //Wenn das Datum-Feld noch leer ist, dann wird
    //da heute reingeschrieben.
    if(!this.state.terminDatum){
      this.setState({
        terminDatum: aktuellerTermin
      });
    }
  }


  render() {
    const { spieltermin} = this.state;
    const { nutzer, loggedInNutzer, isForderung, existentForderung } = this.props;


    const dateTimeFormat = 'DD.MM.YYYY';
    const timeFormat = 'HH:mm';
    //Einen vorhanden Wert eintragen geht nur ueber defaultValue
    //Aber wenn es noch keinen Termin gibt, dann will ich eig, dass das Feld leer ist
    //(weil man sonst nicht richtig erkennen kann, ob ein Termin gesetzt ist oder nicht)
    //(und '' als defaultValie geht nicht, dann steht in dem Feld invalid Date drin
    let datePickerComp =<DatePicker
        value={this.state.terminDatum}
        locale={deDE}
        //default-Wert hier extra deaktiviert werden
        //defaultValue={moment(this.state.termin, dateFormat)}
        placeholder="Datum"
        allowClear={false}
        format={dateTimeFormat}
        onChange={this.handleChange}
    />;

    let timePickerComp =<TimePicker
        value={this.state.terminUhrzeit}
        showTime={{
          hideDisabledOptions: true,
          //defaultValue: [moment(this.state.termin, timeFormat)]
        }}
        //default-Wert hier extra deaktiviert werden
        //defaultValue={moment(this.state.termin, dateFormat)}
        placeholder="Uhrzeit"
        showNow={false}
        allowClear={false}
        defaultOpenValue={moment('17:00', 'HH:mm')}
        format={timeFormat}
        onSelect={this.timePickerPanelChange}
        onChange={this.handleTimeChange}
        minuteStep={15}
        style={{ width: 100 }}
    />;

    if(this.state.terminDatum)
    {
      datePickerComp =<DatePicker
          value={this.state.terminDatum}
          locale={deDE}
          placeholder="Datum"
          allowClear={false}
          defaultValue={moment(this.state.termin, dateTimeFormat)}
          format={dateTimeFormat}
          onChange={this.handleChange}
      />;

    }

    if(this.state.terminUhrzeit)
    {
      timePickerComp =<TimePicker
          value={this.state.terminUhrzeit}
          showTime={{
            hideDisabledOptions: true,
            //defaultValue: [moment(this.state.termin, timeFormat)]
          }}
          //default-Wert hier extra deaktiviert werden
          //defaultValue={moment(this.state.termin, dateFormat)}
          placeholder="Uhrzeit"
          showNow={false}
          allowClear={false}
          format={timeFormat}
          onSelect={this.timePickerPanelChange}
          onChange={this.handleTimeChange}
          minuteStep={15}
          style={{ width: 100 }}
      />;
    }

    let validierungsmeldung = '';

    if(spieltermin.validateStatus === 'error'){
      validierungsmeldung = validierungsmeldung + spieltermin.errorMsg;
    }

    return (

        <React.Fragment>
          {!!nutzer && !! loggedInNutzer &&
          <div>
            <h2>Spieltermin eintragen</h2>

            <p>
              Ihr habt einen Spieltermin ausgemacht? Trage ihn hier ein:
            </p>

            <Form layout="inline" onFinish={this.handleSubmit} className="spieltermin-form">

              <FormItem
                  label={<b>Datum</b>}
                  rules={[{
                    required: true,
                    message: 'Bitte gib ein Datum an!' }]}>
                {datePickerComp}
              </FormItem>

              <FormItem
                  label={<b>Uhrzeit</b>}
                  rules={[{
                    required: true,
                    message: 'Bitte gib eine Uhrzeit an!' }]}>
                {timePickerComp}
              </FormItem>

              <FormItem>
                <Button type="submit">Spieltermin eintragen</Button>
              </FormItem>


              {/*man kann nur seine eigenen Forderungen loeschen */}
              {!!existentForderung && !!existentForderung.id > 0 && !!isForderung &&
              <FormItem>
                <Button type="primary" variant="danger" onClick={this.deleteForderung}>
                  <FontAwesomeIcon icon={faTrashAlt} style={{display: 'inline-block', verticalAlign: '-0.095em'}}/> Löschen
                </Button>
              </FormItem>
              }


              <p style={{color: 'red'}}>
                {validierungsmeldung}
                <br/>
              </p>
              {!!this.state.successMsg &&
              <SuccessMessage message={this.state.successMsg}/>
              }

              {!!this.state.errorMsg &&
              <ErrorMessage message={this.state.errorMsg}/>
              }

              {!!this.state.warningMsg &&
              <WarningMessage message={this.state.warningMsg}/>
              }
            </Form>

          </div>
          }
        </React.Fragment>
    )
  }

  validateSpieltermin = (spieltermin) => {
    var validationResult = {
      validateStatus: 'success',
      errorMsg: null
    };

    if(!spieltermin) {
      validationResult = {
        validateStatus: 'error',
        errorMsg: `Bitte gib einen Spieltermin an.`
      };
    }

    this.setState({
      spieltermin: validationResult
    });

    return validationResult;
  }

}
// end::ForderungSpieltermin[]

// Specifies the default values for props:
//so muss in der 'zentralen' Forderungs-Komponente das Property isForderung nicht gesetzt werden
ForderungSpieltermin.defaultProps = {
  isForderung: true
};


export default ForderungSpieltermin;