import React, {Component} from 'react';
import {authService} from '../../_services/authService';
import './Login.css';
import {Link} from 'react-router-dom';
import {ACCESS_TOKEN} from '../../constants';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

import 'antd/dist/antd.css';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, Icon, notification } from 'antd';
import {Form, Input, notification} from 'antd';

//import { Icon } from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

const FormItem = Form.Item;





class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMsg: '',
      errorMsg: ''
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {

     const loginRequest = Object.assign({}, values);
    authService.login(loginRequest)
    .then(response => {
      localStorage.setItem(ACCESS_TOKEN, response.accessToken);
      this.props.onLogin();
    }).catch(error => {
      if(error.status === 401) {
        notification.error({
          message: 'Vereinsrangliste',
          description: 'Deine Email-Adresse oder dein Passwort stimmen nicht überein. Versuche es Bitte noch einmal!'
        });

        this.setState({
          errorMsg: "Deine Email-Adresse oder dein Passwort stimmen nicht überein. Versuche es Bitte noch einmal!"
        });
        
      } else {
        notification.error({
          message: 'Vereinsrangliste',
          description: error.message || 'Sorry! Etwas ist schief gelaufen. Versuche es Bitte noch einmal!'
        });

        this.setState({
          errorMsg: "Sorry! Etwas ist schief gelaufen. Versuche es Bitte noch einmal!"
        });
      }
    });
  }

  render() {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 16,
      },
    };
    return (
        <div className="form-container">
          <h1 className="page-title">Login</h1>
          <div className="login-content">

            <Form {...layout} onFinish={this.handleSubmit}>

              <FormItem
                  name="usernameOrEmail"
                  label="E-Mail"
                  rules={[{
                    required: true,
                    message: 'Bitte gib deine E-Mail-Adresse an!' }]}>
                <Input type="text"
                       prefix={<UserOutlined className="site-form-item-icon" />}
                       size="large"
                       name="usernameOrEmail"
                       autoComplete="on"
                       placeholder="Email"/>

              </FormItem>

              <FormItem
                  name="password"
                  label="Passwort"
                  rules={[{
                    required: true,
                    message: 'Bitte gib dein Passwort an!' }]}>
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    size="large"
                    name="password"
                    type="password"
                    placeholder="Password"  />
              </FormItem>

              {!!this.state.successMsg &&
              <SuccessMessage message={this.state.successMsg}/>
              }

              {!!this.state.errorMsg &&
              <ErrorMessage message={this.state.errorMsg}/>
              }
              
              <FormItem {...tailLayout} >
                <Button className="myLargeBtn" type="submit" >Login</Button>
                <br/>
                <Link to={{pathname:"/password/forgotten", prevLocation: this.props.location.state}}>Passwort vergessen?</Link>
                <br/>
                Neu bei der Vereinsrangliste?
                <br/>
                <Link to={{pathname:"/signup/new", prevLocation: this.props.location.state}}>Dann registriere dich jetzt!</Link>
              </FormItem>
            </Form>
          </div>
        </div>
    );
  }
}


export default Login;