import './Forderung.css';
import 'antd/dist/antd.css';
import 'moment/locale/de';
import {setErrorState} from "../../util/ErrorUtil";
import ForderungMixed from "./ForderungMixed";
import {ranglisteMixedEintragService} from "../../_services/ranglisteMixedEintragService";
import RanglistenMixedEintragDto from "../../dto/match/RanglistenMixedEintragDto";
import {authService} from "../../_services/authService";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class ForderungFromRanglisteMixed extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      herausgeforderterRanglistenEintrag: null,
      fordernderRanglistenEintrag: null,
    };

    this.loadHerausgeforderterNutzerAndFordernderNutzer = this.loadHerausgeforderterNutzerAndFordernderNutzer.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;

    this.loadHerausgeforderterNutzerAndFordernderNutzer();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //der HerausgeforderterS wird aus den Props uebergeben und
  //der Fordernder ist der angemeldete RanglistenEintragDto
  loadHerausgeforderterNutzerAndFordernderNutzer(){

    //Zuerst den anderen RanglistenEintragDto laden
    const herausgeforderterRanglistenEintragId = this.props.match.params.mixedpaarungId;

    ranglisteMixedEintragService.getById(herausgeforderterRanglistenEintragId)
    .then(response => {
      if (this._isMounted) {
        const mapped = new RanglistenMixedEintragDto(response);
        this.setState({
          herausgeforderterRanglistenEintrag: mapped
        });
        //dann den eingeloggten RanglistenEintragDto ladem
        //hier wird dann danach auch die ForderungDto geladen und dazu muessen ja schon beide RanglistenEintragDto da sein.
        //daher konnte ich die 3 Methoden nicht einfach in der DidMount-Methode nacheinander aufrufen, sondern muss das immer
        //in dem then tun
        this.loadUser();
      }

    })
    .catch(error => {
      console.log(error);
      setErrorState(error, this);
    });
  }

  loadUser() {
    authService.getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({user: response});

        ranglisteMixedEintragService.getRanglisteMixedEintragByNutzerId(response.id)
        .then(response2 => {
          if (this._isMounted) {
            const mapped = new RanglistenMixedEintragDto(response2);
            this.setState({
              fordernderRanglistenEintrag: mapped
            });
          }
        })
        .catch(error => {
          console.log(error);
          setErrorState(error, this);
        });

      }
    })
    .catch(error => {
      console.log(error);
      setErrorState(error, this);
    });
  }
  
  render() {
    
    const { herausgeforderterRanglistenEintrag, fordernderRanglistenEintrag} = this.state;

    return (
        <div>
          {!!herausgeforderterRanglistenEintrag && !!fordernderRanglistenEintrag &&
            <ForderungMixed
                fordernderRanglistenEintrag={fordernderRanglistenEintrag}
                herausgeforderterRanglistenEintrag={herausgeforderterRanglistenEintrag}
                isForderung={true}
                isForderungEdit={false}/>
          }
        </div>
    )
  }


}
// end::app[]

export default ForderungFromRanglisteMixed;