import React, {Component} from 'react';
import {confirmSignup} from '../../_services/authService';
import {setErrorState} from '../../util/ErrorUtil';
//import { Form, Input, Button, notification } from 'antd';
import {notification} from 'antd';

class SignupConfirm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            regtoken: {
                value: ''
            },
            //ob die Registierung erfolgreich war
            registrationStatus: '',
            error: null
        }
    }

    componentDidMount() {
        this.doConfirmationPostRequest();
    }

    doConfirmationPostRequest() {
        //holt das Token aus dem Request-Parameter der URL raus
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')
        
        const signUpConfirmRequest = {
            registrationtoken: token
        };
        confirmSignup(signUpConfirmRequest)
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Du hast dich erfolgreich registriert.",
            });

            this.setState({
                registrationStatus: 'success'
            });
            //this.props.history.push("/login")

        }).catch(error => {
            setErrorState(error, this);
            this.setState({
                registrationStatus: 'error',
                error: error
            });
        });
    }

    render() {
        const { registrationStatus } = this.state;
        
        let content = <div></div>;
        
        if(registrationStatus === 'success'){
            content = <div>
                        <h1 className="page-title">Registrierung erfolgreich</h1>
                        <p>
                            Herzlichen Glückwunsch! Du hast deine E-Mail-Adresse bestätigt und somit deine Registrierung abgeschlossen.
                            <br/>
                            Melde dich an, um fortzufahren.
                            <br/>
                            <br/>
                            <a className="btn btn-primary myLargeBtn" href="/login">Login</a>
                        </p>
                    </div>
        } else if(registrationStatus === 'error'){
            content = <div>
                <h1 className="page-title">Fehler bei der Registrierung</h1>
                <p>
                    Die Registrierung konnte nicht abgeschlossen werden.
                    <br/>
                    <b>{this.state.error.message}</b>
                </p>
            </div> 
        }
        
        return (
            <div className="content-container">
                
                {content}
            </div>
        );
    }
    
}

export default SignupConfirm;