import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const gespieltService = {
  getGespieltById,
  saveGespielt,
  editGespielt,
  getLetztenXSorted,
  getPagedSorted,
  getGespieltForNutzer,
  getAllByHerausgeforderter,
  getAllByFordernder
};


export function getGespieltById(gespieltId)
{
  return request({
    url: API_BASE_URL + "/gespielt/getById/"  + gespieltId,
    method: 'GET'
  });
}

//hier wird die ForderungDto zu dem GespieltDto-Request gesucht.
export function saveGespielt(gespieltRequest) {
  return request({
    url: API_BASE_URL + "/gespielt/save",
    method: 'POST',
    body: JSON.stringify(gespieltRequest)
  });
}

//hier wird wirklich nur das GespieltDto-Objekt geändert. Ohne die ForderungDto zu beachten.
export function editGespielt(gespieltRequest) {
  return request({
    url: API_BASE_URL + "/gespielt/edit",
    method: 'POST',
    body: JSON.stringify(gespieltRequest)
  });
}

export function getLetztenXSorted(count)
{
  return request({
    url: API_BASE_URL + "/gespielt/list/last/" + count,
    method: 'GET'
  });
}

export function getPagedSorted(pageNumber, pageSize)
{
  return request({
    url: API_BASE_URL + "/gespielt/pagedlist?page=" + pageNumber + "&size=" + pageSize,
    method: 'GET'
  });
}

export function getGespieltForNutzer(nutzerId)
{
  return request({
    url: API_BASE_URL + "/gespielt/getByNutzer/" + nutzerId,
    method: 'GET'
  });
}

export function getAllByHerausgeforderter(nutzerId)
{
  return request({
    url: API_BASE_URL + "/gespielt/getAllByHerausgeforderter/" + nutzerId,
    method: 'GET'
  });
}

export function getAllByFordernder(nutzerId)
{
  return request({
    url: API_BASE_URL + "/gespielt/getAllByFordernder/" + nutzerId,
    method: 'GET'
  });
}

//loescht das Match
export function deleteGespieltById(gespieltId)
{
  return request({
    url: API_BASE_URL + "/gespielt/delete/"  + gespieltId,
    method: 'DELETE'
  });
}