import React from 'react'
import RanglisteMixed from "./RanglisteMixed";
import SpieltermineMixed from "../../Spieltermine/SpieltermineMixed";
import GespieltMixed from "../../Gespielt/GespieltMixed";
import {authService} from "../../_services/authService";


class RanglisteHomeMixed extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: {},
      userloaded: null,
      gespielt: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadUser();
    }
  }


  loadUser(){
    authService.getCurrentUser()
    .then(response => {
      this.setState({
        loggedInUser: response,
        userloaded: true
      });
    })
    .catch(error => {
      if (this._isMounted) {
        this.setState({
          hasError: error,
          loggedInUser: {},
          userloaded: false
        });
      }
    });
  }

  render()
  {
    const { loggedInUser, userloaded } = this.state;

    return (
        <div>
          
          {userloaded !== null && !loggedInUser.id &&
          <div className='print_hide'>
            <p style={{marginTop: 20}}>Neu bei der Vereinsrangliste? <a href="/signup/new">Dann registriere dich jetzt!</a></p>
  
            <p>Eine Anleitung und die Spielregeln findest du  <a href="/spielregeln">hier</a>.</p>
          </div>
          }

          {userloaded !== null && loggedInUser.id &&
          <div className='print_hide'>
            <p style={{marginTop: 20}}><b>NEU</b>: Schau dir deine Match-Statistik im Profil-Bereich an: <a href="/user/matchprofil">Meine Matches</a></p>
          </div>
          }
          
          <div id="printBox">
            <RanglisteMixed currentUser={this.props.currentUser} />
            <SpieltermineMixed currentUser={this.props.currentUser} adminView={false}/>
          </div>
          
          <div className='print_hide'>
            <GespieltMixed />
          </div>
          
        </div>
    );
  }
}
export default RanglisteHomeMixed;