import {setErrorState} from "../../util/ErrorUtil";
import Rangliste from "../component/Ranglistel";
import RanglistenEinzelEintragDto from "../../dto/match/RanglistenEinzelEintragDto";
import {
  getEinzelEintragByNutzerId,
  getRanglisteEinzelEintraegeSorted
} from "../../_services/ranglisteEinzelEintragService";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class RanglisteEinzel extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      ranglistenEintraege: [],
      currentRanglistenEintrag: {},
      eintragLoaded: false
    }
  }

    loadData()
    {
      this.loadRanglistenEintraege();
      this.loadMeinRanglistenEintragEinzel();
    }

    loadRanglistenEintraege(){
      getRanglisteEinzelEintraegeSorted()
          .then(response => {
            if (this._isMounted) {
              const mapped = response.map((element) => new RanglistenEinzelEintragDto(element));
              this.setState({
                ranglistenEintraege: mapped
              });
            }
          })
          .catch(error => {
            console.log(error);
            setErrorState(error, this);
          });
    }

    loadMeinRanglistenEintragEinzel(){
      if(this.props.currentUser != null){
        getEinzelEintragByNutzerId(this.props.currentUser.id)
            .then(response => {
              if (this._isMounted) {
                const mapped = new RanglistenEinzelEintragDto(response);
                this.setState({
                  currentRanglistenEintrag: mapped,
                  eintragLoaded: true
                });
              }
            })
            .catch(error => {
              if(!error instanceof SyntaxError){
                setErrorState(error, this);
                this.setState({
                  eintragLoaded: true
                });
                //SyntaxError:  JSON.parse: unexpected end of data at line 1 column 1 of the JSON data
                //immer wenn kein Ranglsiteneintrag fuer dein eingeloggten Nutzer gefunden werden kann.
                //Also immer wenn man nicht in der Einzel- oder Mixed-Rangliste mitspielt, sich aber eingelgogged hat.
              }

            });
      }
    }

    componentDidMount()
    {
      this._isMounted = true;

      this.loadData();
    }

    componentWillUnmount()
    {
      this._isMounted = false;
    }

    //braucht man fuer das Mischen der RanglisteEinzel.
    //und dem ein/ausloggen von Nutzern
    componentDidUpdate(prevProps, prevState)
    {
      if (prevProps.currentUser !== this.props.currentUser) {
        this.loadData();
      }

      if (prevProps.refreshCount !== this.props.refreshCount) {
        this.loadData();
      }
    }


  
  render() {

    var loggedInNutzer = this.props.currentUser;

    return (
        <div>
            <Rangliste
                heading={'Rangliste'}
                ranglistenEintraege={this.state.ranglistenEintraege}
                loggedInNutzer={loggedInNutzer}
                currentRanglistenEintrag={this.state.currentRanglistenEintrag}
                editForderungUrl={'/rangliste/forderung/'}/>
        </div>
    )
  }
}
// end::app[]

export default RanglisteEinzel;