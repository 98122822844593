import './Rangliste.css';
import './Rangliste_print.css';

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
class Rangliste extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      links: {},
      loggedInNutzer: null,
     };

  }
  
  // loadData(){
  //   //this.loadLoggedInNutzer();
  // }

  componentDidMount() {
    this._isMounted = true;

    //this.loadData();
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  //braucht man fuer das Mischen der RanglisteEinzel.
  //und dem ein/ausloggen von Nutzern
  // componentDidUpdate(prevProps, prevState){
  //   if(prevProps.refreshCount!==this.props.refreshCount){
  //     this.loadData();
  //   }
  //
  //   // if(prevProps.currentRanglistenEintrag!==this.props.currentRanglistenEintrag){
  //   //   this.loadLoggedInNutzer();
  //   // }
  //
  //   if(prevProps.currentMixedpaarung!==this.props.currentMixedpaarung){
  //     this.loadData();
  //   }
  // }


  // loadLoggedInNutzer() {
  //   const currentUser = this.props.currentUser;
  //
  //   if(currentUser !== undefined && currentUser !== null){
  //     // const currentUserId = this.props.currentUser.id;
  //
  //     this.setState({
  //       loggedInNutzer: this.props.currentUser.nutzer
  //     });
  //
  //     // getNutzerByNutzerId(currentUserId)
  //     // .then(response2 => {
  //     //   if (this._isMounted) {
  //     //     this.setState({
  //     //       loggedInNutzer: response2
  //     //     });
  //     //   }
  //     // })
  //     // .catch(error => {
  //     //   this.setState({
  //     //     loggedInNutzer: null
  //     //   });
  //     //   console.log("1");
  //     //   setErrorState(error, this);
  //     // });
  //   }else{
  //     this.setState({
  //       loggedInNutzer: null
  //     });
  //   }
  // }
  
  render() {

    var header = 'Rangliste';

    if(this.props.heading){
      header = this.props.heading;
    }

    return (
        <div>
          <h1>{header}</h1>
              <NutzerTree
                  ranglistenEintraege={this.props.ranglistenEintraege}
                  loggedInNutzer={this.props.loggedInNutzer}
                  currentRanglistenEintrag={this.props.currentRanglistenEintrag}
                  editForderungUrl={this.props.editForderungUrl}/>
        </div>
    )
  }
}
// end::app[]

// tag::NutzerTree[]
class NutzerTree extends React.Component{

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.updateDimensions);
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //um die RanglisteEinzel beim Verkleinern des Fensters von links nach rechts anzuordnen und nicht mehr von oben nach unten.
  updateDimensions = () => {
   
    const mql =  window.matchMedia('(max-width: 800px)');

    if(mql.matches){
      //durch das Setzen des States, wird, sobald die Media-Query greift (oder eben gegriffen hat und jetzt nicht mehr greift), neu gerendert.
      this.setState({ mqlMatches: true });
    }else{
      this.setState({ mqlMatches: false });
    }
  };
  
  getGausscheSummeFor(i){
    var gs = i * (i + 1);
    
    return gs/2;
  }
  
  render() {
    //ob wir in die Smartphone-Darstellung muessen.
    //Dann wird der Baum naemlich von links nach rechts angezeigt
    const mql =  window.matchMedia('(max-width: 800px)');
    const isResponsiveView = mql.matches;
    
    var gesamt = this.props.ranglistenEintraege;
    const numrows = this.props.ranglistenEintraege.length;
    const currentRanglistenEintrag = this.props.currentRanglistenEintrag;
    
    var rows = [];
    var dreieckszahlen = [];
    var done = numrows <= 0;

    for (var n = 1; done === false; n++) {
      let gausschesumme = this.getGausscheSummeFor(n);
      dreieckszahlen.push(gausschesumme);
      if(gausschesumme >= numrows){
        done = true;
      }
    }
    
    var i = 0;
    var incr = 0;
    
    //Wieviele Elemente die letzte Zeile hat (bzw. haette, auch obwohl sie nicht voll ist)
    var lastRowElementCount = 0;
    while (i < numrows) {
      
      var start = i;
      var end = i + incr + 1; //das Ende ist immer um eins weiter
      lastRowElementCount = end - start;
      
      var sublist = gesamt.slice(start, end);
      
      const employees = sublist.map(ranglistenEintrag =>
          <Nutzer
              key={ranglistenEintrag.nachname + '_' + ranglistenEintrag.id}
              ranglistenEintrag={ranglistenEintrag}
              loggedInNutzer={this.props.loggedInNutzer}
              currentRanglistenEintrag={currentRanglistenEintrag}
              gausscheSummen={dreieckszahlen}
              isResponsiveView={isResponsiveView}
              editForderungUrl={this.props.editForderungUrl}/>
      );
      
      //Falls die letzte Zeile des Baumes nicht ganz voll wird,
      //muessen leere Divs eingefuegt werden, da bei zu wenigen Divs die ganzen Divs nach links
      if(end > numrows){
        for (var j = numrows + 1; j <= end; j++) {
          employees.push(<DummyNutzer key={j}/>);
        }
      }
      
      rows.push(<div className='outer' key={end}>{employees}</div>);
        
      incr = incr + 1;
      i = i + incr;
    }

    var reverse = [];
    //dann wird der Baum von rechts nach links angezeigt
    if(isResponsiveView){
      
      for (var x = rows.length - 1; x >= 0; x--) {
        reverse.push(rows[x]);
      }

      rows = reverse;
    }


    
    //Ein RanglistenEintragDto hat die Breite 200
     var widthOfNutzer = 160;

    const minWidth = lastRowElementCount * widthOfNutzer;
    var minWidthPixel = minWidth + 'px';

    var styleObj = {
      minWidth: minWidthPixel
    }
    
    //dann wird der Baum von rechts nach links angezeigt
    if(mql.matches){
      styleObj = {
      }
    }


    
    return (
        <div className="outer" style={styleObj}>
          {rows}
        </div>
    )
  }
}
// end::employee-list[]

// tag::RanglistenEintragDto[]
class Nutzer extends React.Component{
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);  
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleClick() {    
    const herausgeforderterRanglistenEintragId = this.props.ranglistenEintrag.id;

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = this.props.editForderungUrl + herausgeforderterRanglistenEintragId;

  }
    
  //liefert zurueck, in welcher Zeile ich stehe
  //z.B. 5 für die 5. Zeile
  getMyRowCount(gausscheSummen, meineRanglistenPosition){
   
    for (var i = 0; i < gausscheSummen.length; i++) {
      if(meineRanglistenPosition <= gausscheSummen[i]){
        return i + 1;
      }
    }
    //dann ist iwas schief gelaufen
    return 0;
  }


  
  render() {
    const ranglistenEintrag = this.props.ranglistenEintrag;
    const loggedInNutzer = this.props.loggedInNutzer;
    const currentRanglistenEintrag = this.props.currentRanglistenEintrag;
    const gausscheSummen = this.props.gausscheSummen;
    let canClick = false;
    
    let className = 'inner';

    if(currentRanglistenEintrag && currentRanglistenEintrag.id > 0){
      if (currentRanglistenEintrag) {
        if (ranglistenEintrag.containsNutzer(loggedInNutzer)) {
          className += ' me';
        }
      }



      var meineRanglistenPosition = currentRanglistenEintrag.position;

      const myRow = this.getMyRowCount(gausscheSummen, meineRanglistenPosition);
      const maxRoderndeRanglistePosition = meineRanglistenPosition - 1;   //die hoechste Ranglistenposition, die ich fordner darf (also der Schlechteste, der Letzte)
      const mminRoderndeRanglistePosition = meineRanglistenPosition - myRow + 1;  //die niedrigste Ranglistenposition, die ich fordner darf (also der Beste, der Erste)



      if(this.props.ranglistenEintrag.position >= mminRoderndeRanglistePosition && this.props.ranglistenEintrag.position <= maxRoderndeRanglistePosition){
        className += ' possible_to_forder';
        canClick = true;
      }

    }



    let text = this.props.ranglistenEintrag.getLabelElement(this.props.isResponsiveView);


    return (
        <div className={className} onClick={() => canClick ? this.handleClick() : () => false }>
          {text}
        </div>
    )
  }
}
// end::RanglistenEintragDto[]

// tag::DummyNutzer[]
//wird benoetigt, falls es zu wenige RanglistenEintragDto gibt und so die letzte Zeile nicht ganz voll wird.
//Da wuerden die Divs sonst nach links verrutschen.
//sind dann einfach leere Divs, die man an der Oberflaeche nicht sieht, die aber dafuer sorgen, dass die
//letzte Zeile auch richtig angezeigt wird
class DummyNutzer extends React.Component{
  render() {
    return (
        <div className="dummy">
        </div>
    )
  }
}
// end::DummyNutzer[]

export default Rangliste;