import React, {Component} from 'react';
import './Signup.css';

//import { Form, Input, Button, notification } from 'antd';


class SignupInfoConfirm extends Component {

    render() {

        return (
            <div className="signup-container">
                <h1 className="page-title">Bestätige deine E-Mail-Adresse</h1>
                <div className="signup-content">
                   Um deine Registrierung abzuschließen, überprüfe dein E-Mail-Konto und bestätige deine E-Mail-Adresse.
                </div>
            </div>
        );
    }



}

export default SignupInfoConfirm;