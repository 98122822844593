import React from 'react'
import MeineForderungenMixed from "./MeineForderungenMixed";
import MeineGespieltenMixed from "./MeineGespieltenMixed";
import MeineMatchStatistikMixed from "./MeineMatchStatistikMixed";
import {ranglisteMixedEintragService} from "../../../_services/ranglisteMixedEintragService";
import RanglistenMixedEintragDto from "../../../dto/match/RanglistenMixedEintragDto";
import {setErrorState} from "../../../util/ErrorUtil";
import RegisterMixedProfil from "./RegisterMixedProfil";

class MatchProfilMixed extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            mixedpaarung: {},
            mixedpaarungLoaded: false
        };

        this.loadMixedPaarung = this.loadMixedPaarung.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadMixedPaarung();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadMixedPaarung(){
        if(this.props.currentUser){
            ranglisteMixedEintragService.getRanglisteMixedEintragByNutzerId(this.props.currentUser.id)
                .then(response2 => {
                    if (this._isMounted) {
                        const mapped = new RanglistenMixedEintragDto(response2);
                        this.setState({
                            mixedpaarung: mapped,
                            mixedpaarungLoaded: true
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setErrorState(error, this);
                    this.setState({
                        mixedpaarungLoaded: true
                    });
                });
        }
    }


  render()
  {
      const { mixedpaarungLoaded, mixedpaarung } = this.state;

    return (
        <React.Fragment>
            {!!this.props.currentUser && this.props.currentUser.id && mixedpaarung.id > 0 &&
                <div>
                    <MeineMatchStatistikMixed currentUser={this.props.currentUser}/>
                    <br/>
                    <MeineForderungenMixed currentUser={this.props.currentUser}/>
                    <br/>
                    <MeineGespieltenMixed currentUser={this.props.currentUser}/>
                </div>
            }

            {!!mixedpaarungLoaded && (mixedpaarung === undefined || mixedpaarung.id === 0) &&

                <div className="content-container">
                        <h1>
                            Statsitk Mixed-Rangliste
                        </h1>
                        Du bist noch nicht Teil der Mixed-Rangliste.
                        <br/>
                        Suche dir eine/n Partner/in und meldet euch für die Mixed-Rangliste an!

                        <RegisterMixedProfil  currentUser={this.props.currentUser}/>
                    </div>
            }
        </React.Fragment>
    );
  }
}
export default MatchProfilMixed;