import './Forderung.css';

import 'antd/dist/antd.css';
import 'moment/locale/de';
import {setErrorState} from "../../util/ErrorUtil";
import {getForderungById} from "../../_services/forderungService";
import ForderungEinzelDto from "../../dto/match/ForderungEinzelDto";
import ForderungEinzel from "./ForderungEinzel";
import RanglistenEinzelEintragDto from "../../dto/match/RanglistenEinzelEintragDto";
import {authService} from "../../_services/authService";

// tag::vars[]
const React = require('react');
// end::vars[]

// tag::app[]
//fuer das Bearbeiten einer ForderungDto.
//kann sowohl der fordernderRanglistenEintrag als auch der herausgeforderterRanglistenEintrag aufrufen.
class ForderungFromEditEinzel extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      forderung: null,
      loggedInNutzer: null
    };

    this.loadLoggedInNutzer = this.loadLoggedInNutzer.bind(this);
    this.loadHerausgeforderterNutzerAndFordernderNutzer = this.loadHerausgeforderterNutzerAndFordernderNutzer.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;

    this.loadLoggedInNutzer();
    this.loadHerausgeforderterNutzerAndFordernderNutzer();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //den eingeloggten RanglistenEintragDto brauche ich, um festzustellen, ob ich die Fordern- oder Herausfordern-Sicht anzeigen muss.
  //Die unterscheiden sich nur in ein paar Text-Zeilen.
  loadLoggedInNutzer() {
    authService.getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({user: response});

            this.setState({
              user: response,
              loggedInNutzer: response.nutzer
            });

          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }
  
  
  //der Herausgeforderter und Fordernder werden aus der uebergebenen ForderungDto geladen
  loadHerausgeforderterNutzerAndFordernderNutzer(){
    //Zuerst den anderen RanglistenEintragDto laden
    const forderungId = this.props.match.params.forderungId;

    getForderungById(forderungId)
    .then(response => {
      if (this._isMounted) {
        const mapped = new ForderungEinzelDto(response);
        this.setState({
          forderung: mapped,
        });
      }

    })
    .catch(error => {
      console.log(error);
      setErrorState(error, this);
    });
  }


  render() {
    
    const { forderung, loggedInNutzer } = this.state;

    var isForderung = true;

    if(loggedInNutzer && forderung && forderung.id && loggedInNutzer.id === forderung.herausgeforderter.id){
      //Wenn der eingeloggte RanglistenEintragDto, der Herausgeforderte der ForderungDto ist, dann wird die Herausforderungs-Sicht angezeigt
      //( Also nicht: "Fodere XY" sondern "Herausvorderung von XY", ...)
      isForderung = false;
    }

    return (
        <div>
          {!!forderung && !!loggedInNutzer &&
              <ForderungEinzel
                  fordernderRanglistenEintrag={new RanglistenEinzelEintragDto(forderung.fordernder)}
                  herausgeforderterRanglistenEintrag={new RanglistenEinzelEintragDto(forderung.herausgeforderter)}
                  isForderung={isForderung} isForderungEdit={true}/>
          }
        </div>
    )
  }


}
// end::app[]

export default ForderungFromEditEinzel;