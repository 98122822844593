import { authHeader } from '../_helpers';
import {ACCESS_TOKEN} from "../constants";

export const request = (options) => {
    const headers = authHeader();

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
          if(response.status === 401) {
            localStorage.removeItem(ACCESS_TOKEN);
          }
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};
