import React from 'react';
import {setErrorState} from "../util/ErrorUtil";
import {editGespielt, getGespieltById} from "../_services/gespieltService";
import GespieltForm from "./GespieltForm";
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import GespieltEinzelDto from "../dto/match/GespieltEinzelDto";

class GespieltEditEinzel extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            gespielt: {}
        };

        this.loadGespielt = this.loadGespielt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onBack = this.onBack.bind(this);
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadGespielt();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadGespielt() {
        const gespieltId = this.props.match.params.gespieltId;

        getGespieltById(gespieltId)
            .then(response => {
                if (this._isMounted) {
                    const mapped = new GespieltEinzelDto(response);
                    this.setState({gespielt: mapped});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }

    handleSubmit(gespieltRequest)
    {
        //alte Fehlermeldungen zurücksetzen
        this.setState({
            errorMsg: ''
        });

        editGespielt(gespieltRequest)
            .then(response => {
                //Wir brauchen nicht zwei Meldungen.
                //Das hier sind du 'Pop-Up' Meldungen
                // notification.success({
                //   message: 'Vereinsrangliste',
                //   description: "Euer Ergebnis wurde erfolgreich gespeichert.",
                // });

                this.setState({
                    successMsg: "Das Ergebnis wurde erfolgreich gespeichert."
                });

            }).catch(error => {
            setErrorState(error, this);
        });

        this.setState({
            successMsg: "Das Ergebnis wurde erfolgreich gespeichert."
        });
    }

    onBack(){
        this.props.history.push("/verwaltung/spieltermine/list")
    }


    render() {
        const { gespielt } = this.state;

        
        return (
            <div>

                {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                }

                {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                }

                {!!gespielt && !!gespielt.id &&
                    <GespieltForm existentGespielt={gespielt}
                               onHandleSubmit={this.handleSubmit} 
                                  onHandleBack={this.onBack}
                                  isDoppel={false}/>


                }
            </div>
        );
    }
}

export default GespieltEditEinzel;