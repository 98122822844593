import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//um den Datepicker zu lokalisieren
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import 'moment/locale/de';

ReactDOM.render((
    <BrowserRouter>
      {/* ConfigProvider von antd: um den Datepicker zu lokalisieren*/}
      <ConfigProvider locale={deDE}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
