import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Impressum from '../Impressum/Impressum.js'
import Spielregeln from '../Spielregeln/Spielregeln.js'

import PrivateRoute from '../_components/PrivateRoute.js';

import RanglisteEinzel from '../rangliste/einzel/RanglisteEinzel';
//import ForderungDto from '../rangliste/forderung/ForderungDto';
import ForderungFromRanglisteEinzel from '../rangliste/forderung/ForderungFromRanglisteEinzel';

import AllMatchesEinzel from '../Matches/AllMatchesEinzel';
import RanglisteShuffleEinzel from '../verwaltung/rangliste/RanglisteShuffleEinzel';


import UserProfil from '../user/profil/profil.js';

import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import SignupInfoConfirm from '../user/signup/SignupInfoConfirm';
import SignupConfirm from '../user/signup/SignupConfirm';

import ForgottenPassword from '../user/forgottenPassword/ForgottenPassword';
import ResetPassword from '../user/resetPassword/ResetPassword';

import LoadingIndicator from '../common/LoadingIndicator';


import 'antd/dist/antd.css';
import MeineForderungenEinzel from "../user/profil/einzel/MeineForderungenEinzel";
import MatchProfiEinzell from "../user/profil/einzel/MatchProfiEinzell";
import SpieltermineVerwaltung from "../verwaltung/spieltermine/SpieltermineVerwaltung";
import GespieltEditEinzel from "../Gespielt/GespieltEditEinzel";
import RanglisteMixed from "../rangliste/mixed/RanglisteMixed";
import RanglisteHomeMixed from "../rangliste/mixed/RanglisteHomeMixed";
import GespieltEditMixed from "../Gespielt/GespieltEditMixed";
import SpieltermineVerwaltungMixed from "../verwaltung/spieltermine/SpieltermineVerwaltungMixed";
import RanglisteHomeEinzel from "../rangliste/einzel/RanglisteHomeEinzel";
import ForderungFromEditMixed from "../rangliste/forderung/ForderungFromEditMixed";
import ForderungFromEditEinzel from "../rangliste/forderung/ForderungFromEditEinzel";
import ForderungFromRanglisteMixed from "../rangliste/forderung/ForderungFromRanglisteMixed";
import AllMatchesMixed from "../Matches/AllMatchesMixed";
import MatchProfilMixed from "../user/profil/mixed/MatchProfilMixed";
import RanglisteShuffleMixed from "../verwaltung/rangliste/RanglisteShuffleMixed";
import EintragListEinzel from "../verwaltung/ranglistenEintraege/list/EintragListEinzel";
import EintragListMixed from "../verwaltung/ranglistenEintraege/list/EintragListMixed";
import NutzerEintragEinzelNew from "../verwaltung/ranglistenEintraege/new/NutzerEintragEinzelNew";
import NutzerEintragMixedNew from "../verwaltung/ranglistenEintraege/new/NutzerEintragMixedNew";


// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
  }


  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }

    return (
        <div className="container-fluid">
          <Switch>
            <Route exact path='/' render={(props) => <RanglisteHomeEinzel currentUser={this.props.currentUser} {...props} />}/>
            <Route exact path='/impressum' component={Impressum}/>
            <Route exact path='/spielregeln' component={Spielregeln}/>
            
            
            <Route path="/rangliste/view" render={(props) => <RanglisteEinzel currentUser={this.props.currentUser} {...props} />}></Route>
            <Route path='/rangliste/home' render={(props) => <RanglisteHomeEinzel currentUser={this.props.currentUser} {...props} />}/>

            <Route path="/rangliste-mixed/view" render={(props) => <RanglisteMixed currentUser={this.props.currentUser} {...props} />}></Route>
            <Route path="/rangliste-mixed/home" render={(props) => <RanglisteHomeMixed currentUser={this.props.currentUser} {...props} />}></Route>

            <Route path='/rangliste/matches/view' render={(props) => <AllMatchesEinzel currentUser={this.props.currentUser} {...props} />}/>
            <Route path='/rangliste-mixed/matches/view' render={(props) => <AllMatchesMixed currentUser={this.props.currentUser} {...props} />}/>


            <PrivateRoute path="/rangliste/forderung/:herausgeforderterRanglistenEintragId" component={ForderungFromRanglisteEinzel}></PrivateRoute>
            <PrivateRoute path="/rangliste/forderungbyid/:forderungId" component={ForderungFromEditEinzel}></PrivateRoute>
            <PrivateRoute path="/rangliste/gespielt/:gespieltId" component={GespieltEditEinzel}></PrivateRoute>

            <PrivateRoute path="/rangliste-mixed/forderung/:mixedpaarungId" component={ForderungFromRanglisteMixed}></PrivateRoute>
            <PrivateRoute path="/rangliste-mixed/forderungbyid/:forderungId" component={ForderungFromEditMixed}></PrivateRoute>
            <PrivateRoute path="/rangliste-mixed/gespielt/:gespieltId" component={GespieltEditMixed}></PrivateRoute>

            <PrivateRoute path='/user/profil' component={UserProfil}/>
            {/*<PrivateRoute path='/user/meineforderungen' component={MeineForderungenEinzel}/>*/}
            <PrivateRoute path='/user/meineforderungen' component={() => <MeineForderungenEinzel currentUser={this.props.currentUser} />}/>
            
            
            {/*<PrivateRoute path='/user/matchprofil' component={MatchProfiEinzell}/>*/}
            {/*<PrivateRoute path="/user/matchprofil" render={(props) => <MatchProfiEinzell currentUser={this.props.currentUser} {...props} />}></PrivateRoute>*/}
            {/*<PrivateRoute path="/user/matchprofil" render={MyMatchProfil} />*/}
            {/*<PrivateRoute path='/user/matchprofil' component={<MatchProfiEinzell currentUser={this.props.currentUser}/>}/>*/}

            <PrivateRoute path='/user/matchprofil' component={() => <MatchProfiEinzell currentUser={this.props.currentUser} />}/>
            <PrivateRoute path='/user/matchprofil-mixed' component={() => <MatchProfilMixed currentUser={this.props.currentUser} />}/>

            <PrivateRoute path="/verwaltung/nutzer/list" component={EintragListEinzel}></PrivateRoute>
            <PrivateRoute path="/verwaltung/ranglisteneintrag/new" component={NutzerEintragEinzelNew}></PrivateRoute>
            <PrivateRoute path="/verwaltung/spieltermine/list"  component={() => <SpieltermineVerwaltung currentUser={this.props.currentUser} />} />
            <PrivateRoute path='/verwaltung/rangliste/mix' component={() => <RanglisteShuffleEinzel currentUser={this.props.currentUser} />}/>


            <PrivateRoute path="/verwaltung-mixed/nutzer/list" component={EintragListMixed}></PrivateRoute>
            <PrivateRoute path="/verwaltung-mixed/ranglisteneintrag/new" component={NutzerEintragMixedNew}></PrivateRoute>
            <PrivateRoute path="/verwaltung-mixed/spieltermine/list"  component={() => <SpieltermineVerwaltungMixed currentUser={this.props.currentUser} />} />
            <PrivateRoute path='/verwaltung-mixed/rangliste/mix' component={() => <RanglisteShuffleMixed currentUser={this.props.currentUser} />}/>

            <Route path="/login"
                   render={(props) => <Login onLogin={this.props.onLogin} {...props} />}></Route>

            <Route path="/signup/new"
                   render={(props) => <Signup onSignup={this.props.onSignup} {...props} />}></Route>
            {/*Das hier ist die Seite, auf die man nach dem Signup kommt.*/}
            <Route path="/signup/infoconfirm"
                   render={(props) => <SignupInfoConfirm {...props} />}></Route>
            {/*Das hier ist die Seite, auf die man durch den Link in der E-Mail kommt.*/}
            <Route path="/signup/confirm"
                   render={(props) => <SignupConfirm {...props} />}></Route>

            <Route path="/password/forgotten"
                   render={(props) => <ForgottenPassword {...props} />}></Route>
            <Route path="/password/reset"
                   render={(props) => <ResetPassword {...props} />}></Route>


          </Switch>
        </div>
    );
  }
}
export default Main;