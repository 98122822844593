import React, {Component} from 'react';
import {authService, checkEmailAvailability} from '../../../_services/authService';
import {setErrorState} from '../../../util/ErrorUtil';

import {EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, USERNAME_MAX_LENGTH} from '../../../constants';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import {Checkbox, Form, Input, notification} from 'antd';

const FormItem = Form.Item;

class NutzerEintragNew extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            disableSubmitButton: false,
            vorname: {
                value: ''
            },
            nachname: {
                value: ''
            },
            telefonnummer: {
                value: ''
            },
            email: {
                value: ''
            },
            spieltEinzelRangliste: {
                value: false
            }
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }
    
    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleSubmit(event) {
        //event.preventDefault();
        
        this.setState({
            disableSubmitButton: true
        });

        const signupRequest = {
            email: this.state.email.value,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            telefonnummer: this.state.telefonnummer.value,
            createEinzelpieler: this.state.spieltEinzelRangliste.value
        };
        authService.createWithNewInactiveNutzer(signupRequest)
        .then(response => {
            notification.success({
                message: 'Vereinsrangliste',
                description: "Der Nutzer/Nutzer wurde erfolgreich erstellt!",
            });

            this.setState({
                disableSubmitButton: false
            });

            this.props.onAfterSave();
            
        }).catch(error => {
            setErrorState(error, this);
            this.setState({
                disableSubmitButton: false
            });
        });
    }

    isFormInvalid() {
        return !(this.state.vorname.validateStatus === 'success' &&
            this.state.nachname.validateStatus === 'success' &&
            this.state.telefonnummer.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success'
        );
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const inputName = target.name;
        const checked = target.checked;

        this.setState({
            [inputName] : {
                value: checked
            }
        });
    }

    render() {
        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

    
        let header = 'Neuen ' + this.props.headerObject + ' anlegen';
        // if(nutzer && nutzer.id > 0){
        //     header = "RanglistenEintragDto bearbeiten";
        // }
        
        return (
        <div className="signup-container">
            <h1 className="page-title">{header}</h1>
            <div className="signup-content">
                <Form {...layout} onFinish={this.handleSubmit} className="signup-form">
                    <FormItem
                        label="Vorname"
                        name="vorname"
                        rules={[{
                            required: true,
                            message: 'Bitte gib den Vornamen an' }]}
                        hasFeedback
                        validateStatus={this.state.vorname.validateStatus}
                        help={this.state.vorname.errorMsg}>
                        <Input
                            size="large"
                            name="vorname"
                            autoComplete="on"
                            placeholder="Der Vorname"
                            value={this.state.vorname.value}
                            onChange={(event) => this.handleInputChange(event, this.validateVorname)} />
                    </FormItem>
                    <FormItem label="Nachname"
                              name="nachname"
                              rules={[{
                                  required: true,
                                  message: 'Bitte gib den Nachnamen an' }]}
                              hasFeedback
                              validateStatus={this.state.nachname.validateStatus}
                              help={this.state.nachname.errorMsg}>
                        <Input
                            size="large"
                            name="nachname"
                            autoComplete="on"
                            placeholder="Der Nachname"
                            value={this.state.nachname.value}
                            onChange={(event) => this.handleInputChange(event, this.validateNachname)}/>
                    </FormItem>
                    <FormItem label="Telefonnummer"
                              name="telefonnummer"
                              rules={[{
                                  required: true,
                                  message: 'Bitte gib die Telefonnummer an' }]}
                              hasFeedback
                              validateStatus={this.state.telefonnummer.validateStatus}
                              help={this.state.telefonnummer.errorMsg}>
                        <Input
                            size="large"
                            name="telefonnummer"
                            autoComplete="on"
                            placeholder="Die Telefonnummer"
                            value={this.state.telefonnummer.value}
                            onChange={(event) => this.handleInputChange(event, this.validateTelefonnummer)}/>
                    </FormItem>
                    <FormItem
                        label="E-Mail"
                        name="email"
                        rules={[{
                            required: true,
                            message: 'Bitte gib die E-Mail-Adresse an' }]}
                        hasFeedback
                        validateStatus={this.state.email.validateStatus}
                        help={this.state.email.errorMsg}>
                        <Input
                            size="large"
                            name="email"
                            type="email"
                            autoComplete="on"
                            placeholder="Die Email-Adresse"
                            value={this.state.email.value}
                            onBlur={this.validateEmailAvailability}
                            onChange={(event) => this.handleInputChange(event, this.validateEmail)} />
                    </FormItem>

                    <FormItem className={"verticalAlignCeckbox"}
                              name="spieltEinzelRangliste"
                              valuePropName="checked"
                              initialValue={this.props.spieltEinzelRanglisteDefaultValue}
                              onChange={(event) => this.handleCheckboxChange(event)}>
                        <Checkbox name="spieltEinzelRangliste">für die Einzel-Rangliste eintragen</Checkbox>
                    </FormItem>

                    <FormItem wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="submit"
                                size="large"
                                className="signup-form-button"
                                disabled={this.isFormInvalid() || this.state.disableSubmitButton}>{this.props.headerObject + ' erstellen'}</Button>
                        <Button type="submit"
                                size="large"
                                className="signup-form-button"
                                onClick={() => { this.props.onCancel() }}>Abbrechen</Button>
                    </FormItem>
                </Form>
            </div>
        </div>

        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib eine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    validateVorname = (vorname) => {
        if(!vorname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib einen Vornamen an. Die Angabe des Vornamens ist pflicht.`
            }
        } else if (vorname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Vorname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateNachname = (nachname) => {
        if(!nachname) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib einen Nachnamen an. Die Angabe des Nachnamens ist pflicht.`
            }
        } else if (nachname.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Nachname ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }

    validateTelefonnummer = (telefonnummer) => {
        if(!telefonnummer) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib eine Telefonnummer an. Die Angabe einer Telefonnummer ist pflicht, damit dich andere Nutzer kontaktieren können.`
            }
        } else if (telefonnummer.length > PHONE_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der Telefonnummer ist zu lang. Es sind maximal ${PHONE_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }


    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
        .then(response => {
            if(response.available) {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            } else {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                    }
                });
            }
        }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }

}

export default NutzerEintragNew;