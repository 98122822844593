import {ACCESS_TOKEN, API_BASE_URL} from '../constants';
import {request} from '../util/APIUtils';


export const authService = {
  login,
  signup,
  confirmSignup,
  getCurrentUser,
  getCurrentNutzer,
  checkEmailAvailability,
  forgottenPassword,
  resetPassword,
  createWithNewInactiveNutzer
};


export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export function confirmSignup(signupRequest)
{
  return request({
    url: API_BASE_URL + "/auth/signup/confirm",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + "/nutzer/checkEmailAvailability?email=" + email,
    method: 'GET'
  });
}

//Nur Id und Email sind gefuellt
export function getCurrentUser() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  
  return request({
    url: API_BASE_URL + "/nutzer/me",
    method: 'GET'
  });
}

//hier sind noch alle Daten des Nutzers gefuellt
export function getCurrentNutzer() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/nutzer/loggedinNutzer",
    method: 'GET'
  });
}

export function forgottenPassword(emailRequest) {
  return request({
    url: API_BASE_URL + "/auth/password/forgotten",
    method: 'POST',
    body: JSON.stringify(emailRequest)
  });
}

export function resetPassword(resetPassRequest) {
  return request({
    url: API_BASE_URL + "/auth/password/save",
    method: 'POST',
    body: JSON.stringify(resetPassRequest)
  });
}

export function createWithNewInactiveNutzer(nutzerNutzerRequest) {
  return request({
    url: API_BASE_URL + "/auth/createInactiveNutzer",
    method: 'POST',
    body: JSON.stringify(nutzerNutzerRequest)
  });
}

