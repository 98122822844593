import React from 'react';

import InfiniteScroll from "react-infinite-scroll-component";
import GespieltAll from "./component/GespieltAll";
import {setErrorState} from "../util/ErrorUtil";
import {gespieltMixedService} from "../_services/gespieltMixedService";
import GespieltDoppelDto from "../dto/match/GespieltDoppelDto";

class GespieltAllMixed extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            gespielt: [],
            hasMore: true,
            pageSize: 10,
            actualPage: -1
        };

        this.loadGespielt = this.loadGespielt.bind(this);
        this.reloadGespielt = this.reloadGespielt.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);

        this.editGespielt = this.editGespielt.bind(this);
        this.deleteGespielt = this.deleteGespielt.bind(this);
    }


    loadGespielt() {
        this.fetchMoreData();
    }


    reloadGespielt() {
        this.setState({
            gespielt: [],
            hasMore: true,
            pageSize: 10,
            actualPage: -1
        })

        this.fetchData(-1, this.state.pageSize);
    }

    fetchMoreData() {
        this.fetchData(this.state.actualPage, this.state.pageSize);
    }

    fetchData(actualPage, pageSize) {
        //erste Seite = 0
        gespieltMixedService.getPagedSorted(actualPage, pageSize)
            .then(response => {
                if (this._isMounted) {
                    const mapped = response.content.map((element) => new GespieltDoppelDto(element));
                    this.setState({
                        gespielt:  this.state.gespielt.concat(mapped),
                        actualPage: response.number + 1,
                        hasMore: !response.last
                    });
                }
            })
            .catch(error => {
                setErrorState(error, this);
            })
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadGespielt();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    editGespielt(e, gespieltId){
        e.preventDefault();

        //keine Ahnung warum hier nicht this.props.history.push funktioniert
        window.location.href = '/rangliste-mixed/gespielt/' + gespieltId;
    }

    deleteGespielt(e, gespieltId){
        e.preventDefault();

        if(gespieltId > 0){
            gespieltMixedService.deleteGespieltById(gespieltId)
                .then(response => {
                    this.setState({
                        successMsg: "Das Match wurde erfolgreich gelöscht."
                    });

                    //um die componentDidUpdate Methode zu triggern, damit die Forderungen neu geladen werden,
                    //sonst verschwindet die geloeschte ForderungEinzel nicht.
                    this.reloadGespielt();

                }).catch(error => {
                    console.log('error');
                console.log(error);
                setErrorState(error, this);
            });
        }
    }

    render() {

        const { gespielt } = this.state;

        return (    <div>
                <h1>Zuletzt gespielte Matches</h1>

                {gespielt === undefined  || gespielt.length === 0 ?
                    //Wenn Forderungen NULL ist
                    <p>Momentan wurden keine Matches gespielt.</p>
                    :
                    <div></div>
                }


                {!!gespielt.length &&
                    <InfiniteScroll
                        dataLength={gespielt.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={<h4>Loading...</h4>}
                        //total bescheurte Komponente. Wenn das Parent-Element nicht voll ist, wird auch nicht nachgeladen.
                        //also bei allen Screens, wo die bereits angezeigten Datensaetze locker in diese Hoehe reinpassen,
                        //klappt das mit dem Nachladen nicht.
                        height={350}
                    >
                        <GespieltAll
                            gespielt={gespielt}
                            deleteGespielt={this.deleteGespielt}
                            editGespielt={this.editGespielt}
                            adminView={this.props.adminView}/>
                    </InfiniteScroll>

                }

            </div>

        );
    }
}

export default GespieltAllMixed;