import React from 'react';
import './App.css';

import Header from './layout/Header.js';
import Main from './layout/Main.js';

import {withRouter} from 'react-router-dom';

import {ACCESS_TOKEN} from './constants';

import {Layout, notification} from 'antd';

import {Helmet} from 'react-helmet';
import {authService} from "./_services/authService";

const { Content } = Layout;

class App extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  loadCurrentUser() {
    this.setState({
      isLoading: true
    });
    authService.getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({
          currentUser: response,
          isAuthenticated: true,
          isLoading: false
        });      
      }
    })
    .catch(error => {
      if (this._isMounted) {
        if (this._isMounted) {
          this.setState({
            hasError: error,
            isAuthenticated: false,
            isLoading: false
          });
        }
      }
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadCurrentUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Handle Logout, Set currentUser and isAuthenticated state which will be passed to other components
  handleLogout(redirectTo="/", notificationType="success", description="Du wurdest abgemeldet.") {
    localStorage.removeItem(ACCESS_TOKEN);
    
    if (this._isMounted) {
      this.setState({
        currentUser: null,
        isAuthenticated: false
      });     
    }
    
    //this.props.history.push(redirectTo);
    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/';

    notification[notificationType]({
      message: 'Vereinsrangliste',
      description: description,
    });
  }

  /* 
   This method is called by the Login component after successful login 
   so that we can load the logged-in user details and set the currentUser &
   isAuthenticated state, which other components will use to render their JSX
  */
  handleLogin() {
    //Man braucht keine  Notification, denke ich
    // notification.success({
    //   message: 'Vereinsrangliste',
    //   description: "Du hast dich erfolgreich angemeldet.",
    // });
    this.loadCurrentUser();

    const { from } = this.props.location.state || { from: { pathname: "/" } };
    this.props.history.push(from);
  }

  handleSignup() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    
    this.props.history.push(from);
  }

  
  render() {

    return (
        <Layout className="app-container App">
          <Helmet>
            <title>Vereinsrangliste</title>
            <description>Die Vereinsrangliste des TC Grün Weiß Neustadt</description>
            {/*ist fuer die mobile View. Sonst passt das nicht, wenn man das Smartphone dreht und so weiter*/}
            {/*alles, was nach initial-scale kommt (also ab dem 3. Argument) ist nur fuer den Chrome Browser.*/}
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densityDpi=device-dpi" />
            <meta http-equiv="Content-Security-Policy" content="script-src 'self'; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com/ https://*.fontawesome.com/;"/>
            
          </Helmet>
          
          <Content className="app-content">
            <div>
                <Header onLogout={this.handleLogout} isAuthenticated={this.state.isAuthenticated} />
                <Main onLogin={this.handleLogin} onSignup={this.handleSignup} currentUser={this.state.currentUser}/>
            </div>
          </Content>
        </Layout>
    );
  }
}

export default withRouter(App);