import GespieltDto from "./GespieltDto";
import NutzerDoppelDto from "./NutzerDoppelDto";

export default class GespieltDoppelDto extends GespieltDto{
    constructor(gespieltJson) {
        super(gespieltJson);

        if(gespieltJson !== null && gespieltJson !== undefined) {
            this.heim = new NutzerDoppelDto(gespieltJson.fordernderNutzerM, gespieltJson.fordernderNutzerW);
            this.gast = new NutzerDoppelDto(gespieltJson.herausgeforderterNutzerM, gespieltJson.herausgeforderterNutzerW);
        }
    }

    getHeim(){
        return this.heim;
    }

    getGast(){
        return this.gast;
    }
}