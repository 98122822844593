
// tag::vars[]
const React = require('react');
// end::vars[]

// tag::ForderungsNutzerInfos[]
// zeigt nur die Infos des angeklickten Nutzers

class ForderungsNutzerInfos extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      ranglistenEintrag: this.props.nutzer,
      //Ob es sich um die Sicht als "ForderungDto" (klick im Baum in der RanglisteEinzel) oder "Herausforderung" (kann im Profil unter "Meine Forderungen")
      isForderung: this.props.isForderung
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = date => {
    this.setState({
      termin: date
    });
  };

  render() {
    const ranglistenEintrag = this.state.ranglistenEintrag;
    const isForderung = this.state.isForderung;

    const nutzerLabel = ranglistenEintrag.getLabelForHeading();
    const nutzerLabelTruncated = ranglistenEintrag.getLabelTruncatedForHeading();

    var duIhrGross = 'Du';
    var wurdestWurdet = 'wurdest';
    var fordereFordert = 'Fordere';
    if(ranglistenEintrag.isDoppel()){
      duIhrGross = 'Ihr';
      wurdestWurdet = 'wurdet';
      fordereFordert = 'Fordert';
    }

      var caption_section =  (
        <React.Fragment>
          <h1>{fordereFordert} {nutzerLabel}</h1>

          <p>
            {fordereFordert} {nutzerLabelTruncated} und macht einen Spieltermin aus. Untenstehend findest du die nötigen Kontaktinformationen. <br/>
            Tragt hier auch den Spieltermin und das Ergebnis ein, damit andere Nutzer sich über euer Match informieren können und ihr eine Menge Zuschauer bekommt.
          </p>
        </React.Fragment>
    );
    
    var kontaktinfo_text = <p>Hier findest du die nötigen Kontaktinformationen, um {nutzerLabelTruncated} herauszufordern:</p>
    
    //Wenn wir uns nicht in der "Fordern"-View befinden, sondern die View aus der Herausforderung-Sicht ist.
    //Also ich fordere nicht, sondern ich wurde herausgefordert.
    //In dieses Szenario kann man ueber den Menupunkt "Meine Forderungen" aus dem Nutzer-profil reinlaufen.
    if(!isForderung){
      caption_section =  (
          <React.Fragment>
            <h1>Herausforderung von {nutzerLabelTruncated}</h1>

            <p>
              {duIhrGross} {wurdestWurdet} von {nutzerLabelTruncated} herausgefordert. Macht einen Spieltermin aus. Untenstehend findest du die nötigen Kontaktinformationen. <br/>
              Tragt hier auch den Spieltermin und das Ergebnis ein, damit andere Nutzer sich über euer Match informieren können und ihr eine Menge Zuschauer bekommt.
            </p>
          </React.Fragment>
      );

      kontaktinfo_text = <p>Hier findest du die nötigen Kontaktinformationen von {nutzerLabelTruncated}:</p>
    }

    
    return (
        <React.Fragment>
          {!!ranglistenEintrag &&
          <div>
            {caption_section}

            <h2>Kontaktinformationen</h2>
            {kontaktinfo_text}

            {ranglistenEintrag.getKontaktinfoElement()}

          </div>
          }
        </React.Fragment>
    )
  }

}
// end::ForderungsNutzerInfos[]


// Specifies the default values for props:
//so muss in der 'zentralen' Forderungs-Komponente das Property isForderung nicht gesetzt werden
ForderungsNutzerInfos.defaultProps = {
  isForderung: true
};


export default ForderungsNutzerInfos;