import {Button} from 'react-bootstrap';


import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// tag::vars[]
const React = require('react');
// end::vars[]


// tag::employee-list[]
class NutzerListPaged extends React.Component{
    constructor(props) {
        super(props);
        this.state = {ranglistenEintraege: [],
            page: this.props.page, 
            pageSize: this.props.pageSize,
            lastPage: this.props.lastPage,
            attributes: [], 
            links: {}};
        
        this.handleNavFirst = this.handleNavFirst.bind(this);
        this.handleNavPrev = this.handleNavPrev.bind(this);
        this.handleNavNext = this.handleNavNext.bind(this);
        this.handleNavLast = this.handleNavLast.bind(this);
        //this.handleInput = this.handleInput.bind(this);
    }

    // tag::handle-nav[]
    handleNavFirst(e){
        e.preventDefault();
        this.props.onNavigate(this.props.links.first.href);
        this.setState({
            page: 0
        });
        //dass die Aenderung der Seite auch nach oben in die Parent-Komponente gelangt
        this.props.onChangePageInfo(this.state.pageSize, 0, this.state.lastPage);
    }

    handleNavPrev(e) {
        e.preventDefault();
        this.props.onNavigate(this.props.links.prev.href);
        const newPage =  this.state.page - 1;
        this.setState({
            page: newPage
        });
        //dass die Aenderung der Seite auch nach oben in die Parent-Komponente gelangt
        this.props.onChangePageInfo(this.state.pageSize, newPage, this.state.lastPage);
    }

    handleNavNext(e) {
        e.preventDefault();
        this.props.onNavigate(this.props.links.next.href);
        const newPage =  this.state.page + 1;
        this.setState({
            page: newPage
        });
        //dass die Aenderung der Seite auch nach oben in die Parent-Komponente gelangt
        this.props.onChangePageInfo(this.state.pageSize, newPage, this.state.lastPage);
    }

    handleNavLast(e) {
        e.preventDefault();
        this.props.onNavigate(this.props.links.last.href);

        //dass die Aenderung der Seite auch nach oben in die Parent-Komponente gelangt
        this.props.onChangePageInfo(this.state.pageSize, this.state.lastPage, this.state.lastPage);

    }
    // end::handle-nav[]

    render() {
        const ranglistenEintrag = this.props.ranglistenEintraege.map(sp =>
            <Nutzer key={sp.getKey()} ranglistenEintrag={sp} onDelete={this.props.onDelete} isDoppel={this.props.isDoppel}/>
        );

        const navLinks = [];
        if ("first" in this.props.links) {
            navLinks.push(<Button className="btn btn-dark" key="first" onClick={this.handleNavFirst}>&lt;&lt;</Button>);
        }else{
            navLinks.push(<Button className="btn btn-dark" key="first" disabled="disabled" onClick={this.handleNavFirst}>&lt;&lt;</Button>);
        }

        if ("prev" in this.props.links) {
            navLinks.push(<Button className="btn btn-dark" key="prev" onClick={this.handleNavPrev}>&lt;</Button>);
        }else{
            navLinks.push(<Button className="btn btn-dark" key="prev" disabled="disabled" onClick={this.handleNavPrev}>&lt;</Button>);
        }

        if ("next" in this.props.links) {
            navLinks.push(<Button className="btn btn-dark" key="next" onClick={this.handleNavNext}>&gt;</Button>);
        }else{
            navLinks.push(<Button className="btn btn-dark" key="next" disabled="disabled" onClick={this.handleNavNext}>&gt;</Button>);
        }

        if ("last" in this.props.links) {
            navLinks.push(<Button className="btn btn-dark" key="last" onClick={this.handleNavLast}>&gt;&gt;</Button>);
        }else{
            navLinks.push(<Button className="btn btn-dark" key="last" disabled="disabled" onClick={this.handleNavLast}>&gt;&gt;</Button>);
        }

        var newRef = "/verwaltung/ranglisteneintrag/new";
        var tableHeader = <tr>
            <th>Position</th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th></th>
        </tr>;
        if(this.props.isDoppel){
            newRef = "/verwaltung-mixed/ranglisteneintrag/new";

            tableHeader = <tr>
                <th>Position</th>
                <th>Nutzer</th>
                <th>Nutzer</th>
                <th></th>
            </tr>;
        }

        return (
            <div className="col-md-6 col-md-offset-3">
                <div style={{'textAlign': 'right'}}>
                    <a href ={newRef} className = "btn btn-primary"><FontAwesomeIcon icon={faPlusCircle} /> Ranglisteneintrag hinzufügen</a>
                </div>
                <table className="table table-striped">
                    <tbody>
                    {tableHeader}
                    {ranglistenEintrag}
                    </tbody>
                </table>
                <div style={{'textAlign': 'right'}}>
                    {navLinks}
                </div>
            </div>
        )
    }
}
// end::employee-list[]

// tag::employee[]
class Nutzer extends React.Component{

    getColumn1Text(ranglistenEintrag){
        if(this.props.isDoppel){
            return ranglistenEintrag.nutzerM.nachname + " " + ranglistenEintrag.nutzerM.vorname;
        }
        return ranglistenEintrag.nutzer.nachname;
    }

    getColumn2Text(ranglistenEintrag){
        if(this.props.isDoppel){
            return ranglistenEintrag.nutzerW.nachname + " " + ranglistenEintrag.nutzerW.vorname;
        }
        return ranglistenEintrag.nutzer.vorname;
    }
    render() {
        return (
            <tr>
                <td>{this.props.ranglistenEintrag.position}</td>
                <td>{this.getColumn1Text(this.props.ranglistenEintrag)}</td>
                <td>{this.getColumn2Text(this.props.ranglistenEintrag)}</td>
                <td>
                    <Button onClick={()=>this.props.onDelete(this.props.ranglistenEintrag)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </td>
            </tr>
        )
    }
}
// end::employee[]

export default NutzerListPaged;