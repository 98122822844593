import React from 'react'
import './Header.css';
import {getCurrentNutzer} from "../_services/authService";
import {SHOW_MIXED_RANGLISTE} from "../constants";
// The Header creates links that can be used to navigate
// between routes.


class Header extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: {}
    };
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadUser();
    }
  }
  
  handleClick() {
    this.props.onLogout();
  }

  loadUser(){
    getCurrentNutzer()
    .then(response => {
      this.setState({
        loggedInUser: response
      });
    })
    .catch(error => {
      if (this._isMounted) {
        this.setState({
          hasError: error,
          loggedInUser: {}
        });
      }
    });
  }

 
  render()
  {
    const { loggedInUser } = this.state;
    
    //nur wenn man nicht angemeldet ist, soll der Login-Link angezeigt werden im Nav
    // let logoutLink = null;
    //   if(!loggedInUser.id){
    //   logoutLink = <Nav.Link className="mr-sm-2" href="/login">Login</Nav.Link>
    // }

    let logoutLink = null;
    if(!loggedInUser.id){
      //logoutLink = <Button className="btn btn-primary navbar-btn">Login</Button>
      //logoutLink = <Nav.Link className="btn btn-primary navbar-btn" href="/login">Login</Nav.Link>
      //logoutLink = <a href = "/login" className = "btn btn-primary">Login</a>

      logoutLink = <a className="btn btn-primary ml-auto mr-1" href="/login">Login</a>

    }

    //TODO: wenn die MixedRangliste dazukommt, kann das hier weg und es kann fix 'Einzel' in der Navigation fuer die Einzel-Rangliste stehen
    var labelEinzelRangliste = 'Rangliste';
    if(SHOW_MIXED_RANGLISTE){
      labelEinzelRangliste = 'Einzel';
    }

    return (
        <div id="nav">

          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/">TCGW Rangliste</a>

            <a className="navbar-brand small-brand" href="/">{labelEinzelRangliste}</a>
            {!!SHOW_MIXED_RANGLISTE &&
              <a className="navbar-brand small-brand" href="/rangliste-mixed/home">Mixed</a>
            }
            {logoutLink}
            
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                {/*<li className="nav-item active">*/}
                  {/*<a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>*/}
                {/*</li>*/}

                {!!SHOW_MIXED_RANGLISTE &&
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Matches
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/rangliste/matches/view">Einzel</a>
                        <a className="dropdown-item" href="/rangliste-mixed/matches/view">Mixed</a>
                      </div>
                    </li>
                }

                {!! !SHOW_MIXED_RANGLISTE &&
                    <li className="nav-item">
                      <a className="nav-link" href="/rangliste/matches/view">Matches</a>
                    </li>
                }

                <li className="nav-item">
                  <a className="nav-link" href="/spielregeln">Anleitung</a>
                </li>
                
                {/*Verwaltung Einzel*/}
                {!!this.state.loggedInUser && this.state.loggedInUser.id && this.state.loggedInUser.admin &&
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Verwaltung Einzel
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="/verwaltung/nutzer/list">Ranglisteneinträge verwalten</a>
                      <a className="dropdown-item" href="/verwaltung/spieltermine/list">Spieltermine verwalten</a>
                      <a className="dropdown-item" href="/verwaltung/rangliste/mix">Rangliste mischen</a>
                    </div>
                  </li>
                }

                {/*Verwaltung Mixed*/}
                {!!SHOW_MIXED_RANGLISTE && this.state.loggedInUser && this.state.loggedInUser.id && this.state.loggedInUser.admin &&
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Verwaltung Mixed
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/verwaltung-mixed/nutzer/list">Ranglisteneinträge verwalten</a>
                        <a className="dropdown-item" href="/verwaltung-mixed/spieltermine/list">Spieltermine verwalten</a>
                        <a className="dropdown-item" href="/verwaltung-mixed/rangliste/mix">Rangliste mischen</a>
                      </div>
                    </li>
                }
                
              </ul>
            </div>
            
            <div className="collapse navbar-collapse w-100 order-3" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                {/*Profil*/}
                {!!this.state.loggedInUser && this.state.loggedInUser.id &&
                <ProfilNav loggedInUser={this.state.loggedInUser} onLogout={this.handleClick}/>
                }

                <li className="nav-item" style={{marginRight: 15}}>
                  <a className="nav-link" href="/impressum">Impressum</a>
                </li>
              </ul>
            </div>
            
          </nav>
          
        </div>
    );
  }

}


// tag::ProfilNav[]
// Profil Menupunkt
class ProfilNav extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleClick() {
    this.props.onLogout();
  }

  render() {
    return (
        <div>
          {!!this.props.loggedInUser &&
            <li className="nav-item dropdown" style={{marginRight: 10}}>
              <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Profil
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="/user/profil">Bearbeiten</a>
              <a className="dropdown-item" href="/user/matchprofil">Meine Einzel</a>
                {!!SHOW_MIXED_RANGLISTE &&
                    <a className="dropdown-item" href="/user/matchprofil-mixed">Meine Mixed</a>
                }
              <a className="dropdown-item" onClick={this.handleClick}>Abmelden</a>
              </div>
            </li>              
          }
        </div>
    )
  }
}
// end::ProfilNav[]



export default Header;
