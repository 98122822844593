import React from 'react';
import {setErrorState} from "../util/ErrorUtil";
import {forderungMixedService} from "../_services/forderungMixedService";
import SpieltermineList from "./component/SpieltermineList";
import ForderungDoppelDto from "../dto/match/ForderungDoppelDto";

class SpieltermineMixed extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            forderungen: []
        };

        this.loadData = this.loadData.bind(this);
        this.loadForderungen = this.loadForderungen.bind(this);
        this.deleteForderung = this.deleteForderung.bind(this);
        this.editForderung = this.editForderung.bind(this);
    }

    
    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.forderungen!==this.props.forderungen){
            this.setState({ forderungen: prevProps.forderungen });
        }
        if(this.props.currentUser !== prevProps.currentUser) {
            // Reset State
            this.loadData();
        }
    }

    //Die hier ist wichtig.
    //sonst kommen die Aenderungen an den Properties-Variable vom Parent nicht hier beim Kind an
    //keine Ahnung wieso.
    //Anmerkung: hier  kann man nicht auf this zugreifen und auch keinen state setzten/aendern
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.forderungen !== prevState.selected) {
            return {
                forderungen: nextProps.forderungen,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    loadData(){
        this.loadForderungen();
    }

    loadForderungen() {
        forderungMixedService.getAllSorted()
            .then(response => {
                if (this._isMounted) {
                    const mapped = response.map((element) => new ForderungDoppelDto(element));
                    this.setState({forderungen: mapped});
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }
    
    editForderung(e, forderungId){
        e.preventDefault();

        //keine Ahnung warum hier nicht this.props.history.push funktioniert
        window.location.href = '/rangliste-mixed/forderungbyid/' + forderungId;
    }

    deleteForderung(e, forderungId){
        e.preventDefault();

        if(forderungId > 0){
            forderungMixedService.deleteForderungById(forderungId)
            .then(response => {
                this.setState({
                    successMsg: "Die ForderungDto wurde erfolgreich gelöscht."
                });

                //um die componentDidUpdate Methode zu triggern, damit die Forderungen neu geladen werden, 
                //sonst verschwindet die geloeschte ForderungDto nicht.
                this.loadForderungen();

            }).catch(error => {
                setErrorState(error, this);
            });
        }
    }

    
    render() {
        const { forderungen } = this.state;

        return (
            <div>
                {!!forderungen &&
                    <SpieltermineList forderungen={forderungen}
                                      editForderung={this.editForderung}
                                      reloadForderungen={this.loadForderungen}
                                      deleteForderung={this.deleteForderung}
                                      header={"Anstehende Forderungen"}
                                      currentUser={this.props.currentUser}
                                      adminView={this.props.adminView}/>
                }
            </div>
        );
    }
}


export default SpieltermineMixed;