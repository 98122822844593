import React, {Component} from 'react';
//import { Form, Input, Button, notification } from 'antd';
import NutzerEintragNew from "./NutzerEintragNew";


class NutzerEintragEinzelNew extends Component {
    constructor(props) {
        super(props);

        this.redirectToList = this.redirectToList.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }


    redirectToList(){
        this.props.history.push("/verwaltung/nutzer/list");
    }

    render() {

        return (
            <NutzerEintragNew
                headerObject={"Nutzer"}
                spieltEinzelRanglisteDefaultValue={true}
                onAfterSave={this.redirectToList}
                onCancel={this.redirectToList}/>
        );
    }

}

export default NutzerEintragEinzelNew;